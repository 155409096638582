/* IMAGE BTNS - MOBILE FIRST (~ TABLET) */
.c-btn-over-image {
  position: absolute;
  .z-layer;
  text-align: center;
  a {
    .unstyle-anchor;
  }
  .cmp-icon {
    width: 24px;
    height: 24px;
    svg {
      path,
      circle {
        stroke: @white;
        stroke-width: 3px;
      }
    }
  }
  &-full {
    width: 100%;
    height: 100%;
    .c-modal {
      width: 100%;
      height: 100%;
    }
  }
  &__label {
    color: @white;
    font-size: 12px;
    font-weight: 300;
    display: block;
    line-height: 12px;
  }
}

.mod--btn-over-image-top-right {
  .c-btn-over-image,
  &.c-btn-over-image {
    right: 8px;
    top: 8px;
  }
}
.mod--btn-over-image-top-left {
  .c-btn-over-image,
  &.c-btn-over-image {
    left: 8px;
    top: 8px;
  }
}
.mod--btn-over-image-bottom-right {
  .c-btn-over-image,
  &.c-btn-over-image {
    right: 8px;
    bottom: 8px;
  }
}
.mod--btn-over-image-bottom-left {
  .c-btn-over-image,
  &.c-btn-over-image {
    left: 8px;
    bottom: 8px;
  }
}
.mod--btn-over-image-center-center {
  .c-btn-over-image,
  &.c-btn-over-image {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mod--btn-over-image-inverse,
.c-gallery__container .mod--btn-over-image-inverse {
  .common-transition;
  .svg-icon {
    display: none;
  }
  &:hover {
    .svg-icon {
      display: inline-block;
    }
    .cmp-image,
    .c-video {
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: @soft-corners;
        .shadow-overlay(to bottom, 0.5, 0%, 0.5, 50%, 0.5, 100%);
        opacity: 1;
      }
    }
  }
}

/* IMAGE BTNS - DESKTOP  */
@media (min-width: @desktop) {
  .md\:mod--btn-over-image-top-right {
    .c-btn-over-image,
    &.c-btn-over-image {
      right: 8px;
      top: 8px;
      bottom: auto;
      left: auto;
    }
  }
  .md\:mod--btn-over-image-top-left {
    .c-btn-over-image,
    &.c-btn-over-image {
      left: 8px;
      top: 8px;
      right: auto;
      bottom: auto;
    }
  }
  .md\:mod--btn-over-image-bottom-right {
    .c-btn-over-image,
    &.c-btn-over-image {
      right: 8px;
      bottom: 8px;
      left: auto;
      top: auto;
    }
  }
  .md\:mod--btn-over-image-bottom-left {
    .c-btn-over-image,
    &.c-btn-over-image {
      left: 8px;
      bottom: 8px;
      top: auto;
      right: auto;
    }
  }
}
