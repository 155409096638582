/* HEADER COMPONENT */
.header {
    height: @header-mobile-height;
    .break-container;
    .c-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid fade(white, 40%);
        padding: 8px 15px;
        .z-sticky;
        &__switch {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &-btn {
                display: flex;
                flex-flow: column;
                align-items: center;
                &-txt {
                    font-size: 11px;
                    line-height: 20px;
                    font-weight: 300;
                    &.txt-on {
                        display: block;
                    }
                    &.txt-off {
                        display: none;
                    }
                }
            }
            &-logo {
                width: 80px;
                margin-left: 15px;
                svg {
                    height: 100%;
                    path {
                        fill: @white;
                    }
                }
            }
        }
        &__nav-container {
            .c-aside-menu {
                &-user {
                    display: flex;
                    &-item {
                        cursor: pointer;
                        margin-left: 15px;
                        text-align: center;
                        font-size: 11px;
                        line-height: 14px;
                        flex-direction: column;

                        svg {
                            max-width: 26px;
                        }

                        &--login {
                            margin-top: 4px;
                        }

                        &-anchor {
                            position: relative;
                            width: 100%;
                            .unstyle-anchor;
                            color: white;
                            font-weight: 300;
                            .cmp-icon {
                                margin-bottom: 4px;
                                svg {
                                    width: 26px;
                                    height: 26px;
                                    background: transparent !important;
                                    * {
                                        stroke-width: 1.9pt;
                                    }
                                    rect {
                                        fill: transparent;
                                    }
                                }
                            }
                            &.c-call-btn {
                                .cmp-icon {
                                    svg {
                                        * {
                                            stroke-width: 1.5;
                                        }
                                    }
                                }
                                label {
                                    font-weight: inherit;
                                    display: block;
                                    position: relative;
                                    &:hover {
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            width: 52px;
                                            height: 63px;
                                            top: -8px;
                                            left: -6px;
                                            background: @white;
                                            opacity: .45;
                                        }
                                    }
                                }
                                input[type="checkbox"] {
                                    position: absolute;
                                    width: 0;
                                    height: 0;
                                    padding: 0;
                                    margin: 0;
                                    visibility: hidden;
                                    &::after {
                                        content: none;
                                    }
                                }
                                .c-call-btn--dropdown {
                                    position: absolute;
                                    display: none;
                                    max-width: 426px;
                                    height: auto;
                                    background: white;
                                    color: black;
                                    padding: 18px;
                                    // right: -15px;
                                    right: -136px;
                                    width: 100vw;
                                    top: 55px;
                                    &-header,
                                    &-claim {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                    }
                                    &-header {
                                        margin-bottom: 12px;
                                    }
                                    p {
                                        font-size: 18px;
                                        line-height: 24px;
                                        margin-left: 10px;
                                        font-weight: 500;
                                        color: #767676;
                                    }
                                    &-phone-box {
                                        background: #F5F5F5;
                                        padding: 10px;
                                        a {
                                            font-size: 16px;
                                            font-weight: 700;
                                            text-decoration: none;
                                        }
                                    }
                                }
                                [id^=drop]:checked + .c-call-btn--dropdown {
                                    display: block;
                                    animation: fadeIn 0.5s ease-out;
                                }
                            }
                            .number-notification{
                                background: #cc4716;
                                position: absolute;
                                border-radius: 50%;

                                // top: 10px;
                                // right: 54px;

                                top: 12px;
                                right: 10px;
                                
                                font-size: 10px;
                                width: 16px;
                                height: 16px;
                                line-height: 16px;
                            }
                        }

                        &:first-child {
                            margin-left: 0px;
                        }
                    }
                }

                .c-popover {
                    &.user-item--login {
                        position: fixed;
                        top: 0;
                        right: 0;
                        width: 90vw;
                        margin-left: auto;
                        height: 100%;
                        &.mod--active {
                            animation: popOverSlideIn 0.35s ease-out forwards;
                            display: block;
                        }
                        &.mod--hide {
                            animation: popOverSlideOut 0.35s ease-out forwards;
                        }
                        &.mod--barceloMenuV2 {
                            width: 100%;
                            box-shadow: none;
                            .c-popover__content {
                                display: block;
                            }
                        }
                        .c-popover__caret {
                            display: none;
                        }
                    }
                }
            }
        }

        &.mod--sticky-header-top {
            .c-aside-menu {
                .c-call-btn--dropdown {
                    top: 55px;
                }
            }
        }
    }
    .c-header-underlay {
        .common-transition;
        width: 0%;
        background: @grey-90;
        height: 60px;
        position: absolute;
        top: 0px;
        left: 0px;
        .z-advs;
        &.mod--active {
            width: 100%;
        }
    }

    &.mod--without-border-bottom {
        .c-header {
            border-bottom: initial;
        }
    }
}

/* MYBARCELO LOGIN */
body {
    .header .c-header__menu-user-item {
        .user {
            display: none;
        }
        .cmp-icon {
            svg {
                margin-right: 8px;
            }
        }
    }
    &.mod--standard,
    &.mod--intense,
    &.mod--unique {
        .header .c-header__menu-user-item {
            .noUser {
                display: none;
            }
            .user {
                display: flex;
            }
        }
    }
}

.activeMegamenu {
    .c-header__switch-btn-txt {
        &.txt-on {
            display: none;
        }
        &.txt-off {
            display: block;
        }
    }
}

//mobile hotels filter opened -> .c-hotels-comparator__sidebar.open
.activeHotelsfilter {
    .header {
        position: relative;
        z-index: 3;
    }
} 

/* C-HEADER - MEDIA - DESKTOP  */
@media (min-width: @desktop) {
    .header {
        height: @header-desktop-height; //80px now
        .default-container;
        padding-top: 5px;
        .c-header {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: 1px solid fade(white, 40%);
            padding: 6px 0 16px;
            &__switch {
                width: 157px;
                justify-content: flex-start;
                margin-top: 0px;
                padding-bottom: 0px;
                &-btn {
                    display: none;
                }
                &-logo {
                    width: 100%;
                    max-width: 120px;
                    margin-right: auto;
                    margin-left: initial;
                }
            }
            &__nav-container {
                width: ~"calc(100% - 157px)";
                height: 52px;
                position: relative;

                display: flex;
                justify-content: flex-end;
                .c-aside-menu {
                    position: absolute;
                    z-index: 1;
                    right: 0px;
                    bottom: -4px;
                    &-user {
                        justify-content: flex-end;
                        &-item {
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 400;
                            flex-direction: initial;
                            align-items: center;
                            margin-top: auto;
                            margin-bottom: auto;
                            &:hover{
                                opacity: 0.5;
                            }
                            &--login {
                                font-size: 16px;
                                line-height: 20px;
                                font-weight: 400;

                                margin-top: 0;
                                // margin-left: 8px;
                                align-self: center;
                            }
                            svg {
                                margin-right: 8px;
                            }
                            &-anchor {
                                display: flex;
                                align-items: center;
                                .cmp-icon {
                                    svg {
                                        max-width: 20px;
                                        height: auto;
                                        margin-right: 8px;
                                        * {
                                            stroke-width: 3px;
                                        }
                                    }
                                }
                                &,
                                & p {
                                    font-size: 16px;
                                    line-height: 20px;
                                    font-weight: 400;
                                }
                                .number-notification{
                                    left: 10px;
                                    top: 10px;
                                }
                            }
                            margin-left: 20px;
                            text-align: center;
                            &:first-child {
                                margin-left: 0px;
                            }

                        }

                        &~.c-popover {
                            .oneCol {
                                // max-width: 322px;
                            }
                        }
                    }

                    .c-popover {
                        &.user-item--login {
                            width: 322px;
                            position: absolute;
                            top: initial;
                            right: initial;
                            margin-left: initial;
                            height: auto;
                            &.mod--active {
                                top: 39px;
                                animation: popOverFadeIn 0.35s ease-out forwards;
                            }
                            &.mod--hide {
                                animation: popOverFadeOut 0.15s ease-out forwards;
                                display: none;
                            }
                            &.mod--barceloMenuV2 {
                                position: fixed;
                                top: 0;
                                right: -385px;
                                margin-left: auto;
                                width: 382px;
                                height: 100%;
                                border-radius: 0;
                                &.mod--active {
                                    right: 0;
                                    animation: popOverSlideIn 0.35s ease-out forwards;
                                }
                                &.mod--hide {
                                    animation: popOverSlideOut 0.35s ease-out forwards;
                                }
                                .c-popover {
                                    width: 382px;
                                    &__caret {
                                        display: none;
                                    }
                                    &__content {
                                        width: 382px;
                                        box-shadow: initial;
                                    }
                                }
                            }
                            .c-popover__caret {
                                display: block;
                            }

                            &.mod--barceloMenuV2 {
                                &.mod--active {
                                    animation: popOverSlideIn 0.35s ease-out forwards;
                                    display: block;
                                    opacity: 1;
                                    z-index: 0;
                                }
                                &.mod--hide {
                                    animation: popOverSlideOut 0.35s ease-out forwards;
                                }
                            }
                        }
                    }

                    &.mod--aside-barceloMenuV2 {
                        z-index: 10;
                    }
                }
            }
        }
    }
}

// MEGAMENU
@import (once) "c-megamenu.less";
