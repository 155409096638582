/* HOTEL DETAIL REFERENCES - MOBILE FIRST (~ TABLET) */   
.c-section {
	&__headings {
		margin-top: 48px;
		margin-bottom: 16px;
		&--title {
			font-size: 28px;
			line-height: 36px;
			font-weight: 300;
			letter-spacing: 0.15px;
		}
	}
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
	.c-section {
		&__headings {
			margin-top: 56px;
			margin-bottom: 24px;
			&--title {
				font-size: 36px;
				line-height: 42px;
				letter-spacing: 0.25px;
			}
		}
	}
}