/* CARD HOTEL DATES*/
.c-card-dates {
    &__alert {
        display: inline-flex;
        align-items: center;
        width: auto;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        margin: 0 0 12px 0;
        border: 2px solid @b-yellow-orange;
        border-radius: @soft-corners;
        &-message {
            background: @white;
            padding: 3px 5px;
            .ellipsis;
            max-width: 600px;
            &:before {
                content: "";
                .mix-background-sprite(18px, 9, center);
                display: inline-block;
                vertical-align: sub;
                width: 18px;
                height: 18px;
                margin: 0 5px 0 0 ;
            }
        }
        &-timer {
            background: @b-pale-yellow;
            padding: 3px 10px;
        }
    }
    &__container {
        background: @grey-20;
        .c-card-dates__periods:nth-child(2) {
            border-top: 3px solid white;
        }
    }
    &__periods {
        display: flex;
        width: 100%;
        &-date {
            background: @grey-20;
            width: auto;
            padding: 6px 12px;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            &-claim {
                font-weight: 700;
                display: block;
            }
            &-book,
            &-travel {
                font-weight: 300;
            }
        }
        &-separator {
            background-color: @grey-20;
            width: 1px;
            margin: 0px;
            display: flex;
            position: relative;
            align-items: center;
            padding: 0px;
            line-height: 0px;
            &::after {
                content: "|";
                color: @grey-40;
                width: 1px;
                position: absolute;
                z-index: 1;
                left: -2px;
            }
        }
    }
}
