//	 __          __              _             _ 
//	 \ \        / /             (_)           | |
//	  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _| |
//	   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` | |
//	    \  /\  / (_| | |  | | | | | | | | (_| |_|
//	     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
//	                                      __/ |  
//	                                     |___/   
//	
//	   ANY CHANGES MADE HERE WILL TAKE EFFECT ON THE "NEW ROYAL HIDEAWAY (BRH)" CURRENCY AND LANGUAGE COMPONENT

/* MEGAMENU */
.c-megamenu {
    background: @grey-90;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: -100vw;
    .common-transition;
    &__menu {
        position: relative;
        width: 100%;
        &-main,
        &-utils {
            width: 100%;
            &-item {
                cursor: pointer;
                padding: 0px 15px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid fade(white, 40%);
                z-index:10;
                .cmp-icon {
                    height: 16px;
                    width: 16px;
                    svg {
                        path {
                            fill: @white;
                        }
                    }
                }
                &-anchor {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    .unstyle-anchor;
                    color: white;
                    font-weight: 500;
                    &:hover,
                    &:active,
                    &.mod--active {
                        opacity: 0.7;
                    }
                    &.tab-blocked-currency {
                        cursor: default;
                        &:hover {
                            opacity: 1;
                        }
                    }
                    .language_current,
                    .currency_current {
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: flex-end;
                        margin-left: auto;
                        margin-right: 18px;
                        .c-price__currency {
                            border: none;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        &-main {
            &-item {
                font-size: 20px;
                line-height: 24px;
                height: 65px;
            }
        }
        &-utils {
            &-item {
                font-size: 16px;
                line-height: 24px;
                height: 50px;
                &-anchor {
                    &.c-locate-nav__btn {
                        justify-content: space-between;
                        margin-right: 16px;
                    }
                    .cmp-icon {
                        width: 16px;
                        height: 16px;
                        margin-right: 10px;
                        svg {
                            ellipse,
                            path {
                                stroke-width: 4px;
                            }
                            path {
                                fill: transparent;
                            }
                        }
                    }
                }
                &:last-child {
                    border-bottom: 1px solid fade(white, 40%);
                }
                .c-price__currency {
                    font-size: 12px;
                    border-radius: @circle;
                    border: 1px solid @white;
                    width: auto;
                    height: 18px;
                    font-weight: 300;
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
                    padding: 0px 5px;
                    line-height: 0px;
                    margin-right: 8px;
                    margin-left: 0px;
                    vertical-align: middle;
                    &:after {
                        margin-left: -1px;
                    }
                }
            }
        }
        &-call {
            display: none;
        }
    }

    /* MEGAMENU SUBMENU */
    .c-popover {
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 110vw;
        overflow-y: scroll;
        overflow-x: hidden;
        margin: 0px;
        padding: 0px;
        border-radius: 0px;
        display: none;
        &.mod--active {
            animation: popOverSlideIn 0.35s ease-out forwards;
            display: block;
        }
        &.mod--hide {
            animation: popOverSlideOut 0.35s ease-out forwards;
        }
        .c-back-bar {
            border-bottom: 1px solid @grey-40;
            min-height: 50px;
            margin: 0 15px;
            padding: 0px;
            width: ~"calc(100% - 30px)";
            justify-content: space-between;
            .submenu-name {
                font-size: 16px;
                width: auto;
                line-height: 50px;
                float: right;
                display: block;
                color: @grey-90;
            }
            a.smenu-back {
                color: var(--b-blue);
                line-height: 50px;
                float: left;
                font-size: 16px;
                width: auto;
                height: auto;
            }
        }
        .c-popover__caret {
            display: none;
        }
    }
    &__submenu {
        display: none;
        &.mod--active {
            opacity: 1;
            display: block;
            .z-modal;
        }
        &.smenu-lang {
            color: @grey-90;
            .c-fieldset {
                margin: 0;
            }
        }
        .z-modal;
        &-language {
            padding: 15px;
            .c-fieldset {
                label {
                    width: 100%;
                }
                .mod--select-arrow {
                    width: 100%;
                    .mix-background-sprite(40px, 21, ~"calc(100% + 5px)");
                    &:focus,
                    &:focus-within {
                        .common-focus;
                    }
                    .language-country {
                        background-color: transparent;
                        width: 100%;
                        cursor: pointer;
                        background-repeat: no-repeat;
                        background-position: 10px center;
                        background-size: 20px auto;
                        padding-left: 35px;
                        -webkit-appearance: none;
                    }
                }
            }
        }
        &-currency {
            padding: 15px;
            &-search {
                margin: 8px 0;
                order: 3;
            }
            &-select {
                
                select {
                    .mix-background-sprite(40px, 21, ~"calc(100% + 5px)");
                    margin-top: 15px;
                }
                .c-fieldset__row {
                    order: 4;
                    &.checked {
                        order: 2;
                    }
                }
                .currency-select {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    &-suggested {
                        order: 1;
                    }
                }
            }
            hr {
                margin: 20px 0px 15px;
            }
            &-main {
                .cmp-title {
                    font-weight: 700;
                    margin-bottom: 15px;
                }
                &-items {
                    margin: 10px -5px;
                    display: flex;
                    flex-wrap: wrap;
                    &-item {
                        font-size: 14px;
                        line-height: 14px;
                        font-weight: 500;
                        width: ~"calc(50% - 10px)";
                        margin: 2px 5px;
                        background-color: @grey-20;
                        align-items: center;
                        justify-content: flex-start;
                        text-align: left;
                        padding: 5px 20px 5px 5px;
                        border-radius: 2px;
                        &.mod--active {
                            font-weight: 700;
                            background-color: fade(@b-light-blue, 30%);
                            position: relative;
                            &:after {
                                content: "";
                                .mix-background-sprite(30px, 13, right);
                                width: 20px;
                                height: 100%;
                                position: absolute;
                                z-index: 1;
                                right: -2px;
                                top: -4px;
                            }
                        }
                    }
                }
            }
        }
        &-language,
        &-currency {
            .c-fieldset__row {
                padding: 2px 0;
                .common-transition;
                &.c-fieldset__form-radio {
                    position: relative;
                    width: 100%;
                    margin-bottom: 0;
                    cursor: pointer;
                    span {
                        position: relative;
                        padding: 12px 8px;
                        width: 100%;
                        display: block;
                        border-radius: 8px;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        &:hover {
                            background: #f5f5f5;
                        }
                    }
                    input[type=radio] {
                        position: absolute;
                        opacity: 0;
                        width: 0;
                        height: 48px;
                        &:checked+span {
                            background: #2F5A76;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

.activeMegamenu {
    .mod--fixed;
    .c-header {
        .c-megamenu {
            left: 0px;
        }
    }
}


.mod--currency-left {
    .c-megamenu {
        &__menu {
            &-utils {
                &-item {
                    &-anchor {
                        &.c-locate-nav__btn:not(.locale-form-lang-JS) {
                            flex-direction: row-reverse;
                        }
                    }
                }
            }
        }
    }
}

/* C-HEADER - MEDIA - DESKTOP  */
@media (min-width: @desktop) {
    /* MEGAMENU DESKTOP*/
    .c-megamenu {
        position: relative;
        height: 100%;
        left: 0px;
        top: 0px;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        background: transparent;
        width: 100%;
        &__menu {
            width: 68%;
            margin: 0px 30px;
            display: flex;
            &-main,
            &-utils {
                position: relative;
                flex-flow: row;
                &-item {
                    border-top: 0px;
                    .cmp-icon {
                        display: none;
                    }
                    &-anchor {
                        .cmp-icon {
                            display: block;
                        }
                    }
                }
            }
            &-main {
                display: flex;
                justify-content: center;
                &-item {
                    display: flex;
                    width: auto;
                    font-size: 16px;
                    height: auto;
                    padding: 0 15px;
                    &-anchor {
                        display: block;

                        font-size: 16px;
                        line-height: 20px;
                        .ellipsis;
                        &::after {
                            font-family: "barcelo-ui-kit";
                            content: "\e927";
                            position: relative;
                            top: 4px;
                            font-size: 21px;
                            right: 0px;
                        }
                    }
                }
            }
            &-utils {
                padding: 0px;
                display: flex;
                &::before {
                    margin-left: initial;
                }
                &-item {
                    border: 0px;
                    padding: 0px;
                    height: auto;
                    margin-left: 18px;
                    &:after {
                        content: none;
                    }
                    &-anchor {
                        position: relative;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 400;
                        .language_current {
                            span {
                                white-space: nowrap;
                            }
                        }
                        .currency_current {
                            margin-right: 6px;
                        }
                    }
                    &:last-child {
                        border: 0px;
                    }
                }
            }
            &-call-btn {
                padding-right: 18px;
                position: absolute;
                text-align: right;
                right: 100%;
                width: auto;
                &:after {
                    content: "";
                    border-left: 1px solid #fff;
                    position: absolute;
                    right: 0px;
                    height: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &>span {
                    display: block;
                    white-space: nowrap;
                }
                a {
                    color: white;
                    text-decoration: none;

                    &:hover {
                        color: white;
                        opacity: 0.85;
                    }
                }
                .cmp-icon {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: -22px;
                    bottom: 1px;
                    svg {
                        max-width: 16px;
                    }
                }
            }
            &.c-position-wrapper { //submenu lang and currency
                right: 0px;
                width: auto;
                margin: 0;
                position: relative;
                align-self: flex-end;

                &,
                & a {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 400;
                }
                .c-locate-nav {
                   
                    &__btn {
                        margin-right: 0;
                        &:first-of-type {
                            .cmp-icon {
                                display: none;
                            }
                        }
                    }
                    .c-megamenu__menu-utils-item {
                        width: auto;
                        position: relative;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 400;
                        &:before {
                            content: "";
                            border-left: 1px solid @white;
                            position: absolute;
                            left: -20px;
                            height: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                .language-current {
                    margin-right: 30px;
                    svg {
                        display: none;
                    }

                    &:after {
                        display: inline-block;
                        font-family: barcelo-ui-kit;
                        content: "\e902";
                        font-size: .9em;
                        line-height: 0;
                        transform: translateY(0.1em) translate(0.5rem) rotate(90deg);
                    }
                }
                .c-price__currency {
                    &:before {
                        content: "";
                        border-left: 1px solid white;
                        position: absolute;
                        left: -20px;
                        height: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                .c-popover {
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                    border-radius: 16px;
                    padding: 12px 0;
                    max-height: 540px;
                }
                .c-popover {
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                    border-radius: 16px;
                    padding: 12px 0;
                    max-height: 540px;
                }
            }
        }
        /* MEGAMENU - SUBMENU - DESKTOP*/
        .c-popover {
            position: absolute;
            height: auto;
            width: auto;
            top: ~"calc(100% + 15px)";
            left: 0;
            overflow-y: visible;
            overflow-x: visible;
            margin: 0px -12px;
            border-radius: @soft-corners;
            &.mod--active {
                animation: popOverFadeIn 0.35s ease-out forwards;
            }
            &.mod--hide {
                animation: popOverFadeOut 0.35s ease-out forwards;
            }
            &__content {
                padding: 0px;
            }
            .c-popover__caret {
                display: inherit;
            }
        }
        &__submenu {
            opacity: 0;
            .common-transition;
            width: @content-sizing;
            &.oneCol {
                width: 360px;
            }
            &.fullCol {
                width: 100vw;
                max-width: @content-sizing;
            }
            &.smenu-lang {
                right: inherit;
                border-radius: 16px;
                width: auto;
                padding: 0 16px;
                max-height: 512px;
                overflow: auto;
                &.mod--active {
                    right: inherit;
                }
                /* width */
                &::-webkit-scrollbar {
                    width: 7px;
                }
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #F5F5F5;
                    border-radius: 10px;
                }
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #D8D8D8;
                    border-radius: 10px;
                }
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #D8D8D8;
                }
                /* Works on Firefox */
                & {
                    scrollbar-width: thin;
                    scrollbar-color: #D8D8D8 #F5F5F5;
                }
            }
            &-language,
            &-currency { //forms
                padding: 0px;
                color: @grey-90;
            }
            &-language {
                .c-popover-language-btn {
                    padding: 0px;
                }
                .language {
                    &-idiom {
                        background-repeat: no-repeat;
                        background-size: 10px auto;
                        padding-left: 5px;
                        -webkit-appearance: none;
                    }
                    &-country {
                        background-repeat: no-repeat;
                        background-position: 10px center;
                        background-size: 20px auto;
                        padding-left: 35px;
                        -webkit-appearance: none;
                    }
                }
                .c-fieldset {
                    margin: auto;
                    .c-popover-language-btn {
                        padding: 0 0 20px 0;
                    }
                    .c-flag-select {
                        top: 146px;
                    }
                }
                hr {
                    margin: 0;
                }
            }
            &-currency {
                padding: 0;
            }
        }
    }
    .activeMegamenu {
        overflow-y: visible;
        height: auto;
    }
    .c-aside-menu {
        width: 32%;
        &-user {
            &-item {
                display: flex;
                &-anchor p {
                    display: block;
                    .ellipsis;
                }
            }
        }
    }
}

// MEGAMENU - MEDIA - DESKTOP CONTENT - SIZE
@media (min-width: @desktop) and (max-width: @content-sizing) {
    .c-megamenu {
        &__menu {
            &-main {
                padding-left: 15px;
            }
        }
    }
}
