/* AEM CMP TABS */
.tabs {
	.cmp-tabs {
		margin-top: 5px;

		&__tablist {
			display: flex;
			flex-wrap: nowrap;
			overflow-x: auto;
			margin-right: -15px;
			margin-left: -15px;
		}

		&__tab {
			width: auto;
			height: 40px;
			padding: 7px 10px;
			border-bottom: 0px;
			position: relative;
			white-space: nowrap;
			margin: 10px 1px;
			font-size: 14px;
			line-height: 27px;
			flex-grow: 1;
			text-align: center;
			font-weight: 500;
			background: @grey-40;
			& * {
				font-size: inherit;
				font-weight: inherit;
				line-height: inherit; 
			}

			&:hover {
				background: var(--b-blue);
				cursor: pointer;
				color: @white;
			}

			&--active {
				background: var(--b-blue);
				color: @white;
				font-weight: 700;

				&:after {
					content: "";
					position: absolute;
					background-color: var(--b-blue);
					left: 50%;
					bottom: -7px;
					width: 15px;
					height: 15px;
					transform: translate(-50%, 0px) rotate(45deg) skew(-3deg, -3deg);
				}
			}
		}
	}

	&.mod--second-level-layout {
		> .cmp-tabs {
			> .cmp-tabs__tabpanel {
				.cmp-tabs {
					margin-top: 0px;
					&__tablist {
						background: @grey-20;
					}
					&__tab {
						position: relative;
						white-space: nowrap;
						height: auto;
						font-size: 14px;
						line-height: 37px;
						flex-grow: 0;
						margin: 0px;
						padding: 0px 12px;
						text-align: center;
						font-weight: 500;
						background: @grey-20;
						color: var(--b-blue);
						text-decoration: underline;
						flex-grow: 1;

						&:first-child {
							padding-left: 24px;
						}

						&:last-child {
							padding-right: 24px;
						}

						&--active {
							background: @grey-20;
							font-weight: 700;
							border: none;

							&:after {
								content: none;
							}
						}
					}
				}
			}
		}
	}

	&.mod--bg-grey-20 {
		> .cmp-tabs {
			margin-top: 0;
			> .cmp-tabs__tablist {
				background: @grey-20;
			}
		}
	}

	&.mod--bigger-tab {
		> .cmp-tabs {
			> .cmp-tabs__tablist {
				.cmp-tabs__tab {
					margin: 0 0 10px 0;
					min-height: 60px;
					height: auto;
					padding: 18px 15px;
					border-right: 4px solid @white;
					background: @grey-20;
					flex: 1 1 0;
					white-space: normal;
					line-height: 1.4;
					font-weight: 700;
					& * {
						font-size: inherit;
						font-weight: inherit;
						line-height: inherit; 
					}

					&:first-of-type {
						border-left: 4px solid @white;
					}

					&--active,
					&:hover {
						background: var(--b-blue);
					}
					.c-textimage{
						padding: 0;
						.generic-text.text {
							.cmp-text {
								line-height: 1.2;
								font-weight: 100;
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}

	&.mod--not-full-w {
		> .cmp-tabs {
			> .cmp-tabs__tabpanel {
				max-width: @content-sizing;
				margin: 0 auto;
				padding: 0px 15px;
			}
		}
	}

	&.mod--remove-arrows {
		> .cmp-tabs {
			> .cmp-tabs__tablist {
				.cmp-tabs__tab {
					margin: 2px 1px;
					&--active {
						&:after {
							content: none;
						}
					}
				}
			}
		}
	}
	&.mod--tab-underlined-over-light-bg,
	&.mod--tab-underlined-over-dark-bg {
		&.mod--remove-arrows {
			.cmp-tabs {
				&__tab {
					background: transparent;
					border-bottom: 2px solid;
					text-transform: uppercase;
					font-size: 17px;
					margin: 2px 0px;
					border-radius: 2px;
					min-height: 40px;
					flex: 1;
					& * {
						font-size: inherit;
						font-weight: inherit;
						line-height: inherit;
					}
					&--active {
						background: transparent;
						border-bottom: 4px solid;
						color: initial;
					}
					&:hover {
						color: initial;
						border-bottom: 4px solid;
						font-weight: 700;
						.common-transition;
					}
				}
			}
			&.mod--tab-underlined-full-width {
				.cmp-tabs {
					&__tab {
						max-width: none;
					}
				}
			}
		}
		
	}
	&.mod--tab-underlined-over-dark-bg {
		&.mod--remove-arrows {
			.cmp-tabs {
				&__tab {
					color: @br-sand-60;
					border-bottom: 2px solid @br-sand-60;
					& * {
						font-size: inherit;
						font-weight: inherit;
						line-height: inherit;
					}
					&--active {
						border-bottom: 4px solid @br-sand-60;
						color: @br-sand-60;
					}
					&:hover {
						color: @br-sand-60;
						border-bottom: 4px solid @br-sand-60;
					}
					&list {
						margin: 0;
					}
				}
			}
		}
	}
	&.mod--tab-underlined-blue{ 
		.cmp-tabs {
			margin: unset;
			&__tab {
				color: @text-color;
				background: unset;
				padding: 8px;
				margin: unset;
				min-width: unset;
				max-width: unset;
				font-size: 16px;
				line-height: 24px;
				font-weight: 300;
				position: relative;
				& * {
					font-size: inherit;
					font-weight: inherit;
					line-height: inherit;
				}
				&--active {
					font-weight: 400;
					&::after{
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 1px;
						background-color: #30A2BF;
						border-radius: 2px;
						transform: unset;
					}
				}
				&:hover {
					&::after{
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 1px;
						background-color: #30a2bf7b;
						border-radius: 2px;
						transform: unset;
					}
				}
				&list {
					position: relative;
					margin-top: unset;
					margin-bottom: 24px;
					padding: 0 16px;
					box-shadow: 0 -1px 0 0 rgba(213, 222, 228, 1) inset;
					overflow-x: auto;
					margin-right: unset;
					margin-left: unset;
					-ms-overflow-style: none;
					scrollbar-width: none;
					&::-webkit-scrollbar{
						display: none;
					}
				}
				&:nth-child(n + 2){
					margin-left: 24px;
				}
			}
			.c-textimage {
				.image {
					flex-shrink: 0;
					flex-grow: 0;
				}
			}
		}
	}
	.launcher {
        .c-launcher__carousel {
            &-content {
                ul {
                    li {
                        &:before {
                            color: currentColor;
							left: -0.8em;
                        }
                    }
                }
            }
        }
    }

}
//tabs into an outline wrapper
.mod--outline {
	.tabs {
		.cmp-tabs {
			&__tablist {
				margin-right: 0;
				margin-left: 0;
			}
		}
	}
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
	.tabs {
		.cmp-tabs {
			margin-top: 20px;

			&__tablist {
				justify-content: center;
				margin-right: unset;
				margin-left: unset;
			}

			&__tab {
				border-radius: @soft-corners;
				min-width: 165px;
				max-width: 180px;
				font-size: 18px;
				line-height: 1.2;
				background: @grey-10;
				flex-grow: inherit;
				margin: 10px 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				white-space: normal;
				height: auto;

				&--active {
					background: var(--b-blue);
					color: @white;
				}
			}
			&.mod--layout-textimage {
				& > .cmp-tabs__tablist {
					.cmp-tabs__tab {
						flex: 1 1 0;
						max-width: initial;
						.cmp-icon {
							width: 46px;
							margin: 0 auto;
						}
					}
				}
			}
		}

		&.mod--bigger-tab {
			> .cmp-tabs {
				> .cmp-tabs__tablist {
					height: auto;
					overflow-y: hidden;
					.cmp-tabs__tab {
						flex: 0 0 auto;
						white-space: normal;
						line-height: 3.4;
						padding: 18px 15px;
						&:first-of-type {
							border-left: 0px;
						}
						&:last-of-type {
							border-right: 0px;
						}
					}
				}
				&.mod--layout-textimage {
					& > .cmp-tabs__tablist {
						.cmp-tabs__tab {
							flex: 1 1 0;
							max-width: initial;
						}
					}
				}
			}
		}

		&.mod--bg-grey-20 {
			> .cmp-tabs {
				> .cmp-tabs__tablist {
					margin-bottom: 10px;
				}
			}
		}

		&.mod--tab-underlined-blue{
			.cmp-tabs {
				&__tab {
					&list {
						margin-bottom: 40px;
					}
					&:nth-child(n + 2){
						margin-left: 64px;
					}
				}
			}
		}
	
	}

	.tabs.fullWidth {
		> .cmp-tabs {
			> .cmp-tabs__tablist {
				.cmp-tabs__tab {
					margin: 10px 1px;
					flex-grow: 1;
				}
			}
		}
	}

}