.c-card-hotel-services {
    display: flex;
    flex-wrap: wrap;
    // gap: 12px;
    gap: 24px;
    margin: 16px;
    justify-content: center;
}


.c-card-hotel-services__card {
    overflow: hidden;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #D7D9DA;
    margin-bottom: 5px;
    max-width: 421px;
    display: flex;
    flex-direction: column;

    &__header {
        .cmp-image {
            max-height: 180px
        } 
    } 

    &__body {
        padding: 24px 16px;

        height: 100%;
        display: flex;
        flex-direction: column;
        &-name {
                font-family: 'Museo Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                letter-spacing: 0.0015em;
                color: #000000;
        }

        &-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: 10px;
            margin-bottom: 10px;

            &__icons {
                display: flex;
                gap: 12px;
                .c-icon {
                width: 32px;
                height: 32px;
                }
            }

            &__tags {
                padding: 4px 8px;
                background: #E8EDF0;
                border-radius: 2px;
            }
        }

        &-description, &-information {
            font-family: 'Museo Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #384044;
            margin-bottom: 24px;
            display: -webkit-box;
            max-height: 118px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
        }

        &-schedule {
            margin-bottom: 24px;
            font-family: 'Museo Sans';
            font-style: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #384044;  
            &__title {
                display: flex;
                font-weight: 600;

                    .c-card-hotel-services__card__body-schedule__icon {
                        width: 24px;
                        height: 24px;
                        background-image: url(/etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/barcelo/commons/icons/ui/History.svg);
                        background-repeat: no-repeat;
                        margin-right: 10px;
                }
            }
            &__time {
                font-weight: 300;
                padding-left: 34px;
            }
        }

        &-phone {
            font-family: 'Museo Sans';
            font-style: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #384044;
            margin-bottom: 20px;  
            &__title {
                display: flex;
                font-weight: 600;

                    .c-card-hotel-services__card__body-phone__icon {
                        width: 24px;
                        height: 24px;
                        background-image: url(/etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/barcelo/commons/icons/ui/Shape.svg);
                        background-repeat: no-repeat;
                        margin-right: 10px;
                }
            }
            &__number {
                font-weight: 300;
                padding-left: 34px;
            }
        }

        &-modal {
            font-family: 'Museo Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.005em;
            margin-top: auto;
        }
    }
}


// MEDIA - DESKTOP
@media (min-width: @desktop) {

    .c-card-hotel-services {
        max-width: @new-content-sizing;
        margin: 88px auto 0 auto;
        gap: 24px;
        
        &__card {
            max-width: 421px;
            margin-bottom: 16px;
            width: 421px;
        }
    }
    .service-tags {
        .c-card-hotel-services__card__body-wrapper {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
    .cmp-tabs {
        .c-card-hotel-services {
            margin-top: 0;
        }
    }
}