/* CMP-TITLE - MOBILE FIRST (~ TABLET) */
.cmp-title {
    text-align: center;
    &__text {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
    }
}

/* CMP-TITLE - MODIFIERS - MOBILE FIRST (~ TABLET) */
.text-right {
    .cmp-title {
        text-align: right;
    }
}

.text-center {
    .cmp-title {
        text-align: center;
    }
}

.text-left {
    .cmp-title {
        text-align: left;
    }
}
.mod--title-head {
    .cmp-title__text {
        .format-text(20px, 32px, 600);
        letter-spacing:  0.0015em;
    }
}
.mod--title-page {
    .cmp-title {
        &__text {
            .format-text(24px, 32px, 700);
        }
    }
}
.mod--title-section {
    .cmp-title {
        &__text {
            .format-text(20px, 32px, 700);
        }
    }
}
.mod--title-card {
    .cmp-title {
        &__text {
            .format-text(18px, 26px, 700);
        }
    }
}
.mod--title-content {
    .cmp-title {
        &__text {
            .format-text(16px, 26px, 700);
        }
    }
}
.mod--title-mybarcelo-help {
    .cmp-title {
        &__text {
            .format-text(20px, 20px, 600);
        }
    }
}

.mod--white-montecastillo {
    .cmp-title {
        color: @white-montecastillo;
    }
}
.mod--green-montecastillo {
    .cmp-title {
        color: @green-montecastillo;
    }
}
.mod--brown-royal {
    .cmp-title {
        color: @brown-royal;
    }
}
.mod--brown-light-royal {
    .cmp-title {
        color: @brown-light-royal;
    }
}
.mod--grey-royal {
    .cmp-title {
        color: @grey-royal;
    }
}
.mod--brown-classic {
    .cmp-title {
        color: @brown-classic;
    }
}
.mod--mybarcelo-light-grey {
    .cmp-title {
        color: #606669;
    }
}
.mod--title-breaklined {
    overflow: unset;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.75rem;
    &:before,
    &:after {
        content: "";
        height: 1px;
        background: @grey-40;
        flex-grow: 1;
    }
    .cmp-title {
        padding: 0 8px;
    }
}

.mod--service-title {
    .cmp-title {
        &__text {
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            margin-top: 9px;
            margin-bottom: 32px;
            letter-spacing: 0.0015em;
        }
    }
}



/* CMP-TITLE - DESKTOP  */
@media (min-width: @desktop) {
    .cmp-title {
        &__text {
            font-size: 24px;
            line-height: 30px;
        }
    }
    /* CMP-TITLE - MODIFIERS - DESKTOP  */
    .mod--title-head {
        .cmp-title__text {
            .format-text(28px, 36px, 600);
            letter-spacing:  0.0015em;
        }
    }
    .mod--title-page {
        .cmp-title {
            &__text {
                .format-text(36px, 44px, 700);
            }
        }
    }
    .mod--title-section {
        .cmp-title {
            &__text {
                .format-text(24px, 36px, 700);
            }
        }
    }
    .mod--title-card {
        .cmp-title {
            &__text {
                .format-text(20px, 28px, 700);
            }
        }
    }
    .mod--title-content {
        .cmp-title {
            &__text {
                .format-text(18px, 26px, 700);
            }
        }
    }

    .mod--service-title {
        .cmp-title {
            &__text {
                font-size: 36px;
                line-height: 42px;
                margin-top: 8px;
                margin-bottom: 40px;
                letter-spacing: 0.0025em;
            }
        }
    }
}



/* CMP-TITLE - MODIFIERS - MOBILE ONLY*/
@media (min-width: @mobile) and (max-width: @desktop) {
    .mod--align-left-mobile {
        .cmp-title {
            text-align: left;
        }
    }
    .mod--align-right-mobile {
        .cmp-title {
            text-align: right;
        }
    }
}
