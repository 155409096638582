.c-newsletter {
	&-form {
		.c-fieldset {
			&__row {
				padding: 4px;
				&:first-of-type {
					padding-top: 0;
    				margin-top: -4px;
				}
			}
			&:last-of-type {
				margin-top: 15px;
			}
		}
		.policy-label {
			text-overflow: initial;
			white-space: normal;
		}
		input:required ~ .policy-label {
			p {
				display: inline;
			}
			span::after {
				content: " *";
				white-space: nowrap;
			}
		}
	}

	&-modal-view {
		.c-fieldset {
			padding-top: 0;
			padding-bottom: 0px;
			&:nth-last-of-type(2) {
				margin-top: 15px;
			}
			.c-fieldset__row-wrap.mod--mobile-nowrap {
				.c-fieldset__row-wrap-col {
					padding-top: 10px;
				}
			}
		}
	}
}

@media (min-width: @desktop) {
	.c-newsletter {
		&-form {
			.c-fieldset {
				&__row {
					padding: 0px 15px;
					&:first-of-type {
						margin-top: 0;
					}
				}
			}
		}
		&-modal-view {
			.c-fieldset {
				padding: 4px 0px;
				&:nth-last-of-type(2) {
					margin-top: 0;
					margin-bottom: 0;
				}
				.c-fieldset__row-wrap.mod--mobile-nowrap {
					.c-fieldset__row-wrap-col {
						padding-top: 4px;
					}
				}
			}
		}
	}
}