/* PROGRESS BAR */
.c-progress-bar {
    display: flex;
    align-items: center;
    width: ~"calc(100% - 100px)";
    height: 30px;
    margin: 20px 50px;
    &__milestone {
        width: 100%;
        height: 18px;
        position: relative;
        display: flex;
        align-items: center;
        .common-transition;
    }
    &__goal {
        width: 0px;
    }
    &__milestone,
    &__goal {
        &-label {
            color: @grey-70;
            text-transform: uppercase;
            top: 0px;
            transform: translate(-50%, -100%);
            font-weight: 700;
            font-size: 14px;
            text-align: center;
            line-height: 20px;
            .common-transition;
        }
        &-dot {
            flex-shrink: 0;
            width: 18px;
            height: 18px;
            position: absolute;
            left: 0px;
            transform: translateX(-50%);
            line-height: 0px;
            .common-transition;
            .z-overlayer;
            &:before {
                content: "";
                background-color: @grey-45;
                position: absolute;
                .z-layout;
                width: 18px;
                height: 18px;
                border-radius: 18px;
            }
            &:after {
                content: "";
                background-color: white;
                position: absolute;
                .z-layer;
                width: 10px;
                height: 10px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 10px;
                background-size: 0px auto;
                .common-transition;
            }
        }
        &-bar {
            height: 6px;
            width: 100%;
            overflow: hidden;
            background: @grey-45;
            position: absolute;
            &:before {
                content: "";
                height: 5px;
                width: 100%;
                position: absolute;
                .z-layer;
                background: @b-green;
                top: 0px;
                left: -100%;
                transition: all 0.5s ease-in-out;
                transition-delay: var(--delay-var);
            }
        }
        &.mod--active {
            .c-progress-bar {
                &__milestone,
                &__goal {
                    &-label {
                        color: @b-green;
                        width: auto;
                    }
                    &-dot {
                        &:before {
                            background-color: @b-green;
                        }
                    }
                    &-bar {
                        &:before {
                            left: 0%;
                        }
                    }
                }
            }
        }
        &.mod--last-active {
            .c-progress-bar {
                &__milestone,
                &__goal {
                    &-bar {
                        &:before {
                            left: -50%;
                        }
                    }
                }
            }
        }
        &.mod--active:not(.mod--last-active) {
            .c-progress-bar__milestone,
            .c-progress-bar__goal {
                height: 20px;
                &-dot {
                    &:before {
                        width: 20px;
                        height: 20px;
                    }
                    &:after {
                        content: "";
                        .mix-background-sprite(20px, 6, center);
                        background-color: @b-green;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

/* PROGRESSBAR - DESKTOP */
@media (min-width: @desktop) {
    .c-progress-bar {
        display: flex;
        align-items: center;
        width: ~"calc(100% - 382px)";
        height: 30px;
        margin: 20px 191px;
    }
}
