.c-module-info-call {
    background-color: #EAEEF1;
    color: #384044;
    padding: 16px;
    border-radius: 16px;
    width: 239px;
    &__title,
    &__text {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: .05px;
    }
    &__title {
        font-weight: 600;
    }
    &__text {
        p {
            font-weight: 300;
            margin: 4px 0;
        }
    }
    .inline-cta a,
    .phone-cta a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
    .phone-cta.mod--cta-secondary-on-image a {
        margin-top: 15px;
        font-weight: 400;
        position: relative;
        color: #384044;
        &::before {
            content: "";
            position: relative;
            display: inline-block;
            background-image: url(/content/dam/barcelo/commons/icons/ui/phone_lined-light-black.png);
            top: 3px;
            left: -8px;
            background-repeat: no-repeat;
            width: 22px;
            height: 22px;
        }
    }
}