/* TRIPADVISOR STYLES*/
.c-tripadvisor {
    display: flex;
    &__rate {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .ta {
        &__score {
            display: flex;
            align-items: center;
            color: @text-color;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
        }
        &__reviews {
            color: @text-color;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            width: 100%;
        }
        &__dots {
            font-size: 0px; //prevent spacing
            height: 12px;
            margin: 0px 0.35rem;
            display: flex;
            flex-shrink: 0;
        }
        &__dot {
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 2px;
            flex-shrink: 0;
            .mix-background-sprite(12px, 0, 0px);
            &--half {
                .mix-background-sprite(12px, 1, 0px);
                width: 100%;
                height: 100%;
                margin-right: 2px;
                flex-shrink: 0;
            }
            &--full {
                .mix-background-sprite(12px, 2, 0px);
                width: 100%;
                height: 100%;
                margin-right: 2px;
                flex-shrink: 0;
            }
        }
    }
}

/* C-TRIPADVISOR - DESKTOP  */
@media (min-width: @desktop) {
    .c-tripadvisor.c-tripadvisor--box,
    .c-head-hotel-info__info {
        .ta__dots {
            height: 16px;
            .ta__dot {
                width: 16px;
                height: 16px;
                .mix-background-sprite(16px, 0, 0px);
                &--half {
                    .mix-background-sprite(16px, 1, 0px);
                }
                &--full {
                    .mix-background-sprite(16px, 2, 0px);
                }
            }
        }
    }
}