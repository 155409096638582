/* CENTRAL IMAGE COMPONENT - MOBILE FIRST */
.central-image {
	position: relative;
	.cmp-image {
		height: 100vh;
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0.65) 100%);
		}
	}
	
	&_content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		color: @white;
		&--title,
		&--subtitle {
			font-family: 'Museo Slab';
			font-weight: 100;
		}
		&--title {
			font-size: 32px;
			line-height: 36px;
			font-weight: 100;
			letter-spacing: 6px;
		}
		&--subtitle {
			font-size: 20px;
			line-height: 40px;
		}
		&--description {
			font-size: 16px;
			line-height: 20px;
			padding: 10px 0px;
			max-width: 866px;
			margin: 0 auto;
		}
		.c-cta {
			min-width: 240px;
			margin-top: 40px;
		}
	}
	.mod--rounded-royal-level {
		.cmp-image {
			border-radius: 58px;
			&::after {
				content: "";
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
				linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
			}
		}
		.logomyb {
			display: flex;
			justify-content: center;
			margin-top: 100px;
			.cmp-image {
				border-radius: 0;
				width: auto;
				height: 50px;
				&::after {
					content: none;
				}
			}
		}
		
		.central-image_content {
			width: 100%;
			padding: 0 24px;
			hyphens: auto;
			&--title {
				font-family: 'Didot', Times, serif;
				font-weight: 400;
				letter-spacing: 0;
			}
			&--subtitle {
				font-family: @font-family-base;
				margin-bottom: 20px;
			}
			&--description {
				font-size: 16px;
				line-height: 24px;
				padding: 10px 0 40px;
				max-width: 581px;
				word-break: auto-phrase;
			}
		}
		.mod--cta-primary {    
			text-align: center;
			.c-cta {
				min-width: 240px;
				margin-top: 40px;
			}
		}
		
		
	}
}
/* CENTRAL IMAGE COMPONENT - DESKTOP  */
@media (min-width: @desktop) {
	.central-image {
		.cmp-image {
			height: ~"calc(100vh + 40px)";
		}
		&_content {
			width: 90%;
			&--title {
				font-size: 130px;
				line-height: 118px;
				font-weight: 100;
				letter-spacing: 6px;
			}
			&--subtitle {
				font-size: 30px;
				line-height: 40px;
			}
			&--description {
				font-size: 20px;
				line-height: 26px;
				padding: 40px 36px;
			}
			.c-cta {
				font-weight: 300;
			}
		}

		.mod--rounded-royal-level {
			.cmp-image {
				height: 880px;
			}
			.central-image_content {
				padding: 0;
				&--title {
					font-size: 90px;
					line-height: 96px;
				}
				&--subtitle {
					font-size: 40px;
					line-height: 60px;
				}
			}
		}
		.mod--rounded-royal-level-myb {
			.cmp-image {
				height: 534px;
			}
			.central-image_content {
				&--title {
					font-size: 64px;
					line-height: 66px;
					padding: 0 164px;
					word-break: auto-phrase;
				}
			}
		}
	}
}

@media (min-width: 1800px) {
	.central-image {
		&_content {
			width: initial;
		}
	}
}

//Editor
.cq-Editable-dom.central-image {
	.cmp-image {
		height: 1800px;
	}
}