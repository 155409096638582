.popup-bubble {
  position: absolute !important;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
}
/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  width: 200px;
}
