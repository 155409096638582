//	 __          __              _             _ 
//	 \ \        / /             (_)           | |
//	  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _| |
//	   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` | |
//	    \  /\  / (_| | |  | | | | | | | | (_| |_|
//	     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
//	                                      __/ |  
//	                                     |___/   
//	
//	   ANY CHANGES MADE HERE WILL TAKE EFFECT ON THE "NEW ROYAL HIDEAWAY (BRH)" CURRENCY AND LANGUAGE COMPONENT

/* PRICE */
.c-price {
    display: inline-flex;
    &__currency {
        margin-left: 0.25rem;
    }
}
.mod--currency-left {
    .c-price {
        flex-flow: row-reverse;
        &__currency {
            margin-right: 0.25rem;
            margin-left: 0px;
            order: 3;
        }
        &__value {
            order: 2;
        }
        &__type {
            order: 1;
        }
    }
}

.currency(@country, @simbol) {
    .mod--@{country} {
        .c-price__currency:after {
            content: "@{simbol}";
        }
        .c-price__currencyCode:after {
            content: "@{country}";
        }
        .facetPrice:not(.facetPrice--@{country}) {
            display: none;
        }
    }
}

.currencyFB(@country, @simbol) {
    .mod--@{country} {
        .c-fastbooking{
            .c-price__currency:after {
                content: "@{simbol}";
            }
            .c-price__currencyCode:after {
                content: "@{country}";
            }
            .facetPrice:not(.facetPrice--@{country}) {
                display: none;
            }
        }
    }
}

.currency(AED, "د.إ");
.currency(AWG, "ƒ");
.currency(BRL, "R$");
.currency(BGN, "лв");
.currency(CHF, "CHF");
.currency(CAD, "C$");
.currency(CLP, "$");
.currency(COP, "$");
.currency(CRC, "₡");
.currency(CUP, "₱");
.currency(CZK, "Kč");
.currency(DKK, "kr");
.currency(DOP, "$");
.currency(EGP, "£");
.currency(EUR, "€");
.currency(GBP, "£");
.currency(GTQ, "Q");
.currency(HUF, "Ft");
.currency(LKR, "Rs");
.currency(MAD, "MAD");
.currency(MXN, "MXN$");
.currencyFB(MXN, "$");
.currency(NIO, "C$");
.currency(NOK, "kr");
.currency(PEN, "S/.");
.currency(PLN, "zł");
.currency(RUB, "₽");
.currency(SEK, "kr");
.currency(TRY, "₺‎");
.currency(UYU, "$U");
.currency(USD, "$");
.currency(VES, "Bs");
.currency(SAR, "SAR");
.currency(OMR, "﷼");
.currency(KWD, " د.ك");
.currency(QAR, "QR");
.currency(ILS, "₪");
.currency(BHD, "BD");
.currency(RON, "RON");
.currency(ARS, "ARS$");
.currency(IDR, "Rp");
.currency(AUD, "$");
.currency(INR, "₹");
.currency(CNY, "¥");
.currency(CVE, "Esc$");
.currency(THB, "฿");


