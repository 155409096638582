/* IMAGE COMPONENT */
.cmp-image {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: relative;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    display: none; // TODO - AEM DEVELOPER, PLEASE DELETE OR HIDE THIS CAPTION ON AEM COMP.
  }
}

.cmp-image__stamp {
  position: absolute;
  width: 35%;
  max-height: 35%;
  object-fit: scale-down;
  .z-layer;

  &.mod--top-left {
    top: 0;
    left: 0;
    padding: 15px 0px 0px 15px;
    object-position: left;
  }

  &.mod--top-right {
    top: 0;
    right: 0;
    padding: 15px 15px 0px 0px;
    object-position: right;
  }

  &.mod--bottom-left {
    bottom: 0;
    left: 0;
    padding: 0px 0px 15px 15px;
    object-position: left;
  }

  &.mod--bottom-right {
    bottom: 0;
    right: 0;
    padding: 0px 15px 15px 0px;
    object-position: right;
  }
}

/* editorial upload component */
.cq-dialog-content {
  .cq-FileUpload-icon.cq-FileUpload-picker {
    border-color: #326ec8;
  }
}

/* IMAGE LOADINGS */
.cmp-image {
  &__image {
    &.cmp-image__image--is-loading {
    }
    &.cmp-image__image--loaded {
      opacity: 0.5;
      filter: blur(10px);
      animation: transitionImage 0.5s ease-out forwards;
    }
  }
}
@keyframes transitionImage {
  from {
    opacity: 0.5;
    filter: blur(10px);
  }
  to {
    opacity: 1;
    filter: blur(0px);
  }
}
