@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

.bhg-loading {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  .bhg-loading-text {
    animation:
      fadeInUp 0.8s ease-out forwards,
      pulse 2s infinite ease-in-out;
    font-weight: 700;
    text-align: center;
    margin-top: 1rem;
  }
}
