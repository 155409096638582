/* Museo Sans */
@font-face {
  font-family: "Museo Sans";
  src: url("../../../../resources/fonts/museo-sans/MuseoSans-300.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans";
  src: url("../../../../resources/fonts/museo-sans/MuseoSans-500.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Museo Sans";
  src: url("../../../../resources/fonts/museo-sans/MuseoSans-700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Marselis */
@font-face {
  font-family: "Marselis";
  src: url("../../../../resources/fonts/marselis/marselisslabot-light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Marselis";
  src: url("../../../../resources/fonts/marselis/marselisslabot-regular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Marselis";
  src: url("../../../../resources/fonts/marselis/marselisslabot-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Museo Slab */
@font-face {
  font-family: "Museo Slab";
  src: url("../../../../resources/fonts/museo-slab/museo-slab-300.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Museo Slab";
  src: url("../../../../resources/fonts/museo-slab/museo-slab-700.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Notoserif */
@font-face {
  font-family: "Notoserif";
  src: url("../../../../resources/fonts/noto-serif/notoserif-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Didot */
@font-face {
  font-family: "Didot";
  src: url("../../../../resources/fonts/didot/didot-400.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Sfizia */
@font-face {
  font-family: "Sfizia";
  src: url("../../../../resources/fonts/sfizia/sfizia-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sfizia";
  src: url("../../../../resources/fonts/sfizia/sfizia-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sfizia";
  src: url("../../../../resources/fonts/sfizia/sfizia-regular-italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Sfizia";
  src: url("../../../../resources/fonts/sfizia/sfizia-bold-italic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/* UI ICONS */
@font-face {
  font-family: "barcelo-ui-kit";
  src: url("../../../../resources/fonts/ui-kit/barcelo-ui-kit.woff2?2s3jwb") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class*=" encoin-"],
[class^="encoin-"] {
  font-family: "Museo Sans";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.encoin-a:before {
  content: "\2C";
}
.encoin-b:before {
  content: "\2E";
}
.encoin-c:before {
  content: "\30";
}
.encoin-d:before {
  content: "\31";
}
.encoin-e:before {
  content: "\32";
}
.encoin-f:before {
  content: "\33";
}
.encoin-g:before {
  content: "\34";
}
.encoin-h:before {
  content: "\35";
}
.encoin-i:before {
  content: "\36";
}
.encoin-j:before {
  content: "\37";
}
.encoin-k:before {
  content: "\38";
}
.encoin-l:before {
  content: "\39";
}

// UI ICONS
@font-face {
  font-family: "barcelo-ui-kit";
  src: url("../../../../resources/fonts/ui-kit/barcelo-ui-kit.woff2?2s3jwb") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "barcelo-ui-kit";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone_fill:before {
  content: "\e92e";
}

.icon-dot:before {
  content: "\e92d";
}

.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-up:before {
  content: "\e903";
}
.icon-best-offer:before {
  content: "\e904";
}
.icon-burger:before {
  content: "\e905";
}
.icon-camera:before {
  content: "\e906";
}
.icon-caret-down:before {
  content: "\e907";
}
.icon-check:before {
  content: "\e908";
}
.icon-circle-check:before {
  content: "\e92f";
}
.icon-circle-uncheck:before {
  content: "\e930";
}
.icon-close:before {
  content: "\e909";
}
.icon-cross:before {
  content: "\e90a";
}
.icon-currencies:before {
  content: "\e90b";
}
.icon-double-check:before {
  content: "\e90c";
}
.icon-eye:before {
  content: "\e90d";
}
.icon-facebook:before {
  content: "\e90e";
}
.icon-feature:before {
  content: "\e90f";
}
.icon-filter:before {
  content: "\e910";
}
.icon-full-star:before {
  content: "\e911";
}
.icon-info:before {
  content: "\e912";
}
.icon-instagram:before {
  content: "\e913";
}
.icon-language:before {
  content: "\e914";
}
.icon-line:before {
  content: "\e915";
}
.icon-login:before {
  content: "\e916";
}
.icon-more:before {
  content: "\e917";
}
.icon-mybarcelo:before {
  content: "\e918";
}
.icon-needs:before {
  content: "\e919";
}
.icon-offer:before {
  content: "\e91a";
}
.icon-order:before {
  content: "\e91b";
}
.icon-phone:before {
  content: "\e91c";
}
.icon-photo-360:before {
  content: "\e91d";
}
.icon-pinterest:before {
  content: "\e91e";
}
.icon-play-video:before {
  content: "\e91f";
}
.icon-poi:before {
  content: "\e920";
}
.icon-rewards:before {
  content: "\e921";
}
.icon-search:before {
  content: "\e922";
}
.icon-simple-arrow:before {
  content: "\e923";
}
.icon-small-check:before {
  content: "\e924";
}
.icon-star:before {
  content: "\e925";
}
.icon-tick:before {
  content: "\e926";
}
.icon-triangle-down:before {
  content: "\e927";
}
.icon-tripadvisor:before {
  content: "\e928";
}
.icon-twitter:before {
  // content: "\e929";
  content: url("/etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/icons/icon-twitter_white-circled.svg");
}
.icon-video:before {
  content: "\e92a";
}
.icon-virtual-tour:before {
  content: "\e92b";
}
.icon-youtube:before {
  // content: "\e92c";
  content: url("/etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/icons/icon-youtube_white-circled.svg");
}
