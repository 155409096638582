//	 __          __              _             _ 
//	 \ \        / /             (_)           | |
//	  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _| |
//	   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` | |
//	    \  /\  / (_| | |  | | | | | | | | (_| |_|
//	     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
//	                                      __/ |  
//	                                     |___/   
//	
//	   ANY CHANGES MADE HERE WILL TAKE EFFECT ON THE "NEW ROYAL HIDEAWAY (BRH)" FASTBOOKING COMPONENT

/* NAVIGATION */

.c-close-btn {
    right: 5px;
    top: 5px;
    position: absolute;
    width: 14px;
    height: 14px;
    font-size: 14px;
    cursor: pointer;
    z-index: 2;
}

.c-back-bar {
    width: 100%;
    background: @white;
    padding: 0px 1rem;
    display: flex;
    &__back-btn {
        color: var(--b-blue);
        text-decoration: underline;
        font-size: 14px;
        line-height: 28px;
        display: inline-flex;
        align-items: center;
        svg,
        .c-icon {
            width: 12px;
            margin-right: 4px;
            path {
                fill: var(--b-blue);
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-back-bar {
        display: none;
    }
}
