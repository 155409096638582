// RESORT COLORS
// HEADER
@br-grey: #E2E2E2;
@br-grey-dark: #384044;
@br-black-05: #F5F5F6;
@br-black-10: #EBECEC;
@br-black-20: #D7D9DA;
@br-black-40: #AFB3B4;
@br-black-60: #888C8F;
@br-black-80: #606669;
@br-black-100: #384044; //font
@br-black-110: #323A3D; 
@br-black-120: #2D3336; 
@br-black-140: #161A1B; 
@br-black-160: #161A1B; 
@br-black-180: #0B0D0E; 

@br-sand-05: #FDFCF8; //font
@br-sand-10: #FCF9F1; 
@br-sand-20: #FCF9F1; //ojo es el mismo
@br-sand-40: #F8F2E4; 
@br-sand-60: #F5ECD6; 
@br-sand-80: #F1E5C9; 
@br-sand-100: #eedfbb;
@br-sand-110: #D6C9A8; //diamont
@br-sand-120: #BEB296; //font
@br-sand-140: #8F8670;
@br-sand-160: #5F594B;
@br-sand-180: #302D25; //Interactive/Secondary

@br-orange-05: #FDF8F4;
@br-orange-10: #FCF0EA;
@br-orange-20: #F8E2D5;
@br-orange-40: #F2C5AB;
@br-orange-60: #EBA782;
@br-orange-80: #E58A58;
@br-orange-100: #cc4716; //base
@br-orange-110: #C86229;
@br-orange-120: #B25725;
@br-orange-140: #be3300; //hover
@br-orange-160: #592C12;
@br-orange-180: #2C1609;

@br-green-05: #F7F8F6;
@br-green-10: #DDE3DA;
@br-green-20: #DDE3DA; //ojo es igual
@br-green-40: #BCC7B5;
@br-green-60: #9AAB90;
@br-green-80: #798F6B;
@br-green-100: #577346;
@br-green-110: #4E673F;
@br-green-120: #465C38;
@br-green-140: #34452A;
@br-green-160: #232E1C;
@br-green-180: #11170E;

@br-aqua-05: #F8FDFD;
@br-aqua-10: #E4F6F8;
@br-aqua-20: #CAEEF1;
@br-aqua-40: #CAEEF1; //ojo es igual
@br-aqua-60: #AFE5E9;
@br-aqua-80: #95DDE2;
@br-aqua-100: #7AD4DB;
@br-aqua-110: #6EBFC5;
@br-aqua-120: #62AAAF;
@br-aqua-140: #497F83;
@br-aqua-160: #315558;
@br-aqua-180: #182A2C;

@br-purple-05: #F7F6F7;
@br-purple-10: #EFEDEE;
@br-purple-20: #DEDADD;
@br-purple-40: #BEB6BB;
@br-purple-60: #9D919A;
@br-purple-80: #7D6D78;
@br-purple-100: #5C4856;
@br-purple-110: #53414D;
@br-purple-120: #4A3A45;
@br-purple-140: #372B34;
@br-purple-160: #251D22;
@br-purple-180: #120E11;


// COLOR INTERACTIVES ROLES
@interactive-primary-enabled: @br-orange-100;
@interactive-primary-active: @br-orange-160;
@interactive-primary-disabled: @br-black-40;
@interactive-primary-hover: @br-orange-140;
@interactive-primary-ghost-light: @br-orange-100;
@interactive-primary-ghost-dark: @white;
@interactive-secondary-enabled: @br-sand-140;
@interactive-secondary-active: @br-sand-180;
@interactive-secondary-disabled: @br-black-20;
@interactive-secondary-hover: @br-sand-160;
@interactive-secondary-ghost: @br-sand-140;

// HEADER BG COLORS
@header: @br-grey;
@header-active: @br-grey-dark;

// FONT COLORS
@font-body: @br-black-100;
@font-dark: @br-black-100;
@font-light: @white;
@font-medium: @br-sand-120;

@profiled-bg: @br-aqua-100;

// COLOR PALETTE POLICIES

.br-c-black {
	color: @br-black-100;
	&.br-v-05 {
		color: @br-black-05;
	}
	&.br-v-10 {
		color: @br-black-10;
	}
	&.br-v-20 {
		color: @br-black-20;
	}
	&.br-v-40 {
		color: @br-black-40;
	}
	&.br-v-60 {
		color: @br-black-60;
	}
	&.br-v-80 {
		color: @br-black-80;
	}
	&.br-v-110 {
		color: @br-black-110;
	}
	&.br-v-120 {
		color: @br-black-120;
	}
	&.br-v-140 {
		color: @br-black-140;
	}
	&.br-v-160 {
		color: @br-black-160;
	}
	&.br-v-180 {
		color: @br-black-180;
	}
}
.br-bg-black {
	background-color: @br-black-100;
	&.br-v-05 {
		background-color: @br-black-05;
	}
	&.br-v-10 {
		background-color: @br-black-10;
	}
	&.br-v-20 {
		background-color: @br-black-20;
	}
	&.br-v-40 {
		background-color: @br-black-40;
	}
	&.br-v-60 {
		background-color: @br-black-60;
	}
	&.br-v-80 {
		background-color: @br-black-80;
	}
	&.br-v-110 {
		background-color: @br-black-110;
	}
	&.br-v-120 {
		background-color: @br-black-120;
	}
	&.br-v-140 {
		background-color: @br-black-140;
	}
	&.br-v-160 {
		background-color: @br-black-160;
	}
	&.br-v-180 {
		background-color: @br-black-180;
	}
}
.br-c-sand {
	color: @br-sand-100;
	&.br-v-05 {
		color: @br-sand-05;
	}
	&.br-v-10 {
		color: @br-sand-10;
	}
	&.br-v-20 {
		color: @br-sand-20;
	}
	&.br-v-40 {
		color: @br-sand-40;
	}
	&.br-v-60 {
		color: @br-sand-60;
	}
	&.br-v-80 {
		color: @br-sand-80;
	}
	&.br-v-110 {
		color: @br-sand-110;
	}
	&.br-v-120 {
		color: @br-sand-120;
	}
	&.br-v-140 {
		color: @br-sand-140;
	}
	&.br-v-160 {
		color: @br-sand-160;
	}
	&.br-v-180 {
		color: @br-sand-180;
	}
}
.br-bg-sand {
	background-color: @br-sand-100;
	&.br-v-05 {
		background-color: @br-sand-05;
	}
	&.br-v-10 {
		background-color: @br-sand-10;
	}
	&.br-v-20 {
		background-color: @br-sand-20;
	}
	&.br-v-40 {
		background-color: @br-sand-40;
	}
	&.br-v-60 {
		background-color: @br-sand-60;
	}
	&.br-v-80 {
		background-color: @br-sand-80;
	}
	&.br-v-110 {
		background-color: @br-sand-110;
	}
	&.br-v-120 {
		background-color: @br-sand-120;
	}
	&.br-v-140 {
		background-color: @br-sand-140;
	}
	&.br-v-160 {
		background-color: @br-sand-160;
	}
	&.br-v-180 {
		background-color: @br-sand-180;
	}
}
.br-c-orange {
	color: @br-orange-100;
	&.br-v-05 {
		color: @br-orange-05;
	}
	&.br-v-10 {
		color: @br-orange-10;
	}
	&.br-v-20 {
		color: @br-orange-20;
	}
	&.br-v-40 {
		color: @br-orange-40;
	}
	&.br-v-60 {
		color: @br-orange-60;
	}
	&.br-v-80 {
		color: @br-orange-80;
	}
	&.br-v-110 {
		color: @br-orange-110;
	}
	&.br-v-120 {
		color: @br-orange-120;
	}
	&.br-v-140 {
		color: @br-orange-140;
	}
	&.br-v-160 {
		color: @br-orange-160;
	}
	&.br-v-180 {
		color: @br-orange-180;
	}
}
.br-bg-orange {
	background-color: @br-orange-100;
	&.br-v-05 {
		background-color: @br-orange-05;
	}
	&.br-v-10 {
		background-color: @br-orange-10;
	}
	&.br-v-20 {
		background-color: @br-orange-20;
	}
	&.br-v-40 {
		background-color: @br-orange-40;
	}
	&.br-v-60 {
		background-color: @br-orange-60;
	}
	&.br-v-80 {
		background-color: @br-orange-80;
	}
	&.br-v-110 {
		background-color: @br-orange-110;
	}
	&.br-v-120 {
		background-color: @br-orange-120;
	}
	&.br-v-140 {
		background-color: @br-orange-140;
	}
	&.br-v-160 {
		background-color: @br-orange-160;
	}
	&.br-v-180 {
		background-color: @br-orange-180;
	}
}
.br-c-green {
	color: @br-green-100;
	&.br-v-05 {
		color: @br-green-05;
	}
	&.br-v-10 {
		color: @br-green-10;
	}
	&.br-v-20 {
		color: @br-green-20;
	}
	&.br-v-40 {
		color: @br-green-40;
	}
	&.br-v-60 {
		color: @br-green-60;
	}
	&.br-v-80 {
		color: @br-green-80;
	}
	&.br-v-110 {
		color: @br-green-110;
	}
	&.br-v-120 {
		color: @br-green-120;
	}
	&.br-v-140 {
		color: @br-green-140;
	}
	&.br-v-160 {
		color: @br-green-160;
	}
	&.br-v-180 {
		color: @br-green-180;
	}
}
.br-bg-green {
	background-color: @br-green-100;
	&.br-v-05 {
		background-color: @br-green-05;
	}
	&.br-v-10 {
		background-color: @br-green-10;
	}
	&.br-v-20 {
		background-color: @br-green-20;
	}
	&.br-v-40 {
		background-color: @br-green-40;
	}
	&.br-v-60 {
		background-color: @br-green-60;
	}
	&.br-v-80 {
		background-color: @br-green-80;
	}
	&.br-v-110 {
		background-color: @br-green-110;
	}
	&.br-v-120 {
		background-color: @br-green-120;
	}
	&.br-v-140 {
		background-color: @br-green-140;
	}
	&.br-v-160 {
		background-color: @br-green-160;
	}
	&.br-v-180 {
		background-color: @br-green-180;
	}
}
.br-c-aqua {
	color: @br-aqua-100;
	&.br-v-05 {
		color: @br-aqua-05;
	}
	&.br-v-10 {
		color: @br-aqua-10;
	}
	&.br-v-20 {
		color: @br-aqua-20;
	}
	&.br-v-40 {
		color: @br-aqua-40;
	}
	&.br-v-60 {
		color: @br-aqua-60;
	}
	&.br-v-80 {
		color: @br-aqua-80;
	}
	&.br-v-110 {
		color: @br-aqua-110;
	}
	&.br-v-120 {
		color: @br-aqua-120;
	}
	&.br-v-140 {
		color: @br-aqua-140;
	}
	&.br-v-160 {
		color: @br-aqua-160;
	}
	&.br-v-180 {
		color: @br-aqua-180;
	}
}
.br-bg-aqua {
	background-color: @br-aqua-100;
	&.br-v-05 {
		background-color: @br-aqua-05;
	}
	&.br-v-10 {
		background-color: @br-aqua-10;
	}
	&.br-v-20 {
		background-color: @br-aqua-20;
	}
	&.br-v-40 {
		background-color: @br-aqua-40;
	}
	&.br-v-60 {
		background-color: @br-aqua-60;
	}
	&.br-v-80 {
		background-color: @br-aqua-80;
	}
	&.br-v-110 {
		background-color: @br-aqua-110;
	}
	&.br-v-120 {
		background-color: @br-aqua-120;
	}
	&.br-v-140 {
		background-color: @br-aqua-140;
	}
	&.br-v-160 {
		background-color: @br-aqua-160;
	}
	&.br-v-180 {
		background-color: @br-aqua-180;
	}
}
.br-c-purple {
	color: @br-purple-100;
	&.br-v-05 {
		color: @br-purple-05;
	}
	&.br-v-10 {
		color: @br-purple-10;
	}
	&.br-v-20 {
		color: @br-purple-20;
	}
	&.br-v-40 {
		color: @br-purple-40;
	}
	&.br-v-60 {
		color: @br-purple-60;
	}
	&.br-v-80 {
		color: @br-purple-80;
	}
	&.br-v-110 {
		color: @br-purple-110;
	}
	&.br-v-120 {
		color: @br-purple-120;
	}
	&.br-v-140 {
		color: @br-purple-140;
	}
	&.br-v-160 {
		color: @br-purple-160;
	}
	&.br-v-180 {
		color: @br-purple-180;
	}
}
.br-bg-purple {
	background-color: @br-purple-100;
	&.br-v-05 {
		background-color: @br-purple-05;
	}
	&.br-v-10 {
		background-color: @br-purple-10;
	}
	&.br-v-20 {
		background-color: @br-purple-20;
	}
	&.br-v-40 {
		background-color: @br-purple-40;
	}
	&.br-v-60 {
		background-color: @br-purple-60;
	}
	&.br-v-80 {
		background-color: @br-purple-80;
	}
	&.br-v-110 {
		background-color: @br-purple-110;
	}
	&.br-v-120 {
		background-color: @br-purple-120;
	}
	&.br-v-140 {
		background-color: @br-purple-140;
	}
	&.br-v-160 {
		background-color: @br-purple-160;
	}
	&.br-v-180 {
		background-color: @br-purple-180;
	}
}