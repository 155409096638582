.four-news {
  &_new {
    display: grid;
    grid-gap: 10px;
    padding: 25px;
    border: 1px solid @grey-50;
    border-radius: 19px;
    min-height: 240px;
    &-maintitle {
      font-size: 40px;
      font-weight: 300;
      line-height: 112.5%;

      text-transform: uppercase;
      font-family: serif;
      text-align: center;
      &.line2 {
        font-family: sans-serif;
      }
    }
    &-hasimage {
      text-align: center;
      // background: #f8f9fa;
      background: @grey-10;
      // border: 1px solid #f8f9fa;
      border: 1px solid @grey-10;
      img {
        margin: auto auto;
        max-width: 400px;
      }
    }
    &-title {
      font-size: 14px;
      text-transform: uppercase;
      line-height: 16px;
      margin-top: auto;
    }
    &-description {
      font-size: 26px;
      font-weight: 300;
      line-height: 112.5%;
      min-height: 146px;
      max-width: 217px;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }
  }
  &--desktop {
    grid-gap: 21px;
    .four-news_new {
      flex-basis: 25%;
    }
  }
  &--mobile {
    &_new {
      margin-right: 20px;
    }
    .mod--carousel-dropped-dots {
      .swiper {
        .four-news_new-description {
          max-width: none;
        }
        .swiper-pagination.swiper-pagination-bullets {
          bottom: -22px;
        }
      }
    }
  }
  &--desktop,
  &--mobile {
    margin: 35px 0;
  }
}
.tmprHideaway {
  color: @brown-royal;
  .four-news_new-maintitle,
  .four-news_new-maintitle.line2 {
    font-family: "Marselis", serif;
    text-transform: none;
  }
  .four-news {
    &_new {
      border-color: @brown-royal;
      &-description {
        min-height: 176px;
      }
    }
    &--mobile {
      &_new {
        margin-right: 20px;
      }
      .mod--carousel-dropped-dots {
        &.--black {
          .swiper {
            .swiper-pagination.swiper-pagination-bullets {
              .swiper-pagination-bullet {
                background: @brown-royal;
              }
            }
          }
        }
      }
    }
  }
}
