/* C-BURGER */
.c-burger {
    width: 28px;
    height: 26px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    &__bar {
        position: absolute;
        width: 28px;
        height: 2px;
        background-color: @white;
        border-radius: 2px;
        box-shadow: 1px 1px 1px 0px rgba(102, 0, 0, 0);
        .common-transition;
        &.mod--top-bread {
            top: 5px;
        }
        &.mod--meat {
            top: 12px;
        }
        &.mod--bottom-bread {
            top: 19px;
        }
    }
}

.activeMegamenu {
    .c-burger {
        &__bar {
            &.mod--top-bread {
                transform: rotate(45deg);
                top: 13px;
            }
            &.mod--meat {
                width: 0px;
                opacity: 0;
            }
            &.mod--bottom-bread {
                transform: rotate(-45deg);
                top: 13px;
            }
        }
    }
}
