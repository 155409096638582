.cmp-text {
	&__readmore {
		color: @grey-90;
		font-size: 16px;
    	line-height: 24px;
		font-weight: 400;
		.cmp-icon {
			width: 24px;
			height: 24px;
			margin-left: 0.25rem;
			.c-icon {
				width: 15px;
				height: 15px;
				filter: invert(23%) sepia(13%) saturate(415%) hue-rotate(155deg) brightness(95%) contrast(92%);
			}
		}
		&.read_more {
			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 50px;
				top: -50px;
				background: rgb(255,255,255);
				background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 9%, rgba(255,255,255,0) 90%, rgba(255,255,255,0) 100%);
			}
		}
	}
}
// POLICIES
.br-c-white {
	.cmp-text {
		p {
			color: #ffffff;
		}
	}
}
.bg-gradient--marron-light {
	.cmp-text__readmore {
		&.read_more {
			&::before {
				background: linear-gradient(0deg,@brown-light-royal 0,@brown-light-royal 9%,rgba(237,227,221,0) 90%,rgba(237,227,221,0) 100%);
			}
		}
	}
}
.bg-gradient--grey-light {
	.cmp-text__readmore {
		&.read_more {
			&::before {
				background: linear-gradient(0deg,@grey-royal 0,@grey-royal 9%,rgba(237,227,221,0) 90%,rgba(237,227,221,0) 100%);
			}
		}
	}
}
