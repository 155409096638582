/* HELPERS BUTTONS */

.c-helper-button {
    .unstyle-anchor;
    position: fixed;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 66px;
    transition: none;
    height: 64px;
    margin-right: -1px;
    right: -100%;
    text-align: center;
    border-top-left-radius: @circle;
    border-bottom-left-radius: @circle;
    .z-navs;
    background-color: @b-light-blue;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
    [class^="icon-"] {
        width: 100%;
        font-size: 36px;
        margin: 5px 0 0 5px;
        -webkit-text-stroke: 1.2px var(--b-blue);
        color: @b-light-blue;
    }
    &__text {
        width: 100%;
        font-weight: 500;
        font-size: 12px;
        color: var(--b-blue);
        padding-left: 5px;
    }
}

.mod--first-helper {
    top: 57.5%;
}

.c-go-up-btn {
    position: fixed;
    right: -100%;
    margin-right: 16px;
    top: ~"calc(57.5% + 64px + 30px)";
    height: 42px;
    width: 42px;
    display: none;
    .z-navs;
    .c-cta-container {
        width: 100%;
        height: 100%;
        .c-cta.cta__icon:hover {
            background: @b-intense-blue;
        }
    }
    .cta__icon {
        font-size: 20px;
        line-height: 42px;
    }
    &.show-right {
        display: block;
    }
}

/* HELPERS BUTTONS - DESKTOP  */
@media (min-width: @desktop) {
    .c-go-up-btn {
        height: 56px;
        width: 56px;
        &:before {
            font-size: 28px;
            line-height: 56px;
        }
    }
}