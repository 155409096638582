/* MEDIA CONTAINERS */
.c-container-image {
    position: relative;
}

.c-container-video {
    overflow: hidden;
    position: relative;
}

.generic-video {
    width: 100%;
    height: 100%;
    .c-video-responsive {
        width: 100%;
        height: 100%;
    }
    .c-video {
        width: 100%;
        height: 100%;
        &--image-wrapper {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
