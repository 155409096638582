/* TOOLTIP - MOBILE FIRST (~ TABLET) */

.bhg-tooltip {
    position: relative;
    &--hidden {
    }
    &--icon {
        cursor: pointer;
        font-family: "barcelo-ui-kit";
        text-align: center;
        &:after {
            content: "\e912";
            font-weight: 900;
            font-size: 1em;
        }
        &:before {
            content: "";
            pointer-events: none;
            border: solid transparent;
            height: 0px;
            width: 0px;
            border-width: 8px 8px 8px 8px;
            border-bottom-color: #ffffff;
            position: absolute;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            .common-transition;
            z-index: -1;
            opacity: 0;
            bottom: -0.9em;
        }
    }

    &--text {
        position: absolute;
        z-index: 1;
        background: white;
        margin-top: 2.1em;
        padding: 21px 24px;
        border-radius: @soft-corners;
        transform: translateX(-50%);
        left: 50%;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
        font-size: 14px;
        font-weight: 500;
        width: 300px;
        line-height: 20px;
        color: @grey-90;
        .common-transition;
        z-index: -1;
        opacity: 0;
        margin-top: 2.6em;
    }
    /* TOOLTIP - MODIFIERS - MOBILE FIRST*/
    &.mod--active {
        .bhg-tooltip--icon:before {
            opacity: 1;
            bottom: -0.5em;
            .z-overmodal;
        }
        .bhg-tooltip--text {
            margin-top: 2.2em;
            opacity: 1;
            .z-modal;
        }
    }
    &.mod--right {
        .bhg-tooltip--text {
            transform: translateX(-24px);
        }
    }
    &.mod--left {
        .bhg-tooltip--text {
            transform: translateX(24px);
            left: inherit;
            right: 0px;
        }
    }
    &[data-color="black"] {
        .bhg-tooltip--icon {
            &:before {
                border-bottom-color: @grey-90;
            }
        }
        .bhg-tooltip--text {
            background: @grey-90;
            color: @white;
        }
    }
    &[data-color="blue"] {
        .bhg-tooltip--icon {
            &:before {
                border-bottom-color: var(--b-blue);
            }
        }
        .bhg-tooltip--text {
            background: var(--b-blue);
            color: @white;
        }
    }
    &[data-color="white"] {
        .bhg-tooltip--icon {
            &:before {
                border-bottom-color: @white;
            }
        }
        .bhg-tooltip--text {
            background: @white;
            color: @grey-90;
        }
    }

    &[data-coloricon="black"] {
        .bhg-tooltip--icon {
            color: @grey-90;
        }
    }
    &[data-coloricon="blue"] {
        .bhg-tooltip--icon {
            color: var(--b-blue);
        }
    }
    &[data-coloricon="white"] {
        .bhg-tooltip--icon {
            color: @white;
        }
    }
}
