.datepicker__month-button,
.datepicker__month-day {
  -webkit-transition-duration: 0.2s;
  -webkit-transition-property: color, background-color, border-color;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.datepicker__month-day--no-checkin:after,
.datepicker__month-day--no-checkout:after {
  background-color: rgba(255, 0, 0, 0.1);
  bottom: 0;
  content: "";
  top: 0;
  z-index: -1;
  position: absolute;
}
.datepicker__close-button,
.datepicker__month-name,
.datepicker__week-name {
  text-transform: uppercase;
}
.datepicker {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  width: 260px;
  z-index: 1;
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 8px 8px 40px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 8px 8px 40px 5px rgba(0, 0, 0, 0.08);
  color: #484c55;
  font-family: Helvetica, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  line-height: 14px;
}
.datepicker__inner {
  overflow: hidden;
  padding: 20px;
}
.datepicker__month {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
  font-size: 12px;
}
.datepicker__info--feedback,
.datepicker__month--month2 {
  display: none;
}
.datepicker__month-day--valid {
  cursor: pointer;
}
.datepicker__month-day--lastMonth,
.datepicker__month-day--nextMonth {
  visibility: hidden;
}
.datepicker__info--error,
.datepicker__info--help {
  display: block;
}
.datepicker__tooltip {
  position: absolute;
}
.datepicker__month-day--no-checkin,
.datepicker__month-day--no-checkout,
.datepicker__topbar {
  position: relative;
}
.datepicker__month-caption {
  border-bottom: 1px solid #dcdcdc;
  height: 2.5em;
  vertical-align: middle;
}
.datepicker__week-days {
  height: 2em;
  vertical-align: middle;
}
.datepicker__week-name {
  font-size: 11px;
  font-weight: 400;
}
.datepicker__month-day {
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: #acb2c1;
  padding: 9px 7px;
}
.datepicker__month-day--no-checkin:after {
  display: block;
  left: 0;
  right: 50%;
}
.datepicker__month-day--no-checkout:after {
  display: block;
  left: 50%;
  right: 0;
}
.datepicker__month-day--invalid {
  color: #e8ebf4;
}
.datepicker__month-day--disabled {
  color: #e8ebf4;
  position: relative;
}
.datepicker__month-day--disabled:after {
  content: "\00d7";
  left: 50%;
  position: absolute;
  color: red;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.datepicker__month-day--day-of-week-disabled {
  background-color: rgba(232, 235, 244, 0.5);
}
.datepicker__month-day--selected {
  background-color: rgba(116, 107, 253, 0.2);
  color: #fff;
}
.datepicker__month-day--selected:after {
  display: none;
}
.datepicker__month-day--hovering {
  background-color: rgba(116, 107, 253, 0.3);
  color: #fff;
}
.datepicker__month-day--today {
  background-color: #484c55;
  color: #fff;
}
.datepicker__month-day--first-day-selected,
.datepicker__month-day--last-day-selected {
  background-color: #746bfd;
  color: #fff;
}
.datepicker__month-day--last-day-selected:after {
  content: none;
}
.datepicker__month-button {
  cursor: pointer;
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #d6dae5;
  border-radius: 4px;
  color: #9da6b8;
  display: inline-block;
  padding: 5px 10px;
}
.datepicker__month-button:hover {
  background-color: #746bfd;
  color: #fff;
}
.datepicker__topbar {
  margin-bottom: 20px;
}
.datepicker__info-text {
  font-size: 13px;
}
.datepicker__info--selected {
  font-size: 11px;
  text-transform: uppercase;
}
.datepicker__info--selected-label {
  color: #acb2c1;
}
.datepicker__info-text--selected-days {
  font-size: 11px;
  font-style: normal;
}
.datepicker__info--error {
  color: red;
  font-size: 13px;
  font-style: italic;
}
.datepicker__info--help {
  color: #acb2c1;
  font-style: italic;
}
.datepicker__close-button {
  cursor: pointer;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: color, background-color, border-color;
  transition-property: color, background-color, border-color;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #746bfd;
  border-radius: 4px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 10px;
  color: #fff;
  margin-top: 20px;
  padding: 7px 13px;
  text-decoration: none;
  text-shadow: none;
}
.datepicker__close-button:hover {
  background-color: #484c55;
  color: #fff;
}
.datepicker__tooltip {
  background-color: #ffe684;
  border-radius: 2px;
  font-size: 11px;
  margin-top: -5px;
  padding: 5px 10px;
}
.datepicker__tooltip:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #ffe684;
  bottom: -4px;
  content: "";
  left: 50%;
  margin-left: -4px;
  position: absolute;
}
@media (min-width: 320px) {
  .datepicker {
    width: 300px;
  }
}
@media (min-width: 480px) {
  .datepicker {
    width: 460px;
  }
  .datepicker__months {
    overflow: hidden;
    position: relative;
  }
  .datepicker__month {
    width: 200px;
  }
  .datepicker__month--month1 {
    float: left;
  }
  .datepicker__month--month2 {
    display: table;
    float: right;
  }
  .datepicker__month-button--disabled {
    visibility: hidden;
  }
  .datepicker__months:before {
    background: #dcdcdc;
    bottom: 0;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 0;
    width: 1px;
  }
}
@media (min-width: 768px) {
  .datepicker {
    width: 560px;
  }
  .datepicker__month {
    width: 240px;
  }
  .datepicker__close-button {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
