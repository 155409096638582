@import (once) "./src/main/webpack/clientlibs/base/less/inc/custom-vendors/swiper.less";


/* COMPONENT HEROSLIDER MOBILE FIRST (~ TABLET)*/
.heroslider {
  background-color: @grey-20;

  .c-video {
    height: 100%;

    .c-video-responsive {
      height: 100%;
    }
  }

  .c-heroslider {
    height: 300px;

    // HEROSLIDER - CENTRAL BLOCK - MOBILE FIRST (~ TABLET)

    &__central-block {
      width: 100%;
      max-width: ~"calc(1140px - 96px)";
      height: auto;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      position: absolute;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      text-align: center;
      .z-overlayer;

      &_titles {
        overflow: hidden;
        margin: auto;
        .mod--white-text;

        &_title {
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
        }

        &_subtitle {
          font-size: 16px;
          line-height: 24px;
          display: block;
          font-weight: 500;
          text-align: center;
        }

        &_image {
          padding: 15px 40px;

          .cmp-image {
            height: auto;
            width: 100%;

            &__image {
              object-fit: contain;
            }

            &:after {
              background: transparent !important;
              /* TO AVOID THE GRADIENT OVERLAY POLICY */
            }
          }
        }
      }

      &_cta {
        width: 100%;
        text-align: center;
        margin-bottom: 50px;

        .c-cta {
          .unstyle-anchor;
          .mod--white-text;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          padding: 6px 10px;
          border-radius: 16px;
          background: fade(@black, 60%);
          opacity: 0.8;
          .common-transition;

          &:before {
            font-family: "barcelo-ui-kit";
            content: "\e906";
            margin-right: 9px;
            font-size: 20px;
            vertical-align: text-bottom;
          }

          &:hover {
            opacity: 1;
            color: white;

            &:before {
              color: white;
            }
          }
        }
      }

      &_stamp {
        .z-background;
        max-height: 130px;
        max-width: 130px;
        position: absolute;
        top: 7px;
        right: 15px;

        .cmp-image {
          max-height: 130px;
          width: auto;

          &__image {
            object-fit: contain;
            object-position: top center;
          }

          &:after {
            content: none;
          }
        }
      }
    }

    &.mod--layout-vacational, &.mod--layout-urban {
      height: 260px;
      margin-bottom: unset;

      .c-heroslider__urban-gallery {
        display: none;
      }

      .c-heroslider__central-block {
        max-width: unset;
        height: 100%;

        &_cta {
          width: unset;
          right: 16px;
          bottom: 16px;
          margin-bottom: 0;

          .c-cta {
            text-shadow: none;
            font-weight: 400;
            padding: 4px 8px;
            background: rgba(56, 64, 68, 0.7);
            border-radius: 8px;
          }
        }

        &__share {
          text-shadow: none;
          font-weight: 400;
          padding: 4px 8px;
          background: rgba(56, 64, 68, 0.7);
          border-radius: 8px;
          background: rgba(56, 64, 68, 0.7);
          position: absolute;
          display: flex;
          gap: 24px;
          top: 16px;
          right: 16px;

          &-social {
            display: none;
          }
        }
      }

      .c-heroslider__central-block {
        display: none;

        &_cta {
          position: absolute;
          z-index: 2;
        }

        &_titles {
          width: auto;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          z-index: 2;
        }

        &__share {
          z-index: 1;
        }

      }

      .swiper-pagination {
        display: flex;
        gap: 8px;
        justify-content: center;
        position: absolute;
        bottom: 24px;

        &.swiper-pagination-bullets {
          .swiper-pagination-bullet {
            border-radius: 6px;
            width: 6px;
            height: 6px;
            background: #FFFFFF;
            margin: unset;

            &-active {
              width: 20px;
              border-radius: 100px;
              border: unset;
              transform: none;

            }
          }
        }
      }

      .swiper-button-prev, .swiper-button-next {
        display: none;
        width: 32px;
        height: 32px;
      }

      .c-mask {
        display: none;
      }

      .breadcrumb {
        display: none;
      }
    }

    &.mod--layout-service {
      height: 260px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 63.75%);
      margin-bottom: unset;

      &.swiper-initialized {
        margin-bottom: 40px;
        overflow: visible;
      }

      .swiper-button-prev, .swiper-button-next {
        display: none;
      }

      .c-heroslider__central-block {
        max-width: unset;
        flex-flow: initial;
        align-items: flex-end;
        justify-content: initial;
        text-align: unset;
        margin-top: 76px;

        top: initial;
        bottom: 0;
        transform: translate(-50%, -17px);

        &_titles {
          padding-left: 16px;
          margin: unset;

          &_title {
            font-family: 'Museo Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 8px;
            letter-spacing: 0.0015em;
            text-align: unset;
          }

          &_subtitle {
            font-family: 'Museo Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: 0.0015em;
            text-align: unset;
          }
        }
      }

      .swiper-pagination {
        display: flex;
        gap: 68px;
        justify-content: center;

        @keyframes service-line-animation {
          0% {
            width: 0px;
            left: unset;
          }
          33% {
            width: 76px;
          }

          79% {
            width: 76px;
            left: unset;
            right: -70px;
          }

          100% {
            width: 0px;
            right: -76px;
            left: unset;
            display: none;
          }
        }

        &.swiper-pagination-bullets {
          .swiper-pagination-bullet {
            border-radius: 6px;
            width: 8px;
            height: 8px;
            background: #384044;
            border: 1px solid #384044;
            position: relative;
            margin: unset;
            opacity: 1;

            &::before {
              display: none;
              content: "";
              width: 0px;
              height: 2px;
              background-color: #384044;
              position: absolute;
              top: 2px;
            }

            &-active {
              background: #384044;
              width: 8px;
              height: 8px;
              transform: unset;
              opacity: 1;

              &::before {
                display: block;
                animation-name: service-line-animation;
                animation-duration: 5s;
              }

              @keyframes service-circle-animation {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              }

              &::after {
                content: "";
                width: 32px;
                height: 32px;
                border: 1px solid #384044;
                background-color: transparent;
                position: absolute;
                top: -13px;
                left: -13px;
                border-radius: 100%;
                animation-name: service-circle-animation;
                animation-duration: 0.75s;
              }

              &:last-child {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }

      .breadcrumb {
        display: none;
      }
    }

    // BREADCRUMB
    &.inject-breadcrumb-true {
      &.mod--layout-vacational,
      &.mod--layout-urban {
        overflow: visible;

        .breadcrumb,
        .cmp-breadcrumb {
          display: block;

          &__item {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            padding-top: 24px;
            padding-bottom: 4px;

            &-link {
              span {
                text-decoration: underline;
              }

              &:after {
                content: "/";
                margin: 0 8px;
              }
            }
          }
        }
      }
    }

    &.mod--layout-campaign {
      height: auto;

      &.inject-breadcrumb-true {
        .breadcrumb {
          display: none;
        }
      }

      .c-heroslider__central-block {
        display: flex;
        flex-direction: column-reverse;

        position: relative;
        height: auto;
        transform: none;
        left: initial;
        top: initial;

        &_titles {
          opacity: 1;
          text-align: left;
          width: 100%;
          background: white;
          text-shadow: none;
          border-radius: 24px 24px 0px 0px;
          margin-top: -30px;
          z-index: 1;

          .cmp-breadcrumb {
            &__item {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: @grey-90;
            }
          }

          &_title {
            font-weight: 600;
            font-size: 36px;
            line-height: 42px;
            letter-spacing: 0.0025em;
            padding: 24px 16px;
            color: @grey-90;
            text-align: left;
          }

          &_subtitle {
            display: none;
          }
        }
      }

      .c-heroslider__image-masked {
        width: 100%;
        height: auto;

        defs {
          display: none;
        }
      }
    }

    &.mod--layout-ia {
      height: auto;

      .c-heroslider__central-block_titles {
        &_title {
          font-size: 36px;
          font-weight: 400;
          line-height: 42px;
          letter-spacing: 0.09px;
        }
        &_subtitle {
          display: block;
          text-align: left;
          padding: 0 16px;
          .cmp-text {
            font-weight: 300;
            letter-spacing: 0.08px;
            &:nth-of-type(2) {
              margin-top: 8px;
            } 
            .cmp-text__readmore {
              margin-top: 16px;
            }
          } 
        }
      }

      &.inject-breadcrumb-true {
        .breadcrumb {
          display: none;
        }
      }

      .c-heroslider__central-block {
        display: flex;
        flex-direction: column-reverse;

        position: relative;
        height: auto;
        transform: none;
        left: initial;
        top: initial;

        &_titles {
          opacity: 1;
          text-align: left;
          width: 100%;
          background: white;
          text-shadow: none;
          border-radius: 24px 24px 0px 0px;
          margin-top: -30px;

          .cmp-breadcrumb {
            display: block;
            margin: 20px 16px 0;
            &__item {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: @grey-90;
            }
          }

          &_title {
            font-weight: 600;
            font-size: 36px;
            line-height: 42px;
            letter-spacing: 0.0025em;
            padding: 24px 16px;
            color: @grey-90;
            text-align: left;
          }

          &_subtitle {
            display: block;
            color: initial;
            text-align: start;
            padding: 0 16px;
          }
        }

      }

      .c-heroslider__image-masked {
        width: 100%;
        height: auto;
        align-self: start;

        defs {
          display: none;
        }
      }
    }

    &.mod--layout-carousel-campaign {
      height: 260px;

      .inject-breadcrumb {
        display: none;
      }

      .c-heroslider__central-block {
        transform: none;
        top: initial;
        bottom: 0;
        left: 16px;

        &_titles {
          margin-left: 0;
          margin-bottom: 48px;

          &_title {
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            text-align: left;
          }

          &_subtitle {
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            text-align: left;
            margin-bottom: 8px;
          }
        }

        &_cta {
          margin-bottom: 0;
          text-align: left;

          .c-cta {
            margin: 8px 0 0;
            padding: 6px 32px;
            min-width: 147px;
            height: 48px;
            background: #FFFFFF;
            border-radius: 100px;
            font-weight: 600;
            font-size: 16px;
            line-height: 32px;
            color: #2F5A76;
            text-shadow: none;
            white-space: nowrap;
            opacity: 1;

            &:before {
              content: none;
            }

            &:hover {
              background: @grey-25;
              border-color: @grey-25;
              color: @b-dark-blue;
              box-shadow: none;
            }

            &:active {
              background: @b-dark-blue;
              border-color: @b-dark-blue;
              color: @white;
            }
          }
        }

        &__share {
          text-shadow: none;
          font-weight: 400;
          padding: 4px 8px;
          border-radius: 8px;
          background: rgba(56, 64, 68, .7);
          position: absolute;
          display: flex;
          gap: 24px;
          top: 16px;
          right: 16px;
          z-index: 2;
        }
      }
    }

    &.mod--layout-free-carousel-campaign {
      height: 388px;
      margin-bottom: 4px;
      display: flex;
      .inject-breadcrumb {
        display: none;
      }

      .c-heroslider__central-block {
        bottom: 0;
        height: 100%;
        padding: 16px;

        height: 126px;
        justify-content: flex-end;
        // top: ~"calc(100vw - 100px)";
        // height: 2px;
        // align-self: flex-end;
        top: initial;
        transform: none;
        left: 0px;
        overflow: initial;
        &_titles {
          display: flex;
          flex-direction: column;
          margin-left: 0;
          max-width: 465px;
          /* max-width: 50%; */
          margin-bottom: 0;
          text-align: left;
          overflow: visible;
          // margin-bottom: -20px;

          &_title {
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            max-height: 112px;
            width: 70%;
            text-align: left;
            order: 2;
            word-wrap: normal;
          }

          &_subtitle {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;
            text-align: left;
            order: 1;
          }
          &_description {
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            order: 3;
          }
          &_ctas {
            display: none;
            order: 4; 
            margin-top: 8px;

            &>div:first-of-type {
              margin-right: 24px;
            }
          }
        }

        &_cta {
          margin-bottom: 0;
          text-align: left;
          width: auto;
          right: 0;
          position: absolute;
          
          .c-cta {
            // margin: 8px 0 0;
            // padding: 6px 32px;
            // min-width: 147px;
            // height: 48px;
            // background: #FFFFFF;
            // background: inherit;
            // border-radius: 100px;
            // font-weight: 600;
            // font-size: 16px;
            // line-height: 32px;
            // color: #2F5A76;
            // text-shadow: none;
            // white-space: nowrap;
            // opacity: 1;

            // &:before {
            //   content: none;
            // }

            // &:hover {
            //   background: @grey-25;
            //   border-color: @grey-25;
            //   color: @b-dark-blue;
            //   box-shadow: none;
            // }

            &:active {
              // background: @b-dark-blue;
              // border-color: @b-dark-blue;
              // color: @white;
            }
          }
        }
      }
      .c-btn-over-image {
        right: 16px;
        top: 16px;
        img {
          border-radius: 6px;
        }
      }
      &.mod--align-titles-right {
        .c-heroslider__central-block {
          // right: 0;
          &_titles {
            margin-right: 0;
            align-self: flex-end;
          }
        }
      }
    }

    // HEROSLIDER - BACKGROUND AND SWIPER - MOBILE FIRST (~ TABLET)
    .cmp-image,
    .image {
      height: 100%;
    }

    // BOTTOM MASK
    .c-mask {
      bottom: -1px;

      svg {
        height: 100%;
      }

      .z-layer;

      .convex-mask {
        transform: rotate(180deg);
      }
    }

    // HEROSLIDER - MENU IMAGES - MOBILE FIRST (~ TABLET)
    &.c-hero__menu-enabled {
      margin-bottom: 175px;

      .c-heroslider__cta {
        margin-bottom: 80px;
      }
    }

    &__menu-wrapper {
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      max-width: @content-sizing;
      left: 50%;
      transform: translateX(-50%);
      bottom: 40px;
      padding: 0px;
      .z-mask;

      .c-hero-menu--item {
        .common-transition;
        height: 128px;
        .unstyle-anchor;
        .mod--white-text;
        border-radius: @soft-corners;
        box-shadow: 0px 1px 2px 0px fade(@black, 30%);
        border: 6px solid white;
        margin: 0px;
        position: relative;
        overflow: hidden;

        .item__title {
          font-size: 20px;
          line-height: 24px;
          position: absolute;
          margin: 16px 17px;
          bottom: 0px;
          .z-layer;
        }

        .item__image {
          border-radius: 2px;
          overflow: hidden;
          height: 128px;

          &.mod--gradient-overlay .cmp-image:after,
          &.mod--gradient-overlay .c-video:after {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0.65) 100%);
          }
        }
      }

      .swiper .swiper-pagination.swiper-pagination-bullets {
        bottom: -15px;
      }
    }
  }

  // HEROSLIDER POLICIES - MOBILE FIRST (~ TABLET)
  &.carousel {
    margin: 0;
    position: relative;
  }

  &.mod--gradient-overlay {
    .cmp-image,
    .c-video {
      &:after {
        .shadow-overlay(to bottom, 0.35, 0%, 0.175, 50%, 0, 100%);
      }
    }

    &--white {
      .cmp-image,
      .c-video {
        &:after {
          content: "";
          position: absolute;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.6) 40%, rgba(255, 255, 255, 0.4) 60%, rgba(255, 255, 255, 0.1) 100%);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 2px;
          .common-transition;
        }
      }
    }
  }

  &.mod--white-montecastillo {
    .c-heroslider__central-block_titles {
      color: @white-montecastillo;
    }
  }

  &.mod--green-montecastillo {
    .c-heroslider__central-block_titles {
      color: @green-montecastillo;
    }
  }

  &.mod--brown-royal {
    .c-heroslider__central-block_titles {
      color: @brown-royal;
    }
  }

  &.mod--brown-light-royal {
    .c-heroslider__central-block_titles {
      color: @brown-light-royal;
    }
  }

  &.mod--grey-royal {
    .c-heroslider__central-block_titles {
      color: @grey-royal;
    }
  }

  &.mod--brown-classic {
    .c-heroslider__central-block_titles {
      color: @brown-classic;
    }
  }

  &.mod--full-heroslider,
  &.mod--full-height-heroslider {
    .c-heroslider {
      height: ~"calc(100vh - 63px)";

      &.inject-fastbooking-true {
        .c-heroslider {
          height: 100vh;
        }
      }
    }
  }

  &.mod--lg-heroslider {
    .c-heroslider {
      height: ~"calc(100vh - 45px)";
    }
  }

  &.mod--md-heroslider {
    .c-heroslider {
      height: 248px;
    }
  }

  &.mod--sm-heroslider {
    .c-heroslider {
      height: 185px;
    }
  }

  // layout-campaign
  &.mod--full-heroslider,
  &.mod--full-height-heroslider,
  &.mod--lg-heroslider,
  &.mod--md-heroslider,
  &.mod--sm-heroslider {
    .mod--layout-campaign.c-heroslider .mod--layout-ia.c-heroslider {
      height: auto;
    }
  }
}

[data-template="hotel-royal"] {
  .c-favorite-share.c-heroslider__central-block__share.c-cta {
    display: none;
  }
}

/* HEROSLIDER WITH COLLAPSE HEADER - MOBILE FIRST */
header.xpf_reference_header.c-container-collapsed-desktop + main .heroslider,
header.xpf_reference_header.c-container-collapsed + main .heroslider {
  .c-heroslider {
    &__central-block {
      &_stamp {
        top: @header-mobile-height;
        padding-top: 7px;
      }
    }

    &.mod--layout-urban,
    &.mod--layout-vacational {
      .c-heroslider__central-block__share {
        top: 78px;
      }
    }
  }
}

/* HEROSLIDER WITH INJECTED FASTBOOKING - MOBILE FIRST */
header.xpf_reference_header + main .heroslider.mod--full-heroslider.inject-fastbooking-true,
header.xpf_reference_header + main .heroslider.mod--lg-heroslider.inject-fastbooking-true,
header.xpf_reference_header + main .heroslider.mod--full-height-heroslider.inject-fastbooking-true,
header.xpf_reference_header + main .heroslider.mod--lg-heroslider.inject-fastbooking-true {
  .c-heroslider {
    &__central-block {
      height: ~"calc(100% + " @ifb-mobile-position-full ~" - " @header-mobile-height ~")";
      top: ~"calc(50% - " @header-mobile-height ~")";

      &_stamp {
        top: 0px;
        padding-top: 7px;
      }
    }
  }
}

header.xpf_reference_header + main .heroslider.mod--md-heroslider.inject-fastbooking-true {
  .c-heroslider {
    &__central-block {
      height: ~"calc(100% + " @ifb-mobile-position-md ~" - " @header-mobile-height ~")";
      top: 50%;

      &_stamp {
        top: 0px;
        padding-top: 7px;
      }
    }
  }
}

header.xpf_reference_header + main .heroslider.mod--sm-heroslider.inject-fastbooking-true {
  .c-heroslider {
    &__central-block {
      height: ~"calc(100% + " @ifb-mobile-position-sm ~" - " @header-mobile-height ~")";
      top: 50%;

      &_stamp {
        top: 0px;
        padding-top: 7px;
      }
    }
  }
}

.hotel {
  .heroslider {
    .c-heroslider {
      &__central-block {
        height: calc(100% - 100px);
        overflow: initial;

        .c-heroslider__central-block_cta {
          bottom: 0;
          position: absolute;
          margin-bottom: 0;
        }
      }
    }
  }
}

/* COMPONENT HEROSLIDER - DESKTOP  */
@media (min-width: @desktop) {
  .heroslider {
    .c-heroslider {
      height: 616px;

      // HEROSLIDER - CENTRAL BLOCK - DESKTOP
      &__central-block {
        &_titles {
          &_title {
            max-width: 1000px;
            font-size: 36px;
            line-height: 44px;
          }

          &_subtitle {
            font-size: 20px;
            line-height: 36px;
          }

          &_image {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;

            .cmp-image {
              width: 25vw;
              height: auto;
            }
          }
        }

        &_stamp {
          max-height: 200px;
          max-width: 200px;
          top: 15px;

          .cmp-image {
            max-height: 200px;
          }
        }
      }

      &.mod--layout-vacational {
        height: 500px;
        margin-bottom: unset;

        .c-heroslider__central-block {
          max-width: unset;

          &_cta {
            width: unset;
            right: 66px;
            bottom: 24px;
            margin-bottom: 0;
          }

          &__share {
            top: 24px;
            right: 66px;

            &-social {
              display: initial;
            }
          }
        }

        .swiper-pagination {
          display: none;
        }

        .swiper-button-prev {
          display: block;
          left: 66px;

          &::after {
            left: 9px;
            font-size: 12px;
          }
        }

        .swiper-button-next {
          display: block;
          right: 66px;

          &::after {
            right: 9px;
            font-size: 12px;
          }
        }
      }

      &.mod--layout-service {
        height: 360px;
        margin-bottom: unset;

        &.swiper-initialized {
          margin-bottom: 40px;
          overflow: visible;
        }

        .c-heroslider__central-block {
          width: 100%;
          left: 0px;
          text-align: left;
          top: 40%;
          transform: unset;
          bottom: initial;

          &_titles {
            max-width: @new-content-sizing;
            width: 100%;
            margin: 0 auto;
            padding-left: 0;

            &_title {
              font-size: 24px;
              line-height: 34px;
            }

            &_subtitle {
              font-weight: 400;
              font-size: 60px;
              line-height: 70px;
              letter-spacing: -0.005em;
            }
          }
        }

        .swiper-pagination {
          bottom: -44px;
        }
      }

      // central
      &.mod--layout-vacational,
      &.mod--layout-urban {
        .c-heroslider__central {
          max-width: @new-content-sizing;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          margin: 0 ~"calc(50% - 656px)";

        }

      }

      &.mod--layout-vacational {
        .c-heroslider__central {
          .c-heroslider__central-block_cta,
          .c-heroslider__central-block__share,
          .swiper-button-next {
            right: 0;
          }

          .swiper-button-prev {
            left: 0;
          }
        }
      }

      &.mod--layout-urban {
        .c-heroslider__central {
          .c-heroslider__central-block_cta,
          .c-heroslider__central-block__share,
          .swiper-button-next {
            right: 24;
          }

          .swiper-button-prev {
            left: 24;
          }
        }
      }


      &.mod--layout-urban {
        height: 500px;

        .swiper-wrapper-overflow {
          display: none;
        }

        .swiper-pagination {
          display: none
        }

        .c-heroslider__urban-gallery {
          width: 100%;
          max-width: @new-content-sizing;
          display: flex;
          margin: 0 auto;
          padding: 0;
          position: relative;
          flex-direction: row;

          &-items {
            display: flex;
            width: 50%;
            flex-wrap: wrap;
            gap: 12px;
            height: 360px;
            height: 500px;

            .swiper-slide {
              width: 48%;
              height: 244px;

              .cmp-image {
                border-radius: 8px;
              }

              &:first-child {
                width: 50%;
                position: absolute;
                right: 0px;
                height: 500px;
              }
            }
          }

          &-last-item {
            width: 50%;
            height: 500px;
            margin-left: 12px;

            @media (min-width: 1369px) {
              margin-left: 6px;
            }
          }
        }

        .c-heroslider__central-block {
          width: calc(100% - 300px);
          max-width: @new-content-sizing;

          &__share {
            top: 24px;
            right: 34px;
          }
        }
      }

      // BREADCRUMB
      &.inject-breadcrumb-true {
        &.mod--layout-vacational,
        &.mod--layout-urban {
          .inject-breadcrumb {
            position: relative;
            max-width: none;
            left: 0;
            transform: none;
            width: auto;
            margin: 0 auto;

            .c-container__grid-wrapper {
              max-width: @new-content-sizing;
            }

            .cmp-breadcrumb__list {
              padding: 20px 0;
            }

            .cmp-breadcrumb__item,
            .cmp-breadcrumb__item-link {
              color: @black;
            }
          }
        }
      }

      &.mod--layout-campaign {
        .c-heroslider__central-block {
          max-width: @new-content-sizing;
          flex-direction: row;
          justify-content: space-between;
          margin: 40px auto;

          &_titles {
            margin-top: initial;
            margin: auto 0;
            border-radius: initial;
            background: initial;

            .cmp-breadcrumb {
              display: block;
              padding-left: 4px;

              &__item-link {
                &:after {
                  content: "/";
                  font-size: 14px;
                }

                span {
                  text-decoration: underline;
                }
              }
            }

            &_title {
              font-weight: 400;
              font-size: 48px;
              line-height: 56px;
              padding: 10px 200px 24px 0px;
            }

          }
        }

        .c-heroslider__image-masked {
          width: 654px;
          height: 400px;
          flex-shrink: 0;

          defs {
            display: initial;
          }
        }
      }

      &.mod--layout-ia {
        .c-heroslider__central-block {
          max-width: @new-content-sizing;
          flex-direction: row;
          justify-content: space-between;
          margin: 40px auto;
          align-items: flex-start;

          &_titles {
            margin-top: initial;
            border-radius: initial;
            background: initial;
            margin: 0;

            .cmp-breadcrumb {
              display: block;
              padding-left: 4px;

              &__item-link {
                &:after {
                  content: "/";
                  font-size: 14px;
                }

                span {
                  text-decoration: underline;
                }
              }
            }

            &_title {
              padding: 32px 16px 32px 0;
              font-size: 36px;
              font-weight: 400;
              line-height: 42px;
              letter-spacing: 0.09px;
            }

            &_subtitle {
              display: block;
              text-align: left;
              color: @black;
              font-size: 16px;
              line-height: 24px;
              padding: 0 16px 0 0;
            }
          }
        }

        .c-heroslider__image-masked {
          width: 654px;
          height: 400px;
          flex-shrink: 0;

          defs {
            display: initial;
          }
        }
      }

      &.mod--layout-carousel-campaign {
        height: 388px;

        .c-heroslider__central-block {
          transform: translate(-50%, 0);
          left: 50%;
          top: initial;
          min-height: 216px;

          &_titles {
            margin-left: 38px;
            text-align: left;
            margin-bottom: 32px;
            margin-top: 0;

            &_title {
              font-weight: 400;
              font-size: 60px;
              line-height: 70px;
              text-align: left;
            }

            &_subtitle {
              font-weight: 300;
              font-size: 24px;
              line-height: 34px;
              text-align: left;
              margin-bottom: 8px;
            }
          }

          &_cta {
            .c-cta {
              margin: 24px 0 0;
            }
          }
        }
      }

      &.mod--layout-free-carousel-campaign {
        height: 388px;
        margin-bottom: 20px;

        .c-heroslider__central-block {
          max-width: ~"calc("@new-content-sizing ~" - 12px)";
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          // height: 100%;
          height: 2px;
          padding: 0;
          justify-content: initial;
          // height: initial;
          overflow: initial;
          &_titles {
            text-align: left;
            height: 100%;
            max-width: 50%;
            margin-top: 0;
            justify-content: center;

            &_title {
              font-weight: 400;
              font-size: 48px;
              line-height: 56px;
              text-align: left;
              max-height: 112px;
            }

            &_subtitle {
              font-weight: 600;
              font-size: 20px;
              line-height: 28px;
              text-align: left;
            }

            &_description {
              font-weight: 300;
              font-size: 20px;
              line-height: 28px;
              text-align: left;
            }
            &_ctas {
              display: flex;
            }
          }
          &_cta {
            margin-bottom: 32px;
            bottom: -194px;
            .c-cta {
              margin: 24px 0 0;
            }
          }
          .c-btn-over-image {
            top: 36px;
            right: 0;
            img {
              border-radius: 6px;
            }
          }
        }
        .swiper-button-prev,
        .swiper-rtl .swiper-button-next {
            left: ~"calc(calc(92% - " @new-content-sizing ~") / 2)";
        }
        .swiper-button-next,
        .swiper-rtl .swiper-button-prev {
            right: ~"calc(calc(92% - " @new-content-sizing ~") / 2)";
        }
      }

      // HEROSLIDER - BACKGROUND AND SWIPER - DESKTOP
      .mod--swiper-inside-fullscreen;

      // HEROSLIDER - MENU IMAGES - DESKTOP
      &.c-hero__menu-enabled {
        margin-bottom: 150px;
      }

      &__menu-wrapper {
        bottom: 40px;
        .z-mask;

        .c-hero-menu--item {
          height: auto;
          margin: 0px 15px;

          .item__title {
            font-size: 24px;
            line-height: 28px;
            position: absolute;
          }

          .item__image {
            height: 152px;
          }

          &:first-child {
            margin-left: 0px;
          }

          &:last-child {
            margin-right: 0px;
          }
        }
      }

      &.swiper {
        .c-heroslider__sliderWrapper {
          position: relative;
          width: 100%;
          height: auto;
          overflow: hidden;
        }
      }

      &__cta {
        padding: 0px 15px;
      }
    }

    &.mod--sm-heroslider,
    &.mod--md-heroslider,
    &.mod--lg-heroslider,
    &.mod--full-heroslider,
    &.mod--full-height-heroslider {
      .c-heroslider {
        height: 616px;
      }
    }

    // HEROSLIDER - POLICIES - DESKTOP
    &.mod--sm-heroslider--desktop {
      .c-heroslider {
        height: 220px;
      }

      &.inject-fastbooking-true {
        .c-mask.mod--heroslider {
          top: auto;
        }
      }
    }

    &.mod--md-heroslider--desktop {
      .c-heroslider {
        height: 466px;
      }

      &.inject-fastbooking-true {
        .c-mask.mod--heroslider {
          top: auto;
        }
      }
    }

    &.mod--lg-heroslider--desktop {
      .c-heroslider {
        height: 616px;
      }

      &.inject-fastbooking-true {
        .c-mask.mod--heroslider {
          top: auto;
        }
      }
    }

    &.mod--full-heroslider,
    &.mod--full-height-heroslider--desktop {
      .c-heroslider {
        height: ~"calc(100vh - 181px)";
      }

      &.inject-fastbooking-true {
        .c-heroslider {
          height: 100vh;
        }

        .c-mask.mod--heroslider {
          top: auto;
        }
      }
    }
  }

  /* HEROSLIDER WITH COLLAPSE HEADER - DESKTOP */
  header.xpf_reference_header.c-container-collapsed-desktop + main .heroslider,
  header.xpf_reference_header.c-container-collapsed + main .heroslider {
    .c-heroslider {
      &__central-block {
        &_stamp {
          top: @header-desktop-height;
          padding-top: 15px;
        }
      }
    }
  }

  /* HEROSLIDER WITH INJECTED FASTBOOKING - DESKTOP */
  header.xpf_reference_header + main .heroslider.inject-fastbooking-true,
  header.xpf_reference_header + main .heroslider.mod--full-heroslider.inject-fastbooking-true,
  header.xpf_reference_header + main .heroslider.mod--full-height-heroslider.inject-fastbooking-true,
  header.xpf_reference_header + main .heroslider.mod--lg-heroslider.inject-fastbooking-true,
  header.xpf_reference_header + main .heroslider.mod--full-heroslider--desktop.inject-fastbooking-true,
  header.xpf_reference_header + main .heroslider.mod--full-height-heroslider--desktop.inject-fastbooking-true,
  header.xpf_reference_header + main .heroslider.mod--lg-heroslider--desktop.inject-fastbooking-true {
    .c-heroslider {
      &__central-block {
        height: ~"calc(100% - (" @header-desktop-height ~" + " @ifb-desktop-height ~" + " @ifb-desktop-position ~"))";
        top: 50%;

        &_stamp {
          top: 0px;
          padding-top: 7px;
        }
      }
    }
  }

  header.xpf_reference_header + main .heroslider.inject-fastbooking-true,
  header.xpf_reference_header + main .heroslider.mod--md-heroslider.inject-fastbooking-true,
  header.xpf_reference_header + main .heroslider.mod--sm-heroslider.inject-fastbooking-true,
  header.xpf_reference_header + main .heroslider.mod--md-heroslider--desktop.inject-fastbooking-true,
  header.xpf_reference_header + main .heroslider.mod--sm-heroslider--desktop.inject-fastbooking-true {
    .c-heroslider {
      &__central-block {
        height: ~"calc(100% - (" @header-desktop-height ~" + " @ifb-desktop-height ~" + " @ifb-desktop-position ~"))";
        top: ~"calc(50% - " @header-mobile-height ~")";

        &_stamp {
          top: 0px;
          padding-top: 7px;
        }
      }
    }
  }
}

/* COMPONENT HEROSLIDER - DESKTOP (CONTENT SIZE)*/
@media (min-width: @desktop) and (max-width: @content-sizing) {
  .heroslider {
    .c-heroslider {
      .mod--swiper-inside-buttons;
    }
  }
}


@media (max-width: @desktop) {
  .heroslider.inject-fastbooking-true {
    .c-heroslider__central-block {
      .c-heroslider__central-block_titles {
        margin: auto;
      }
    }
  }
}


//Policies
.heroslider {
  &.mod--arrows-white {
    .swiper-button-next, .swiper-button-prev {
      background: #FFFFFF;
      border: 1px solid #D7D9DA;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

      &::after {
        color: #384044;
      }
    }
  }
}

//Carousel Dropped Dots
.swiper {
  &.mod--carousel-dropped-dots {
    .swiper-pagination.swiper-pagination-bullets {
      display: flex;
      gap: 8px;
      justify-content: center;
      position: absolute;
      bottom: 24px;

      .swiper-pagination-bullet {
        border-radius: 6px;
        width: 6px;
        height: 6px;
        background: #fff;
        margin: unset;
      }

      .swiper-pagination-bullet-active {
        width: 20px;
        border-radius: 100px;
        border: unset;
        transform: none;
      }
    }
  }
}