/* C-FASTBOOKING__SUBMENU - MOBILE FIRST*/
.c-fastbooking__submenu {

    &-destination,
    &-search,
    &-calendar,
    &-rooms,
    &-discount {
        &.mod--active {
            display: flex;
            animation: popOverFadeIn 0.35s ease-out forwards;
        }
        &.mod--hide {
            display: none;
            animation: popOverFadeOut 0.35s ease-out forwards;
        }
        .accordion-toggle {
            .svg-icon {
                &.accordion-arrow {
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }

    &-header {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin: 15px 0 10px;
        width:auto;
        margin-right: 10px;
    }

    &-anywhere {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: @grey-20;
        padding: 9px;
        box-shadow: 0px 1px 0px 0px fade(@black, 30%);
        height: 40px;
        width: 100%;

        &-claim {
            font-size: 14px;
            font-weight: 500;
            .ellipsis;
            flex-grow: 1;
        }
    }

    &-destination {
        width: 100vw;
        max-width: 1140px;
        .c-popover__content-block {
            &:nth-child(4) {
                border-right: 0px;
            }
            .mod--accordion-disabled-dsktp {
                &.accordion-item {
                    .accordion-label {
                        font-weight: 700;
                    }
                }
            }
        }
        &-no-search-result {
            display: flex;
            padding: 10px;
            align-items: center;
            background: @b-light-blue;
            &_text {
                padding-left: 10px;
                font-weight: 500;
                line-height: 20px;
                p {
                    font-size: 12px;
                }
            }
        }

        .svg-icon {
            margin-right: 0.25rem;
        }
        &--filter-btn {
            .svg-icon {
                margin-right: 10px;
            }
        }


        &-history {
            padding-bottom: 10px;
            .link {
                width: 95%;
            }
            ul {
                 li {
                    margin-bottom: 12px;
                 }
            }
            .history__hotel {
                &-info {
                    .unstyle-anchor;
                    display: block;
                    font-size: 14px;
                    line-height: 20px;
                    margin: 0px 0px 12px;
                }

                &-name {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 700;
                }

                &-direction,
                &-features {
                    color: @grey-70;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 20px;
                }

                &-features {

                    .sprite-icon-adult {
                        width: 20px;
                        height: 32px;
                        .mix-background-sprite(30px, 29, -7px);
                    }

                    .sprite-icon-child {
                        width: 20px;
                        height: 32px;
                        .mix-background-sprite(30px, 31, -7px);
                    }
                }
            }

            .mod--accordion-disabled-mobile {
                &.accordion-content {
                    &>.accordion-item {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
                .accordion {
                    &-item,
                    &-item.active {
                        height: 32px;
                        overflow: hidden;
                        margin-bottom: 0;
                        &>.accordion-toggle {
                            .svg-icon {
                                &.accordion-arrow {
                                    display: none;
                                }
                            }
                        }
                        &::after {
                            content: none;
                        }
                        .accordion-label {
                            font-size: 16px;
                            font-weight: initial;
                            width: 100%;
                            padding-left: 0;
                        }

                    }
                    &-toggle {
                        .accordion-label {
                            line-height: 2;
                        }
                    }
                    &-content {
                        &>.accordion-item {
                            .accordion-label {
                                line-height: 1.6;
                                padding-left: 26px;
                            }
                            &::after {
                                content: none;
                            }
                            &:before {
                                content: "";
                                position: absolute;
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 5px 0 5px 8.7px;
                                border-color: transparent transparent transparent var(--b-blue);
                                background-image: none;
                                padding: 0;
                                z-index: 1;
                                top: 12px;
                                left: 10px;
                            }
                        }
                    }
                }
            }
        }

        &-hotels {
            ul {
                li {
                    .unstyle-anchor;
                    font-size: 16px;
                    margin: 0.25rem 0px 10px 0px;
                    line-height: 20px;
                    padding-left: 0;
                    a {
                        text-decoration: none;
                        color: @grey-90;
                    }
                }
            }
        }
        &-suggest {
            .suggest__hotels {
                .unstyle-anchor;
                font-size: 14px;
                margin: 0.25rem 0px;
                display: block;
            }
        }

        &-top {
            .top__destination {
                .unstyle-anchor;
                .mod--white-text;
                position: relative;
                display: block;
                margin-bottom: 8px;
                .launcher {
                    .c-launcher {
                        .c-launcher__image {
                            &-content {
                                &-title {
                                    font-size: 14px;
                                    line-height: 16px;
                                }
                            }
                        }
                    }
                }
                .c-container-image {
                    height: 120px;
                    width: ~"calc(100vw - 30px)";
                    max-width: 349px;
                    width: auto;
                }

                &-content {
                    width: ~"calc(100% - 30px)";
                    position: absolute;
                    bottom: 11px;
                    left: 15px;
                    font-weight: 700;

                    &-header {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
        }

        &-no-search {
            &-result {
                margin-bottom: 10px;
            }

            &-again {
                span {
                    strong {
                        color: var(--b-blue);
                    }
                }
            }

            .value-no-search-JS {
                font-weight: 700;
            }
        }

        &-tooltip {
            .ellipsis;
            background: @b-light-blue;
            border-radius: @soft-corners;
            padding: 9px;
            box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.3);
            margin-bottom: 9px;
        }

        &-history,
        &-hotels,
        &-suggest,
        &-top {
            &>.accordion {
                &-item {
                    &:after {
                        height: 0px;
                    }
                    &>.accordion-toggle {
                        .svg-icon {
                            &.accordion-arrow {
                                border-radius: 100%;
                                background: @b-light-blue;
                                height: 24px;
                                width: 24px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-top: 6px;
                                .cmp-icon {
                                    width: 16px;
                                    height: 16px;
                                    padding-top: 3px;
                                }
                            }
                        }
                    }
                }
                &-label {
                    justify-self: flex-start;
                    margin-right: auto;
                }
            }
            .mod--accordion-disabled-dsktp {
                &> .accordion-content {
                    position: relative;
                    overflow-y: auto;
                    &>li {
                        margin: 0;
                        padding: .25rem 10px;
                    }
                    & .resort-child{
                        padding-left: 42px;
                        line-height: 20px;
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                    & .resort-parent{
                        line-height: 20px;
                        padding-left: 26px;
                        position: relative;
                        &::after { 
                            content: none; 
                        } 
                        &:before { 
                            content: ""; 
                            width: 0; 
                            height: 0; 
                            border-style: solid; 
                            border-width: 5px 0 5px 8.7px; 
                            border-color: transparent transparent transparent var(--b-blue); 
                            background-image: none; 
                            padding: 0; 
                            z-index: 1; 
                            top: 8px; 
                            left: 10px; 

                            display: inherit;
                            position: absolute;
                        }
                    }
                }
            }

        }
    }

    &-search {
        width: 100vw;
        max-width: 1140px;
        padding-bottom: 20px;

        &-header {
            display: none;
        }
        .svg-icon {
            margin-right: 0.25rem;
        }
        .c-popover__content-block {
            border-bottom: 0;
            &:first-of-type{
                .c-fastbooking__submenu-header {
                    margin: 15px 0 18px 10px;
                }
            }
            &:nth-child(3) {
                border-right: 0px;
            }
        }

        &-hotels,
        &-destination,
        &-tematics,
        &-experiences {
            position: relative;
            overflow-y: auto;
            a {
                .unstyle-anchor;
                display: block;
                margin: 0.25rem 0px;
            }
            ul li {
                line-height: 2;
            }
            .accordion {
                &-item {
                    &>.accordion-toggle {
                        .svg-icon {
                            &.accordion-arrow {
                                border-radius: 100%;
                                background: @white;
                                height: 24px;
                                width: 24px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .cmp-icon {
                                    width: 16px;
                                    height: 16px;
                                    padding-top: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-hotels,
        &-destination {
            ul li {
                padding-left: 30px;
                &:before {
                    content: "";
                    position: absolute;
                    background-size: 20px 20px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                    padding: 16px 10px;
                    left: 2px;
                }
            }
        }
        &-hotels {
            margin-top: -8px;
            ul li {
                &:before {
                    background-image: url(/content/dam/barcelo/commons/icons/statics/pin.svg);
                }
            }
            .accordion {
                &-item,
                &-item.active {
                    &>.accordion-toggle {
                        .svg-icon {
                            &.accordion-arrow {
                                display: none;
                            }
                        }
                    }
                    &::after {
                        content: none;
                    }
                    .accordion-label {
                        font-size: 16px;
                        font-weight: initial;
                        width: 100%;
                        padding-left: 0;
                    }
                }
                &-toggle {
                    .accordion-label {
                        line-height: 2;
                    }
                }
                &-content {
                    &>.accordion-item {
                        .accordion-label {
                            line-height: 1.6;
                            padding-left: 26px;
                        }
                        &::after {
                            content: none;
                        }
                        &:before {
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 5px 0 5px 8.7px;
                            border-color: transparent transparent transparent var(--b-blue);
                            background-image: none;
                            padding: 0;
                            z-index: 1;
                            top: 10px;
                            left: 10px;
                        }
                    }
                }
            }
        }
        &-destination {
            ul li {
                &:before {
                    background-image: url(/content/dam/barcelo/commons/icons/statics/hotelbuilding.svg);
                }
            }
        }

        .link {
            width: 95%;
        }

        &-destination-list {
            font-weight: 700;
        }

        .mod--cta-with-arrow {
            position: absolute;
            bottom: 15px;
            left: 25px;

            .c-cta {
                font-size: 14px;
            }
        }
    }

    &-destination,
    &-search {
        .c-popover__content-block {
            padding: 0 15px;
            .accordion {
                &-item {
                    &.active {
                        &>.accordion-toggle {
                            .svg-icon {
                                &.accordion-arrow {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                    &::after {
                        content: none;
                    }
                    .accordion-label {
                        font-size: 16px;
                        font-weight: initial;
                    }
                }
                &-toggle {
                    padding: 0;
                    padding-left: 10px;
                }
            }

            /* width */
            *::-webkit-scrollbar {
                width: 7px;
            }
            /* Track */
            *::-webkit-scrollbar-track {
                background: #F5F5F5;
                border-radius: 10px;
            }
            /* Handle */
            *::-webkit-scrollbar-thumb {
                background: #D8D8D8;
                border-radius: 10px;
            }
            /* Handle on hover */
            *::-webkit-scrollbar-thumb:hover {
                background: #D8D8D8;
            }
            /* Works on Firefox */
            * {
                scrollbar-width: thin;
                scrollbar-color: #D8D8D8 #F5F5F5;
            }
        }
    }

    &-destination {
        &.mod--input-search {
            padding: 10px 0;
            overflow: scroll;
            .c-fastbooking__submenu-anywhere {
                display: none;
            }
            .c-popover__content-block {
                border-bottom: 0;
            }

            .accordion-item {
                &.active {
                    &>.accordion-content {
                        margin-bottom: 0;
                    }
                }
            }

            .accordion-item.mod--accordion-disabled-dsktp {
                &>.accordion-toggle {
                    display: none;
                }
                &.active {
                    &>.accordion-content.mod--accordion-disabled-mobile {
                        overflow: visible;
                        max-height: none;
                        &>.accordion-item.active {
                            height: auto;
                            &>.accordion-content {
                                margin-bottom: 0;
                                &>.accordion-item {
                                    &.active {
                                        height: auto;
                                    }
                                    &::before {
                                        content: none;
                                    }
                                    &>.accordion-content {
                                        &>.history__hotel-name {
                                            font-size: 16px;
                                            font-weight: 400;
                                            color: inherit;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .c-fastbooking__submenu-destination {
                &-history {
                    &>.accordion-item {
                        .accordion-toggle {
                            margin-bottom: 0;
                            .accordion-label {
                                padding-left: 20px;
                                line-height: 2;
                            }
                            &:before {
                                content: "";
                                position: absolute;
                                background-size: 20px 20px;
                                background-position: center center;
                                background-repeat: no-repeat;
                                width: 20px;
                                height: 20px;
                                padding: 16px 10px;
                                left: 2px;
                                background-image: url(/content/dam/barcelo/commons/icons/statics/pin.svg);
                            }
                        }
                        .accordion-content {
                            .accordion-item {
                                height: initial;
                                padding: 0;
                            }
                            &>.history__hotel-name {
                                padding-left: 30px;
                                line-height: 2;
                                margin-bottom: 0px;
                                &:before {
                                    content: "";
                                    position: absolute;
                                    background-size: 20px 20px;
                                    background-position: center center;
                                    background-repeat: no-repeat;
                                    width: 20px;
                                    height: 20px;
                                    padding: 16px 10px;
                                    left: 2px;
                                    background-image: url(/content/dam/barcelo/commons/icons/statics/pin.svg);
                                }
                            }
                        }
                        
                    }
                }
                &-hotels {
                    &>.accordion-item {
                        &>.accordion-content {
                            overflow: visible;
                            &>li {
                                padding-left: 30px;
                                padding-top: 6px;
                                padding-bottom: 6px;
                                &:before {
                                    content: "";
                                    position: absolute;
                                    background-size: 20px 20px;
                                    background-position: top center;
                                    background-repeat: no-repeat;
                                    width: 20px;
                                    height: 20px;
                                    padding: 16px 10px;
                                    left: 2px;
                                    background-image: url(/content/dam/barcelo/commons/icons/statics/hotelbuilding.svg);
                                }
                            }
                        }
                    }
                }
            }
        }

        &-history {
            .accordion-item {
                &.mod--accordion-disabled-dsktp {
                    .show-destination__cta {
                        font-weight: 700;
                        font-size: 16px;
                        padding-left: 10px;
                        margin-top: auto;
                        text-align: left;
                    }
                }
            }
        }
    }

    &-search {
        padding: 10px 0;
        overflow: scroll;
        &-hotels {
            &>ul {
                // pais
                &>.accordion-item {
                    padding-left: 0;
                    &::before {
                        z-index: 1;
                    }
                    &.active {
                        &>.accordion-content {
                            margin-bottom: 0;
                        }
                    }
                    &>.accordion-toggle {
                        &>.accordion-label {
                            padding-left: 20px;
                        }
                    }
                    &>.accordion-content {
                        margin-bottom: 0;
                        // comunidad
                        &>.accordion-item {
                            padding-left: 0;
                            &::before {
                                content: none;
                            }
                            .accordion-toggle {
                                margin-bottom: 0;
                                .accordion-label {
                                    padding-left: 20px;
                                    line-height: 2;
                                }
                                &:before {
                                    content: "";
                                    position: absolute;
                                    background-size: 20px 20px;
                                    background-position: center center;
                                    background-repeat: no-repeat;
                                    width: 20px;
                                    height: 20px;
                                    padding: 16px 10px;
                                    left: 2px;
                                    background-image: url(/content/dam/barcelo/commons/icons/statics/pin.svg);
                                }
                            }
                        }
                    }
                }
            }
        }
        &-destination {
            height: auto;
            overflow: visible;
            &>ul {
                // pais
                &>li {
                    line-height: 22px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    &:before {
                        content: "";
                        position: absolute;
                        background-size: 20px 20px;
                        background-position: top center;
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 20px;
                        padding: 16px 10px;
                        left: 2px;
                        background-image: url(/content/dam/barcelo/commons/icons/statics/hotelbuilding.svg);
                    }
                }
            }
        }
    }

    &-calendar {
        .c-popover__content-block {
            padding: 0px;
        }

        &-header {
            display: block;
            border-bottom: 1px solid @grey-40;
            position: relative;

            &-close {
                width: 42px;
                height: 42px;
                position: absolute;
                right: -15px;
                top: -15px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                
                .cmp-icon {
                    width: 16px;
                    height: 16px;
                }
            }

            &-select { 
                position: relative;
                // padding: 15px 0px;
                padding: 8px 0 4px;
                width: 100%;
                border-bottom: 1px solid @grey-40;
                flex-shrink: 0;

                &-title {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 700;
                }

                &-number {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 500;
                    margin-top: 0px;
                }

                &-selected {
                    width: 100%;
                }

                .c-adv {
                    padding: 8px;
                    min-height: 66px;
                    width: 100%;

                    &__message {
                        font-size: 14px;
                        line-height: normal;
                    }
                }
            }

            &-legend {
                width: 100%;

                &-container {
                    display: flex;
                }

                &-labels {
                    // padding: 5px 15px 5px 0px;
                    font-size: 11px;
                    padding: 0;
                }

                &-item {
                    font-size: 12px;
                    line-height: 20px;
                    font-weight: 500;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    padding: 5px 15px;

                    &-figure {
                        width: 14px;
                        height: 14px;
                        border-radius: @soft-corners;
                        background-color: @grey-20;
                        display: inline-block;
                        margin-right: 8px;

                        &.mod--offers {
                            background-color: @b-light-blue;
                        }
                    }
                }

                /**TODO: Review Norberto**/
                &__relateds-legends {
                    height: 100%;
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                }

                
            }
        }

        &-suggest {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 15px;
            border-bottom: 1px solid @grey-40;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;

            &-item {
                color: var(--b-blue);
                font-weight: 500;
                background-color: @grey-20;
                border-radius: 12px;
                padding: 2px 8px;
                margin: 0px 4px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .c-fastbooking__calendar-wrapper {
            .c-fastbooking__calendar {
                display: none;
            }
        }

        .c-fastbooking__calendar-message-spinner {
            position: absolute;
            top: 65%;
            width: 100%;
            display: flex;
            justify-content: center;
            z-index: 5;
        }

        &-prices-room {
            display: flex;
            padding: 15px;
            border-top: 1px solid @grey-40;
        }

        &.mod--enabledrounded {
            border: 1px solid @grey-38; //#D7D9DA;
            max-width: inherit;
            border-radius: 16px;
            margin: 0;
            padding: 0 16px 16px 16px;
            .c-fastbooking__submenu-calendar {
                &-header {
                    border-bottom: 0;
                    margin-bottom: 16px;
                    &-close {
                        height: 32px;
                        top: -8px;
                        cursor: pointer;
                    }
                    &-select {
                        &-title {
                            font-weight: 400;
                        }
                        &-number {
                            // font-size: 12px;
                            // line-height: 16px;
                            // font-weight: 300;
                            align-items: center;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 20px; 
                            letter-spacing: 0.035px;
                            .text-divider {
                                padding: 5px 0;
                                border-right: 2px solid @grey-38;
                            }
                            .text-error {
                                display: inline-flex;
                                align-items: center;
                                color: @red-error-bright;
                                .c-icon {
                                    width: 15px;
                                }
                            }
                            .text-help {
                                margin-top: 16px;
                                border-top: 1px solid #D7D9DA;
                                padding-top: 16px;
                            }
                        }
                    }
                    &-legend {
                       
                        &-container {
                            display: flex;
                        }
        
                        &-labels {
                            padding: 5px 0;
                            & > span {
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: 300;
                                color: #384044;
                                padding: 4px 8px;
                                border-radius: 4px;
                                &.mod--green-text {
                                    background-color: #D9E8DB;
                                }
                                &.mod--yellow-text {
                                    background-color: #FFF8E7;
                                }
                                &.mod--red-text {
                                    background-color: #FCE1E2;
                                }
                                &:nth-child(3) {
                                    display: initial;
                                }
                            }
                        }
                        .c-adv {
                            width: 100%;
                            // margin-bottom: 24px;
                            flex-shrink: 1;
                            background: #E5F4FE;
                            box-shadow: none;
                            border-radius: 8px;
                            margin: 24px 0;
                            .svg-icon {
                                align-self: flex-start;
                                background: #0091F0;
                                border-radius: 100px;
                                padding: 0 3px;
                                .cmp-icon {
                                    filter: brightness(0) invert(1);
                                }
                            }
                            &__message {
                                &.mod--vr-left {
                                    border-left-color: transparent;
                                    padding-left: 12px;
                                    margin-left: 0;
                                }
                                &--title {
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 24px; 
                                    letter-spacing: 0.08px;
                                }
                                &--closed {
                                    font-size: 14px;
                                    font-weight: 300;
                                    line-height: 20px; 
                                    letter-spacing: 0.035px;
                                }
                            }
                        }
                    }
                    &-info {
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: 300;
                        color: @grey-90;
                    }
                }
            }
            .c-hotel-related {
                &__content {
                    padding: 0 20px;
                    .c-hotel-related__content_cards {
                        padding: 0;
                        .c-cards {
                            height: auto;
                        }
                        .cb-slidy__wrapper {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    &-rooms {
        width: 100vw;

        &-room {
            border-color: @grey-50;
            margin: 1em 0px;
            width: 100%;

            .cmp-title {
                margin-bottom: -16px;
                justify-content: flex-start;

                .cmp-title__text {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 700;
                    text-align: left;
                }

                &:before {
                    width: 15px;
                    flex-grow: 0;
                    background-color: @grey-50;
                }

                &:after {
                    flex-grow: 1;
                    background-color: @grey-50;
                }
            }

            &-body {
                padding: 0px 20px;

                .sprite-icon-adult {
                    width: 30px;
                    height: 38px;
                    .mix-background-sprite(38px, 29, -5px);
                }

                .sprite-icon-child {
                    width: 30px;
                    height: 40px;
                    .mix-background-sprite(40px, 31, -5px);
                }

                .input-adult {
                    padding-left: 25px;
                    .mix-background-sprite(40px, 29, 0px);
                }

                .input-child {
                    padding-left: 25px;
                    .mix-background-sprite(40px, 31, 0px);
                }

                &.accordion {
                    &-item {
                        &:after {
                            content: none;
                        }

                        &.active {
                            .accordion-toggle {
                                border-bottom: 1px solid @grey-40;
                            }

                            .accordion-content {
                                margin-bottom: 11px;
                            }
                        }

                        .accordion-toggle {
                            font-size: 14px;
                            font-weight: 500;
                            border-bottom: 1px solid transparent;
                        }

                        .accordion-content {
                            label {
                                display: initial;
                                margin-bottom: 0;
                                font-size: 18px;
                                font-weight: 300;
                                .remove-ellipsis;
                                .input-child-ico-span {
                                    .c-icon {
                                        width: auto;
                                        height: 28px;
                                    }
                                }
                            }

                            .children-age {
                                border-top: 1px solid @grey-40;
                                margin-top: 15px;
                                padding-top: 15px;
                                position: relative;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;

                                .c-popover__content {
                                    width: 100%;
                                }

                                &:before {
                                    font-family: "barcelo-ui-kit";
                                    content: "\e900";
                                    background: @white;
                                    position: absolute;
                                    font-size: 18px;
                                    top: -8px;
                                    color: @grey-40;
                                    left: 29px;
                                    height: 20px;
                                    width: 17px;
                                    overflow: hidden;
                                }

                                &-column {
                                    display: flex;
                                    justify-content: space-between;

                                    &-item {
                                        width: 50%;
                                        flex-grow: 1;
                                        flex-shrink: 0;

                                        label {
                                            display: inline;
                                            font-size: 16px;
                                            max-width: 47px;
                                            .remove-ellipsis;
                                        }

                                        select {
                                            flex-shrink: 0;
                                            width: 64px;
                                            height: 40px;
                                            margin-left: 12px;
                                            padding-right: 25px;
                                            &.mod--invalidate {
                                                border: 1px solid @red-error;
                                            }
                                        }
                                    }

                                    &-text {
                                        font-size: 14px;
                                        color: @grey-70;
                                    }
                                }
                            }

                            span.mod--invalidate {
                                color: @red-error;
                                transition: .5s linear;
                                text-decoration: none;
                                margin: 6px 0;
                            }
                        }
                    }

                    &-toggle {
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 500;
                        justify-content: space-between;

                        .svg-icon.mod--icon-right {
                            float: right;
                        }
                    }
                }
            }
        }

        &.c-popover__content {
            .c-popover__content-block {
                .ribbon {
                    margin-left: -15px;
                    margin-top: -15px;
                    margin-right: -15px;
                    .c-ribbon {
                        box-shadow: none;
                        display: flex;
                        &.mod--show.hidden{
                            display: none!important;
                        }
                        &__close-btn {
                            height: 14px;
                            width: 14px;
                        }
                        &__light {
                            padding: 8px 28px 8px 10px;
                        }
                        
                        &__icon {
                            padding-right: 6px;
                            border-right: 1px solid @white;
                            height: 38px;
                            .svg-icon {
                                padding: 5px 1px;
                            }
                        }
                        p {
                            font-size: 12px;
                            line-height: 1.3;
                            padding: 0 10px;
                        }
                        
                        &.c-ribbon__not-available {
                            flex-direction: column;
                            .c-ribbon__light-text2 {
                                padding-top: 0;
                                p {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
                .c-ribbon {
                    &.c-ribbon__message-children-age {
                        display: block;
                        position: relative;
                        box-shadow: none;
                        font-size: 14px;
                        line-height: 20px;
                        background: fade(@b-light-blue, 50%);
                    }
                }
            }
        }
    }

    &-rooms.hiddenChildren {
        .children-JS {
            display: none;
        }
    }
    
    &-rooms.hiddenTeenagers {
        .teenager-JS {
            display: none;
        }
    }

    &-discount {
        width: 100vw;

        .c-fastbooking__submenu-header {
            font-size: 16px;
            line-height: 24px;
        }

        .c-fieldset {
            margin: 0;
        }

        .c-fieldset__row-wrap {
            padding: 0;
        }

        .c-fieldset__row {
            padding: 5px 0;
        }

        .form-submenu-promocode {
            #form-promocode {
                margin-right: 10px;
            }
        }

        .form-submenu-discount {
            label {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin: 0px;
                padding: 0px;
            }

            input {
                margin: 0px 10px 0px 0px;
            }
        }

        &-close {
            width: 42px;
            height: 42px;
            position: absolute;
            right: -0px;
            top: -3px;
            display: flex;
            align-items: center;
            justify-content: center;

            .cmp-icon {
                width: 16px;
                height: 16px;
            }
        }
    }
}

.inject-fastbooking-true {

    .c-fastbooking__submenu-search,
    .c-fastbooking__submenu-destination {
        max-width: 1110px;
    }
}

.c-fastbooking {
    &.mod--active {
        .c-fastbooking__form {
            .c-popover {
                bottom: 0;
                // height: 100vh;
                height: auto;
            }
        }
        .c-fastbooking__submenu {
            &-destination,
            &-search {
                .c-popover__content-block {
                    padding: 0 15px;
                    &__result-hotels {
                        height: 100%;
                    }
                    &__suggest {
                        max-width: 28%;
                    }
                }
            }
        }
    }
    .c-fastbooking__submenu {
        &-destination,
        &-search {
            .c-popover__content-block {
                &__result-hotels {
                    height: 100vh;
                }
                &__suggest {
                    max-width: 28%;
                }
            }
        }
    }
}
/* C-FASTBOOKING__SUBMENU - DESKTOP  */
@media (min-width: @desktop) {
    .c-fastbooking__submenu {

        &-anywhere {
            margin-top: 0px;

            &-claim {
                font-size: 16px;
            }
        }

        &-header {
            margin: 5px 0 10px 10px;
            display: flex;
        }
        &-search,
        &-destination {
            width: 976px;
            flex-wrap: wrap;

            .c-popover__content-block {
                flex: 33%;
                padding: 15px;
                /* width */
                ::-webkit-scrollbar {
                    width: 7px;
                }
                /* Track */
                ::-webkit-scrollbar-track {
                    background: #F5F5F5;
                    border-radius: 10px;
                }
                /* Handle */
                ::-webkit-scrollbar-thumb {
                    background: #D8D8D8;
                    border-radius: 10px;
                }
                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                    background: #D8D8D8;
                }

                &--filters {
                    display: flex;
                    flex-basis: 100%;
                    border-top: 2px solid @grey-40;
                    padding: 0 15px;
                }
                .accordion {
                    &-toggle {
                        padding: 8px 0;
                        padding: 6px 0;
                    }
                    &-content {
                        h3.history__hotel-destination {
                            padding-left: 36px;
                        }
                    }
                }
            }

            .c-fastbooking__submenu-search-destination-filtered {
                border-radius: 9px;
                background-color: rgba(56,64,68,0.7);
                color: @white;
                padding: 0px 8px;
                font-size: 12px;
                display: inline-block;
                line-height: 1.8;
                margin-left: 6%;
                height: 20px;
                width: auto;
                transform: translateY(-1px);
                .filter-icon {
                    display: inline-block;
                    margin-right: 4px;
                    span {
                        display: block;
                        width: 14px;
                        height: 1px;
                        margin-bottom: 2px;
                        position: relative;
                        background: #cdcdcd;
                        border-radius: 3px;
                        &:nth-child(2) {
                            width: 10px;
                            margin: 0 auto 2px auto;
                        }
                        &:nth-child(3) {
                            width: 6px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        &-search {
            padding: 0px;
            overflow: initial;
            &-hotels,
            &-destination,
            &-tematics,
            &-experiences {
                height: 252px;
                max-height: none;
            }
            .c-popover__content-block {
                &:first-of-type{
                    .c-fastbooking__submenu-header {
                        margin: 5px 0 18px 10px;
                    }
                    .c-fastbooking__submenu-search-hotels {
                        &>ul>.accordion-item {
                            padding-left: 0px;
                        }
                    }
                }
            }
            .link {
                display: none;
            }
            &-hotels,
            &-destination {
                ul li {
                    padding-left: 0;
                    &:before {
                        content: none;
                    }
                }
            }
            &-hotels {
                .mod--background-light-blue {
                    background-color: @grey-20;
                }
                .accordion {
                    &-item,
                    &-item.active {
                        height: auto;
                        overflow: initial;
                        &>.accordion-toggle {
                            padding: 4px 0;
                            .accordion-label {
                                line-height: 1.3;
                                padding-left: .5rem;

                            }
                            .svg-icon {
                                &.accordion-arrow {
                                    display: flex;
                                }
                            }

                        }
                        &>.accordion-content {
                            margin-bottom: 0px;
                            &>li {
                                .accordion-toggle {
                                    .accordion-label {
                                        padding-left: 26px;
                                    }
                                }
                            }
                        }
                    }
                }

                &>ul {
                    &>.accordion-item {
                        ul li {
                            .accordion-toggle {
                                .accordion-label {
                                    padding-left: 26px;
                                }
                                &>.accordion-arrow {
                                    margin-right: 12px;
                                }
                            }
                            ul li {
                                padding-left: 42px;
                            }
                        }
                        &>.accordion-content {
                            margin-bottom: 0;
                            // comunidad
                            &>.accordion-item {
                                &::before {
                                    content: "";
                                }
                                .accordion-toggle {
                                    .accordion-label {
                                        line-height: 1.3;
                                    }
                                    &:before {
                                        content: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-destination {
                height: 252px;
                overflow-y: auto;
                ul li {
                    padding-left: .5rem;
                }
            }
        }

        &-destination {

            &--filter {
                display: flex;
                margin: 15px 0;

                &-btn-title {
                    font-size: 14px;
                }

                &-ctas {
                    margin: 15px 30px 15px 20px;
                    align-content: baseline;
                    display: flex;
                    flex: 1;
                    justify-content: space-between;
                    .c-cta {
                        font-size: 14px;
                        text-transform: none;
                        text-decoration: none;
                        line-height: 22px;
                        color: var(--b-blue);
                        font-weight: 500;
                        background: @grey-20;
                        padding: 5px 12px;
                        border-radius: 20px;
                        border: 2px solid @grey-20;
                        &:hover,
                        &.mod--active {
                            background-color: @b-light-blue;
                            border-color: var(--b-blue);
                            color: var(--b-blue);
                            font-weight: 700;
                        }
                    }
                }
            }
            &-top {
                .mod--accordion-disabled-dsktp {
                    &> .accordion-content {
                        max-height: none;
                    }
                }
            }
            &-history {
                padding-bottom: initial;
                .link {
                    width: 100%;
                }
                &>.accordion {
                    &-item {
                        &.mod--accordion-disabled-dsktp {
                            &>.accordion-toggle {
                                &>.accordion-arrow {
                                    display: none;
                                }
                            }

                            &>.accordion-content {
                                height: 252px;
                                max-height: none;
                                &.last-search {
                                    overflow-y: auto;
                                    height: 252px;
                                    margin-left: 8px;
                                }

                                &>.accordion-item {
                                    padding: 0;
                                    &>.accordion-toggle {
                                        &.mod--hover-grey {
                                            &:hover {
                                                z-index: 0;
                                            }
                                        }
                                        &>.accordion-arrow {
                                            position: relative;
                                            &:hover:before,
                                            &:before {
                                                content: "";
                                                background: @white;
                                                border-radius: 100%;
                                                width: 22px;
                                                height: 22px;
                                                position: absolute;
                                                z-index: -1;
                                                right: -3px;
                                                top: -2px;
                                            }
                                        }
                                    }

                                    &>.accordion-content {
                                        &>.accordion-item {
                                            &>.accordion-toggle {
                                                padding: 3.5px 0;
                                            }
                                        }
                                    }
                                }
                                &>* {
                                    opacity: 1;
                                }
                                // &.last-search {
                                //     li {
                                //         margin-left: 5px;
                                //     }
                                // }
                            }
                            &.active {
                                &>.accordion-content {
                                    margin-bottom: 0px;
                                }
                                .show-destination__cta {
                                    padding-left: 15px;
                                }
                            }
                        }
                    }
                    &-arrow {
                        background: transparent;
                    }
                }
                .mod--accordion-disabled-mobile {
                    .accordion {
                        &-item,
                        &-item.active {
                            height: auto;
                            overflow: initial;
                            &>.accordion-toggle {
                                .svg-icon {
                                    &.accordion-arrow {
                                        display: flex;
                                        .cmp-icon {
                                            width: 16px;
                                            height: 16px;
                                            padding-top: 3px;
                                        }
                                    }
                                }
                            }
                        }

                    }
                    &>.accordion {
                        &-item,
                        &-item.active {
                            &>.accordion-toggle {
                                .accordion-label {
                                    line-height: 1.3;
                                    padding-left: .5rem;
                                }
                            }
                        }
                        &-item.active {
                            &>.accordion-content {
                                margin-bottom: 7px;
                                &>.accordion-item.active {
                                    &>.accordion-content {
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                        }
                    }

                    &>li {
                        ul li {
                            .accordion-toggle {
                                .accordion-label {
                                    padding-left: 26px;
                                }
                                &>.accordion-arrow {
                                    margin-right: 12px;
                                }
                            }
                            ul li {
                                padding-left: 42px;
                                line-height: 2;
                                font-size: 16px;
                                margin-bottom: 0;
                                font-weight: 500;
                            }
                        }
                    }

                }
            }
            &-hotels {
                ul li {
                    padding-left: .5rem;
                }
                .mod--accordion-disabled-dsktp {
                    &.active {
                        &>.accordion-content {
                            height: 252px;
                            max-height: none;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &-suggest {
                .suggest__hotels {
                    font-size: 16px;
                }
            }

            &.mod--input-search {
                .c-fastbooking__submenu-anywhere {
                    display: flex;
                }
                .mod--accordion-disabled-dsktp {
                    &>.accordion-toggle {
                        display: flex;
                    }
                }
            }

        }

        &-calendar {
            max-width: 970px;

            &-header {
                display: flex;
                border-bottom: 1px solid @grey-40;

                &-select {
                    width: 50%;
                    padding: 0px;
                    border-right: 1px solid @grey-40;
                    border-bottom: none;

                    &-title {
                        font-size: 18px;

                        &-info,
                        &-article {
                            font-weight: 500;
                        }

                        &-info {
                            font-size: 14px;
                        }
                    }

                    &-number {
                        margin-top: 10px;

                        .text-help {
                            margin-top: 24px;
                            border-top: 0;
                            padding-top: 0;
                        }
                    }

                    &-selected {
                        padding: 15px;
                    }
                }

                &-legend {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-flow: column;

                    &-labels {
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: 500;
                        padding: 0px;
                        margin-top: 10px;
                    }

                    &-item {
                        margin-top: 4px;
                        font-size: 14px;
                        padding: 10px;

                        &-figure {
                            width: 11px;
                            height: 11px;
                            border-radius: 1px;
                            border: 1px solid @grey-48; // #979797
                        }
                    }
                }
            }

            .c-fastbooking__calendar-message-spinner {
                top: 60%;
            }

            &.mod--enabledrounded {
                padding: 32px;
                .c-fastbooking__submenu-calendar-header {
                    border-bottom: 1px solid @grey-40;
                    margin-bottom: 24px;
                    &-select {
                        border-right: initial;
                        &-title {
                            font-size: initial;
    
                            &-info,
                            &-article {
                                font-weight: initial;
                            }
                        }
                        &-selected {
                            padding: 0 0 24px 0;
                        }
                    }
                    &-legend {
                        border-radius: 8px;
                        width: 100%;
                        .c-adv {
                            box-shadow: none;
                            border-radius: 8px;
                            margin-top: 0;
                        }
                    }
                }
                .c-hotel-related {
                    min-width: initial;
                    &__content {
                        padding: 0;
                    }
                }
            }
        }

        &-rooms {
            width: 100vw;
            max-width: 360px;

            &-room {
                &-body {
                    .children-age {
                        &-column {
                            &-item {
                                select {
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }
            }

            &.c-popover__content {
                .c-popover__content-block {
                    .ribbon {
                        .c-ribbon {
                            p {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

        }

        &-discount {
            width: 100vw;
            width: 360px;

            .c-popover__content-block {
                padding: 0px 0px 20px;

                .c-fastbooking__submenu-header,
                .form-submenu-promocode,
                .form-submenu-discount,
                .c-rich-text {
                    padding: 5px 20px 0 20px;
                }
            }
        }
    }

    // NEW FB
    .c-fastbooking {
        &.mod--active {
            .c-fastbooking__submenu {
                &-destination,
                &-search {
                    .c-popover__content-block {
                        padding: 15px;
                        &__result-hotels {
                            height: auto;
                        }
                    }
                }
            }
        }
        &__form {
            .c-popover {
                top: ~"calc(100% + 15px)";
                left: 80px;
            }
        }
        .c-fastbooking__submenu {
            &-destination,
            &-search {
                .c-popover__content-block {
                    padding: 15px;
                    &__result-hotels {
                        height: auto;
                    }
                }
            }
        }
    }
}

.c-fastbooking__submenu-search-destination, .c-fastbooking__submenu-destination-hotels {
    & .resort-child{
        padding-left: 42px;
        line-height: 20px;
        font-size: 16px;
        margin-bottom: 0;
    }
    & .resort-parent{
        line-height: 20px;
        padding-left: 26px;
        position: relative;
        &::after { 
            content: none; 
        } 
        &:before { 
            content: ""; 
            width: 0; 
            height: 0; 
            border-style: solid; 
            border-width: 5px 0 5px 8.7px; 
            border-color: transparent transparent transparent var(--b-blue); 
            background-image: none; 
            padding: 0; 
            z-index: 1; 
            top: 8px; 
            left: 10px; 
        }
    }
}
/* C-FASTBOOKING__SUBMENU -  DESKTOP CONTENT - SIZE */
@media (min-width: @desktop) and (max-width: @content-sizing) {
    .c-fastbooking__submenu {
        &-calendar {
            .c-fastbooking__calendar-wrapper {
                .datepicker {
                    .datepicker__months {
                        .datepicker__month {
                            thead {
                                tr.datepicker__week-days {
                                    th.datepicker__week-name {
                                        width: 14.28571429%;
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    td {
                                        width: 14.28571429%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
// FB GUEST MAX NUMBER TOOLTIP
.c-popover {
    &.mod--tooltip {
        &.tooltip-adults,
        &.tooltip-children {
            .c-popover__content {
                padding: 0;
                .c_popover__messages {
                    padding: 3px 10px 8px;
                    &-subtitle {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}
