@import (once) "../../../../../../../../../../resorts/ui.frontend/src/main/webpack/clientlibs/base/less/inc/utils/colors.less";


// CTA - MIXINS
.mixin-cta-color(@color, @rate) {
    color: @color;
    &:hover {
        color: lighten(@color, @rate);
        .c-icon,
        .cmp-icon,
        .svg-icon,
        svg {
            color: lighten(@color, @rate);
        }
        svg path {
            fill: lighten(@color, @rate);
            stroke: lighten(@color, @rate);
        }
        &:before,
        &:after {
            color: lighten(@color, @rate);
        }
    }
}

.mixin-cta-bg-color(@bg-color, @hover-bg-color, @text, @hover-text) {
    background: -webkit-gradient(linear, left top, right top, color-stop(49.95%, @hover-bg-color), color-stop(49.96%, @bg-color));
    background: linear-gradient(to right, @hover-bg-color 49.95%, @bg-color 49.96%);
    color: @text;
    background-size: 201% 201%;
    background-position: 100%;
    &:hover {
        color: @hover-text;
        background-position: 0%;
    }
}

.mixin-cta-border-color(@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color) {
    border: solid 2px @text;
    color: @text;
    background: -webkit-gradient(linear, left top, right top, color-stop(49.95%, @hover-bg-color), color-stop(49.96%, @bg-color));
    background: linear-gradient(to right, @hover-bg-color 49.95%, @bg-color 49.96%);
    background-size: 201% 201%;
    background-position: 100%;
    &:hover {
        color: @hover-text;
        border: solid 2px @hover-bg-color;
        background-position: 0%;
        box-shadow: inset 0 -1px @hover-bg-color;
    }
    &:active {
        color: @active-bg-color;
    }
}

/* CTA - MOBILE FIRST (~ TABLET) */
.c-cta {
    display: inline-block;
    position: relative;
    // .mixin-cta-color(@b-blue, 20%);
    color: @text-color;
    font-size: 16px;
    font-weight: 700;
    .common-transition;
    max-width: 100%;
    text-decoration: underline 1px;
    text-decoration-color: inherit;
    background-color: transparent;
    cursor: pointer;
    &:hover {
        color: @text-color;
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        color: @b-blue-gradient-focused;
        outline: none;
    }
    &:active {
        text-decoration-color:transparent;
    }
    &:hover,
    &:active,
    &:after,
    &:before,
    &:focus-visible {
        .common-transition;
    }
    &:after,
    &:before {
        text-decoration: none;
    }
}

/* CTA - MODIFIERS */
.mod--cta {
    color: @text-color;
    &:hover,
    &:active {
        color: @text-color;
    }

    // WITH ARROW
    &-with-arrow {
        &-right {
            .c-cta,
            &.c-cta {
                &:after {
                    display: inline-block;
                    font-family: barcelo-ui-kit;
                    content: "\e902";
                    font-size: 0.9em;
                    transform: translateY(0.1em);
                    line-height: 0px;
                }
                &:hover,
                &:active {
                    &:after {
                        transform: translateY(0.1em) translateX(4px);
                    }
                }
            }
        }
        &-left {
            .c-cta,
            &.c-cta {
                &:before {
                    display: inline-block;
                    font-family: barcelo-ui-kit;
                    content: "\e901";
                    font-size: 0.9em;
                    transform: translateY(0.1em);
                    line-height: 0px;
                }
                &:hover,
                &:active {
                    &:before {
                        transform: translateY(0.1em) translateX(-4px);
                    }
                }
            }
        }
        &-down {
            .c-cta,
            &.c-cta {
                &:after {
                    display: inline-block;
                    font-family: barcelo-ui-kit;
                    content: "\e902";
                    font-size: 0.9em;
                    line-height: 0px;
                    transform: translateY(0.1em) translateX(0.5rem) rotate(90deg);
                }
                &:hover,
                &:active {
                    &:after {
                        transform: translateY(5px) translateX(0.5rem) rotate(90deg);
                    }
                }
            }
        }
        &-down-block {
            .mod--cta-with-arrow-right .c-cta,
            .c-cta,
            &.c-cta {
                display: block;
                text-align: center;
                &:after {
                    display: inline-block;
                    font-family: barcelo-ui-kit;
                    content: "\e902";
                    font-size: 0.9em;
                    width: 100%;
                    transform: rotate(90deg);
                }
                &:hover,
                &:active {
                    &:after {
                        transform: rotate(90deg) translateX(4px);
                    }
                }
            }
        }
        &-inline {
            text-decoration: none;
            .c-cta {
                display: inline;
                &:after {
                    font-family: "barcelo-ui-kit";
                    content: "\e902";
                    position: absolute;
                    font-size: 1em;
                    margin-top: 2px;
                    font-weight: 500;
                    .common-transition;
                }
            }

            &:hover {
                .c-cta {
                    &:after {
                        transform: translateX(4px);
                    }
                }
            }
        }
        &-only-desktop {
            .c-cta,
            &.c-cta {
                &:after,
                &:before {
                    content: none;
                }
            }
        }
    }

    //WITH ICON
    &-with-icon {
        &-right,
        &-left {
            .c-cta,
            &.c-cta {
                display: inline;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                font-family: inherit;
                &:before {
                    font-family: "barcelo-ui-kit";
                    display: inline-block;
                    text-decoration: none;
                }
            }
        }

        &-right {
            .c-cta,
            &.c-cta {
                float: left;
                &:before {
                    float: right;
                    margin-left: 0.25em;
                }
            }
        }

        &-left {
            .c-cta,
            &.c-cta {
                float: right;
                &:before {
                    float: left;
                    margin-right: 0.25em;
                }
            }
        }
    }

    //resorts
    &-with-arrow-right-maximize {
        .c-cta,
        &.c-cta {
            color: @br-black-100;
            &::after {
                content: "";
                display: inline-block;
                transform: translateY(0.2em) translateX(1.6em);
                text-indent: -9999px;
                width: 20px;
                height: 20px;
                background-image: url("/etc.clientlibs/resorts/clientlibs/clientlib-base/resources/images/barcelo/commons/icons/ui/maximize.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 20px 20px;
                filter: brightness(0) invert(1);
            }
            &:hover,
            &:active {
                &::after {
                    transform: translateY(0.2em) translateX(1.6em);
                }
            }
        }
        &.mod--cta-fullbg {
            .c-cta,
            &.c-cta {
                &::after {
                    transform: translateY(0.2em) translateX(1.6em);
                }
                &:hover,
                &:active {
                    &::after {
                        transform: translateY(0.2em) translateX(1.6em);
                    }
                }
            }
        }
    }

    // FULL BG
    &-fullbg {
        .c-cta,
        &.c-cta {
            font-size: 16px;
            height: 48px;
            line-height: 47px;
            text-align: center;
            padding: 0px 15px;
            background: var(--b-blue);
            border-radius: 25px;
            -webkit-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3);
            text-decoration: none;
            color: white;
            .ellipsis;
            display: inline-block;
            &:hover {
                &:before,
                &:after {
                    color: white;
                }
            }
        }
    }

    // OUT LINE
    &-outline {
        .c-cta,
        &.c-cta {
            font-size: 16px;
            height: 48px;
            line-height: 47px;
            text-align: center;
            padding: 0px 25px;
            border-radius: 25px;
            text-decoration: none;
            .mixin-cta-border-color(@grey-90, @transparent, @white, @grey-90, @white);
            //@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color
            .ellipsis;
            max-width: 100%;
            display: inline-block;
        }
        &-with-bg-white {
            .c-cta,
            &.c-cta {
                .mixin-cta-border-color(@grey-90, @white, @white, @grey-90, @b-dark-blue);
                //@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color
            }
        }
    }

    &-outline-with-bg {
        .c-cta,
        &.c-cta {
            background-color: white;
        }
    }

    // COLORS
    &-color-white {
        .c-cta,
        &.c-cta {
            .mixin-cta-color(@white, 20%);
        }
    }
    &-color-black-opc {
        .c-cta,
        &.c-cta {
            .mixin-cta-color(@black, 20%);
        }
    }
    &-color-grey-90 {
        .c-cta,
        &.c-cta {
            .mixin-cta-color(@grey-90, 20%);
        }
    }
    &-color-red {
        .c-cta,
        &.c-cta {
            .mixin-cta-color(@b-red, 20%);
        }
    }
    &-color-dark-yellow {
        .c-cta,
        &.c-cta {
            .mixin-cta-color(@b-dark-yellow, 20%);
        }
    }
    &-color-green {
        .c-cta,
        &.c-cta {
            .mixin-cta-color(@b-green, 20%);
        }
    }
    &-primary,
    &-color-dark-blue,
    &-color-dark-blue.mod--cta-fullbg {
        .c-cta,
        &.c-cta {
            background: var(--b-dark-blue-gradient);
            color: @white;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity 0.3s;
                z-index: -1;
                background: var(--b-dark-blue-gradient-hover);
            }
            &:hover::before {
                opacity: 1;
            }
            &:active,
            &:active::before {
                background: var(--b-dark-blue-gradient-active);
            }
        }
    }
    &-color-dark-blue.mod--cta-fullbg {
        .c-cta,
        &.c-cta {
            line-height: 46px;
        }
    }

    &-primary,
    &-color-dark-blue,
    &-secondary,
    &-secondary-inverse,
    &-secondary-on-image {
        .c-cta,
        &.c-cta {
            font-size: 16px;
            font-weight: 600;
            line-height: 29px;
            height: 48px;
            padding: 8px 32px;
            border-radius: 25px;
            box-shadow: none;
            text-align: center;
            text-decoration: none;
            text-shadow: none;
            display: inline-block;
            z-index: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .common-transition;
            &:focus-visible {
                box-shadow: @b-blue-gradient-focused;
                outline: none;
            }
        }
        &.mod--disabled,
        &.mod--cta-disabled {
            .c-cta,
            &.c-cta,
            span {
                background: @grey-25; //#EAEEF1
                color: @grey-44; //#AFB3B4
            }
        }
    }
    &-primary { //case banner myb - we move the c-cta as class from the a tag - we change the next span for div
        &.c-cta {
            display: inline-table;
            div::first-letter {
                text-transform: capitalize;
            }
        }
    }

    &-secondary,
    &-secondary-inverse,
    &-secondary-on-image {
        .c-cta,
        &.c-cta {
            border: 2px solid transparent;
        }
    }
    &-secondary {
        .c-cta,
        &.c-cta {
            background: @transparent;
            border-color: @b-dark-blue;
            color: @b-dark-blue;
            &:hover {
                background: @grey-25;
                border-color: @grey-25;
                color: @b-dark-blue;
                box-shadow: none;
            }
            &:active {
                background: @b-dark-blue;
                border-color: @b-dark-blue;
                color: @white;
            }
        }
    }
    &-secondary-inverse {
        .c-cta,
        &.c-cta {
            background: @transparent;
            border-color: @white;
            color: @white;
            &:hover {
                background: @grey-25;
                border-color: @grey-25;
                color: @b-dark-blue;
            }
            &:active {
                background: @white;
                border-color: @white;
                color: @b-dark-blue;
            }
        }
    }
    &-secondary-on-image:not(.c-fastbooking) {
        .c-cta,
        &.c-cta {
            background: @white;
            border-color: @white;
            color: @b-dark-blue;
            font-weight: 600;
            &:hover {
                background: @grey-25;
                border-color: @grey-25;
            }
            &:active {
                background: @b-dark-blue;
                border-color: @b-dark-blue;
                color: @white;
            }
        }
    }

    //COLOR FULLBG
    &-fullbg {
        &.mod--cta-color-white {
            .c-cta,
            &.c-cta {
                .mixin-cta-bg-color(@white, #384044, @grey-90, @white);
            }
        }
        &.mod--cta-color-black {
            .c-cta,
            &.c-cta {
                .mixin-cta-bg-color(@grey-90, @white, @white, #384044);
            }
        }
        &.mod--cta-color-blue { 
            .c-cta,
            &.c-cta {
                .mixin-cta-bg-color(@b-blue, #12456a, @white, @white);
            }
        }
        &.mod--cta-color-red {
            .c-cta,
            &.c-cta {
                .mixin-cta-bg-color(@b-red, #be3300, @white, @white);
            }
        }
        &.mod--cta-color-warning-red {
            .c-cta,
            &.c-cta {
                .mixin-cta-bg-color(#E40F13, #E40F13, @white, @white);
            }
        }
        &.mod--cta-color-dark-yellow {
            .c-cta,
            &.c-cta {
                .mixin-cta-bg-color(@b-dark-yellow, #f4e790, @grey-90, @grey-90);
            }
        }
        &.mod--cta-color-green {
            .c-cta,
            &.c-cta {
                .mixin-cta-bg-color(@b-green, @b-green, @white, @white);
            }
        }
        &.mod--cta-color-orange {
            .c-cta,
            &.c-cta {
                .mixin-cta-bg-color(@br-orange-100, @br-orange-140, @white, @white);
            }
        }

        &.mod--cta-color-dark-blue {
            .c-cta,
            &.c-cta {
                background: var(--b-dark-blue-gradient);
                color: @white;
                box-shadow:none;
                line-height: 46px;
                .common-transition;
                display: inline-block;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 25px;
                    opacity: 0;
                    transition: opacity 0.3s;
                    z-index: -1;
                    background: var(--b-dark-blue-gradient-hover);
                }
                &:hover::before {
                    opacity: 1;
                }
                &:active {
                    background: var(--b-dark-blue-gradient-active);
                }
            }
        }
    }

    //COLOR OUTLINE
    &-outline {
        &.mod--cta-color-white {
            .c-cta,
            &.c-cta {
                .mixin-cta-border-color(@white, @transparent, @grey-90, @white, @white);
                //@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color
            }
        }
        &.mod--cta-color-grey-90 {
            .c-cta,
            &.c-cta {
                .mixin-cta-border-color(@grey-90, @transparent, @white, @grey-90, @grey-90);
                //@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color
            }
        }
        &.mod--cta-color-blue {
            .c-cta,
            &.c-cta {
                .mixin-cta-border-color(@b-blue, @transparent, @white, @b-blue, @b-blue);
                //@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color
            }
        }
        &.mod--cta-color-ocean {
            .c-cta,
            &.c-cta {
                .mixin-cta-border-color(@ocean-70, @transparent, @white, @ocean-70, @ocean-70);
                //@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color
            }
        }
        &.mod--cta-color-red {
            .c-cta,
            &.c-cta {
                .mixin-cta-border-color(@b-red, @transparent, @white, @b-red, @b-red);
                //@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color
            }
        }

        &.mod--cta-color-warning-red {
            .c-cta,
            &.c-cta {
                .mixin-cta-border-color(#E40F13, @transparent, @white, #E40F13, #E40F13);
                //@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color
            }
        }
        &.mod--cta-color-dark-yellow {
            .c-cta,
            &.c-cta {
                .mixin-cta-border-color(@b-dark-yellow, @transparent, @white, @b-dark-yellow, @b-dark-yellow);
                //@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color
            }
        }
        &.mod--cta-color-green {
            .c-cta,
            &.c-cta {
                .mixin-cta-border-color(@b-green, @transparent, @white, @b-green, @b-green);
                //@text, @bg-color, @hover-text, @hover-bg-color, @active-bg-color
            }
        }
        &.mod--cta-color-dark-blue {
            .c-cta,
            &.c-cta {
                background: @transparent;
                border: 2px solid @b-dark-blue;
                color: @b-dark-blue;
                padding: 8px 32px;
                line-height: 32px;
                display: inline-block;
                z-index: 1;
                .common-transition;
                &::before {
                    content: none;
                }
                &:hover {
                    background: @grey-25;
                    border: 2px solid @grey-25;
                    box-shadow: none;
                }
                &:active {
                    background: @b-dark-blue;
                    border: 2px solid @b-dark-blue;
                    color: @white;
                }
            }
        }
    }
    //BREAKLINE
    &-breakline-none {
        .c-container--breakline {
            &:before,
            &:after {
                visibility: hidden;
            }
        }
    }
    //ROUND BUTTON
    &-round-btn {
        .c-cta,
        &.c-cta {
            width: 100%;
            height: 100%;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-line-pack: center;
            align-content: center;
            border-radius: 99999px;
            padding: 0;
            -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
        }
    }
    // SIZES
    &-sm {
        .c-cta,
        &.c-cta {
            font-size: 14px;
        }
    }
    &-md {
        .c-cta,
        &.c-cta {
            font-size: 16px;
        }
    }
    &-lg {
        .c-cta,
        &.c-cta {
            font-size: 20px;
        }
    }
    &-full-width {
        width: 100%;
        text-align: right;
        .c-cta,
        &.c-cta {
            width: 100%;
            padding: 0px;
        }
        &.mod--cta-primary,
        &.mod--cta-secondary,
        &.mod--cta-secondary-inverse,
        &.mod--cta-secondary-on-image,
        .mod--cta-color-dark-blue {
            .c-cta,
            &.c-cta {
                padding: 8px 32px;
                line-height: 32px;
                display: inline-block;
                &::before {
                    border-radius: 25px;
                }
            }
        }
    }
    &-height-22 {
        .c-cta,
        &.c-cta {
            height: 22px;
            line-height: 22px;
        }
        &.mod--cta-outline {
            .c-cta {
                line-height: 20px;
            }
        }
    }
    &-height-32 {
        .c-cta,
        &.c-cta {
            height: 32px;
            line-height: 32px;
        }
        &.mod--cta-outline {
            .c-cta {
                line-height: 32px;
            }
        }
    }
    &-height-48 {
        .c-cta,
        &.c-cta {
            height: 48px;
            line-height: 48px;
        }
        &.mod--cta-outline {
            .c-cta,
            &.c-cta {
                line-height: 46px;
            }
        }
    }
    &-multiline {
        .c-cta,
        &.c-cta {
            height: auto;
            white-space: normal;
            text-align: center;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }
    &-min-width-200 {
        .c-cta,
        &.c-cta {
            min-width: 200px;
        }
    }
    &-min-width-234 {
        .c-cta,
        &.c-cta {
            min-width: 234px;
        }
    }
    &-min-width-268 {
        .c-cta,
        &.c-cta {
            min-width: 268px;
        }
    }

    //NOT UNDERLINED
    &-not-underlined .c-cta,
    &-not-underlined.c-cta {
        text-decoration: none;
    }

    //myBarcelo
    &-add-phone-icon {
        .c-cta {
            display: inline-flex !important;
            align-items: center;
            justify-content: center;
            &::before {
                content: "";
                margin-right: 8px;
                width: 19px;
                height: 19px;
                display: block;
                background-image: url("/etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/phone-icon.svg");
                background-size: contain;
                
                &:hover {
                    &::before {
                        filter: brightness(5);
                    }
                }
            }
        }
    }

    &-no-padding {
        .c-cta {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

// DISABLED
.mod--cta,
.mod- {
    &-disabled,
    &[data-disabled="disabled"],
    &:disabled {
        &.mod--cta-fullbg {
            .c-cta,
            &.c-cta {
                opacity: 0.6;
                background: @grey-90;
                border-color: @grey-90;
                color: white;
                -webkit-box-shadow: none;
                box-shadow: none;
                cursor: not-allowed;
            }
        }
        &.mod--cta-outline,
        &.mod--cta-primary,
        &.mod--cta-secondary {
            &.c-cta,
            .c-cta,
            span {
                opacity: 0.6;
                border-color: @grey-25;
                background: @grey-25; //#EAEEF1
                color: @grey-44; //#AFB3B4
                cursor: not-allowed;
                &::before {
                    transition: none;
                }
                &:hover {
                    &::before {
                        background: @grey-25; //#EAEEF1
                    }
                }
            }
        }
        &.mod--cta-color-ocean {
            &.c-cta,
            .c-cta,
            span {
                opacity: 0.6;
                border-color: @ocean-70;
                background: @ocean-70; //#EAEEF1
                color: @b-ocean15; //#AFB3B4
                cursor: not-allowed;
                &::before {
                    transition: none;
                }
                &:hover {
                    &::before {
                        background: @ocean-70; //#EAEEF1
                    }
                }
            }
        }
        &.mod--cta-secondary-inverse {
            .c-cta,
            &.c-cta {
                color: @b-light-dusty-blue;
                background: @b-dark-blue;
            }
        }
        &.mod--cta-secondary-on-image {
            .c-cta,
            &.c-cta {
                background: @white;
                border-color: @white;
                color: @grey-90;
            }
        }
        .c-cta,
        &.c-cta {
            opacity: 0.6;
            color: @grey-90;
            cursor: not-allowed;
        }
        &.pointer-none{
            pointer-events: none;
        }
    }
}

/* CTA - RESORTS  */
.cmp-br-carousel {
    .mod--cta {
        &-with-arrow {
            &-right {
                .c-cta,
                &.c-cta {
                    color: @br-black-100;
                    &::after {
                        display: inline-block;
                        content: "";
                        transform: translateY(0.2em);
                        text-indent: -9999px;
                        width: 18px;
                        height: 18px;
                        background-image: url("/etc.clientlibs/resorts/clientlibs/clientlib-base/resources/images/barcelo/commons/icons/ui/arrow-right_bold-black.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 18px 18px;
                    }
                    &:hover,
                    &:active {
                        &::after {
                            transform: translateY(0.2em) translateX(4px);
                        }
                    }
                }
                &.mod--cta-fullbg {
                    .c-cta,
                    &.c-cta {
                        &::after {
                            transform: translateY(0.2em) translateX(0.6em);
                        }
                        &:hover,
                        &:active {
                            &:after {
                                transform: translateY(0.2em) translateX(12px);
                            }
                        }
                    }
                }
            }
        }
    }
}


/* CTA - DESKTOP  */
@media (min-width: @desktop) {
    .c-cta {
        font-size: 18px;
        font-weight: 500;
    }
    /* CTA - MODIFIERS - DESKTOP  */
    .mod--cta {
        &-fullbg,
        &-outline {
            .c-cta,
            &.c-cta {
                font-size: 16px;
                padding: 0px 30px;
            }
        }
        &-sm {
            .c-cta {
                font-size: 14px;
            }
        }
        &-lg {
            .c-cta {
                font-size: 20px;
            }
        }
    }
    .mod--cta-with-arrow-right.mod--cta-with-arrow-only-desktop {
        .c-cta,
        &.c-cta {
            &:after {
                content: "\e902";
            }
        }
    }
    .mod--cta-with-arrow-left.mod--cta-with-arrow-only-desktop {
        .c-cta,
        &.c-cta {
            &:before {
                content: "\e901";
            }
        }
    }
    .mod--cta-margin-12px {
        .c-cta {
            margin: 0 12px;
        }
    }
}
