.mod--card-hotel-home-target {
    &.c-card-hotel {
        .c-card-hotel__content {
            &-header {
                &-title {
                    .c-cta {
                        color: @grey-90;
                    }
                }
                .mod--underline-small {
                    line-height: 24px;
                    letter-spacing: 0.005em;
                    margin-bottom: 0;
                    flex: none;
                    max-height: 22px;
                    font-weight: 400;
                    word-break: normal;
                }
            }
            &-body {
                padding: 8px 0 15px;
                &-info {
                    padding: 0 15px;
                    margin: 8px 0 16px;
                    &-label {
                        align-self: center;
                        .c-label {
                            // background: @grey-30;
                            color: @grey-90;
                            background: #EAEEF1;
                            border-radius: 8px;
                        }
                    }
                }
                &-list {
                    padding: 8px 15px 0;
                }
            }
        }
    }
}


/* CARD HOTEL TARGET -  DESKTOP  */
@media (min-width: @desktop) {
    .mod--card-hotel-home-target {
        &.c-card-hotel {
            .c-card-hotel__content {
                &-body {
                    padding: 8px 0 20px;
                }
            }
        }
    }
}