/* Breadcrumbs */
.cmp-breadcrumb {
    display: none;
    &__claim {
        display: none;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: @grey-70;
    }
    &__list {
        padding-left: 0px;
    }
    &__item {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        &-link {
            .unstyle-anchor;
            &:after {
                content: ">";
                font-size: 7px;
                transform: scaleY(2);
                vertical-align: top;
                margin-left: 2px;
                display: inline-block;
            }
        }
    }
}

footer {
    .cmp-breadcrumb {
        display: inline-block;
        &__claim {
            display: block;
        }
    }
}

// royal hotel 
[data-template="hotel-royal"] {
    .heroslider .c-heroslider.inject-breadcrumb-true.mod--layout-vacational {
        .cmp-breadcrumb {
            .cmp-breadcrumb__list {
                padding-top: 24px;
                .cmp-breadcrumb__item {
                    padding-top: 0;
                }
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    footer {
        .cmp-breadcrumb {
            display: none;
        }
    }
    .inject-breadcrumb-true {
        .inject-breadcrumb {
            position: absolute;
            top: 0;
            z-index: 99;
            max-width: @content-sizing;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            .cmp-breadcrumb {
                display: block;
                &__list {
                    padding: 20px 0px;
                }
                &__item {
                    color: @white;
                    &-link {
                        color: @white;
                    }
                    &--active {
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .inject-header-true.inject-breadcrumb-true {
        .inject-breadcrumb {
            top: 105px;
        }
    }
}


//Policies
.breadcrumb {
    &.mod--breadcrumb-center {
        .cmp-breadcrumb {
            display: block;
            text-align: center;
            max-width: 65%;
            margin: 28px auto;
            margin-bottom: 40px;
            .cmp-breadcrumb__list {
                .cmp-breadcrumb__item {
                    font-weight: 400;
                    text-decoration: underline;
                }
            }
        }  
    }
    &.mod--breadcrumb-left {
        .cmp-breadcrumb {
            display: block;
            text-align: left;
            margin: 40px 0 24px;
            .cmp-breadcrumb__list {
                .cmp-breadcrumb__item {
                    font-weight: 400;
                    text-decoration: underline;
                }
            }
        }  
    }
}

@media (min-width: @desktop) {
    .breadcrumb {
        &.mod--breadcrumb-center {
            .cmp-breadcrumb {
                display: block;
                text-align: center;
                max-width: 65%;
                margin: 36px auto;
                margin-bottom: 56px;
                .cmp-breadcrumb__list {
                    .cmp-breadcrumb__item {
                        font-weight: 400;
                        text-decoration: underline;
                    }
                }
            }  
        }
    
    }
}