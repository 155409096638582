.c-service-contact-form {
    .c-fieldset {
        &__row,
        &__row-wrap {
            .policy-label {
                white-space: normal;
            }

            // Prevent calendar picker month title to go to 2 lines on mobile and mini mobile fit
            .flatpickr-wrapper {
                .flatpickr-calendar {
                    width: 260px;
                    .flatpickr-current-month,
                    .flatpickr-monthDropdown-months {
                        white-space: nowrap;
                    }
                    .flatpickr-rContainer {
                        width: 260px;
						.flatpickr-days {
							width: 260px;
                            .dayContainer {
                                min-width: 260px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.service-contact-form {
    .c-service-contact-form {
        .mod--services-form_wider {
            .c-fieldset:not(:nth-last-child(4)) {
                padding-top: 24px;
            }
            .c-fieldset {
                .required::after {
                    content: "*";
                    color: @red-error-bright;
                    padding-left: 6px;
                }
                .iti__selected-flag {
                    margin-left: -4px;
                    width: 58px;
                }
                .iti__arrow {
                    width: 0;
                    border-radius: 0;
                    height: 0;
                    border-left: 0;
                    border-right: 0;
                    border-top: 0;
                    transform: rotate(90deg);
                    position: absolute;
                    &::after {
                        content: "❯";
                        width: initial;
                        left: initial;
                        background: initial;
                        margin: 0px;
                        top: -44px;
                        right: -4px;
                    }
                }
                input[type="text"], input[type="tel"], input[type="password"], input[type="email"], input[type="search"], input[type="url"], input[type="date"], input[type="number"], input[type="month"], input[type="week"], input[type="time"], select {
                    border-color: @grey-90;      
                }
                input[type="tel"] {
                    padding-left: 56px;
                }
                input[type="text"].mod--with-icon.searchdate {
                    background: none;
                }
                .flatpickr-wrapper,
                label[for="number-adults"] {
                    &~.cmp-icon {
                        width: 20px;
                        position: absolute;
                        margin: 8px;
                        top: 29px;
                    }
                }
                label[for="number-adults"]~input[type="number"] {
                    padding-left: 34px;
                }
            }
        } 
    }
}

@media (min-width: @desktop) {
	.c-service-contact-form {
		.c-fieldset {
			&__row,
			&__row-wrap {
                .policy-label {
                    white-space: normal;
                }
				.flatpickr-wrapper {
					.flatpickr-calendar {
						width: 307.875px;
						.flatpickr-rContainer {
							width: 307.875px;
							.flatpickr-days {
								width: 307.875px;
                                .dayContainer {
                                    min-width: initial;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .service-contact-form {
        .c-service-contact-form {
            .mod--services-form_wider {
                .c-fieldset { 
                    .downto_49 {
                        width: 49%;
                    }
                    .upto_26 {
                        width: 26%;
                    }
                }
            }
        }
    }
}