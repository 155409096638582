.c-video--video-node,
.generic-video video {
    width: 100%;
}

.generic-video,
.heroslider {
    &.mod--control-play-bottom {
        .c-video {
            &__custom-control-play {
                bottom: 38px;
                transform: translateX(-50%) translateY(0);
            }

            &--image-wrapper:after {
                top: auto;
                bottom: 38px;
                transform: translateX(-50%) translateY(0);
            }
        }

        .c-heroslider__title {
            top: ~"calc(50% - 38px)";

            .cmp-image {
                max-height: 150px;
            }
        }
    }

    &.mod--control-play-bottom-with-button {
        .c-video {
            &__custom-control-play {
                bottom: 90px;
                transform: translateX(-50%) translateY(0);
            }

            &--image-wrapper:after {
                top: auto;
                bottom: 90px;
                transform: translateX(-50%) translateY(0);
            }
        }
    }
}

.c-video {
    position: relative;
    cursor: pointer;

    &--image-wrapper:after {
        background-color: rgba(0, 0, 0, 0.6);
        background-image: none;
        border-radius: @circle;
        font-family: "barcelo-ui-kit";
        content: "\e91f";
        color: white;
        font-size: 20px;
        width: 56px;
        height: 56px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &__custom-control {
        &-play {
            display: none;
            position: absolute;
            .z-layer;
            .common-transition;
            left: 50%;
            transform: translateX(-50%) translateY(50%);
            bottom: 50%;
            width: 56px;
            height: 56px;
            border-radius: 56px;
            background: rgba(0, 0, 0, 0.6);
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background: rgba(0, 0, 0, 1);
            }

            .cmp-icon {
                width: 18px;
                height: 18px;

                svg path {
                    stroke-width: 3px;
                }
            }

            &.mod--active {
                display: flex;
            }
        }
    }
}

/* VIDEO RESPONSIVE */

.c-video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    /* 16/9 ratio */
    height: 0;
    overflow: hidden;
}

.c-video-responsive iframe,
.c-video-responsive object,
.c-video-responsive embed,
.c-video-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.heroslider {
    .c-video-responsive {
        padding-bottom: inherit;
    }
}
