
.c-logged-update-form {
    &.modd--above-top {
        margin-top: -56px;
        z-index: 1;
        position: relative;
    }
    padding: 24px;
    border-radius: 16px;
    margin: 0 auto;
    background: #F5F5F6;
    max-width: 1040px;
    &--title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;  
        letter-spacing: 0.03px;
        text-align: center;
        margin-bottom: 8px;
    }
    &--text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;  
        letter-spacing: 0.08px;
        text-align: center;
    }
    form {
        max-width: 992px;
        //height: 112px;
        padding: 16px 32px 32px 32px;
        border-radius: 16px;
        margin: 24px auto 0 auto;
        background: @white;
        .c-fieldset {
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;
            flex-direction: column;

            .c-fieldset__row {
                padding: 0;
                margin-bottom: 16px;

                label {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 0;
                    &.required:after {
                        margin-left: 4px;
                        color: @red-error-bright;
                    }
                }
                select {
                    color: @grey-50;
                }
            }
        }

        .fieldset--send {
            flex-basis: 20%;
            .c-cta {
                width: 160px;
                width: 100%;
            }
        }
    }
}

@media (min-width: @desktop) {
    .c-logged-update-form {
        &.modd--above-top {
            margin-top: -56px;
            z-index: 1;
            position: relative;
        }
        form {
            .c-fieldset {
                flex-direction: row;
                .c-fieldset__row {
                    padding: 0 8px;
                    margin-bottom: 0;
                }
            }
            .fieldset--send {
                .c-cta {
                    width: 160px;
                }
            }
        }
    }
}