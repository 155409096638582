/* REGEN IMAGE COMPONENT - MOBILE FIRST */
.regen-image {
	position: relative;
	.cmp-image {
		height: 572px;
		&::after {
			content: "";
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 63.75%);
			width: 100%;
			height: 100%;
			position: absolute;
		}
	}
	&_text-block {
		position: absolute;
		width: @new-content-sizing;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 98%;

		&--bg {
			position: absolute;
			width: 100vw;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);

			padding: 24px;
			color: @white;
			background: rgba(56, 64, 68, 0.7);
			
			&.mod--block_right {
				margin-left: auto;
			}
		}
		&--title {
			font-size: 28px;
			line-height: 36px;
			letter-spacing: 0.09px;
			font-weight: 600;
		}
		&--subtitle {
			font-size: 20px;
			line-height: 30px;
			letter-spacing: 0.042px;
			font-weight: 600;
		}
		&--text {
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.03px;
			font-weight: 300;
		}
	}
}
/* REGEN IMAGE COMPONENT - DESKTOP  */
@media (min-width: @desktop) {
	.regen-image {
		position: relative;
		.cmp-image {
			height: 572px;
			&::after {
				content: "";
				background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 63.75%);
				width: 100%;
				height: 100%;
				position: absolute;
			}
		}
		&_text-block {
			position: absolute;
			width: @new-content-sizing;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			&--bg {
				position: relative;
				width: 421px;
				top: initial;
				left: initial;
				transform: none;
				// padding: 24px;
				// color: @white;
				// background: rgba(56, 64, 68, 0.5);
			}
			&--title {
				font-size: 36px;
				line-height: 42px;
				letter-spacing: 0.09px;
			}
			&--subtitle {
				font-size: 28px;
				line-height: 36px;
				letter-spacing: 0.042px;
			}
			&--text {
				font-size: 20px;
				line-height: 30px;
				letter-spacing: 0.03px;
			}
		}
	}
}