/* MYB PRICES UNDER CURRENCY LEFT */
.mod--currency-left {
  .c-price {
    &.c-price-myb {
      .c-price {
        justify-content: flex-end;
      }
    }
  }
}
