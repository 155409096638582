.mod--card-hotel-target {
    &.c-card-hotel {
        .c-card-hotel {
            &__image {
                .c-container-image {
                    height: 100%;
                    max-height: 185px;
                    .cmp-image {
                        max-height: 185px;
                    }
                }
                .c-card__image-overlay {
                    .c-label {
                        top: 8px;
                        left: 8px;
                        right: initial;
                        color: @grey-90;
                        font-weight: 700;
                        font-size: 14px;
                    }
                    .c-label-theme {
                        top: unset;
                        bottom: 8px;
                        background-color: #EAEEF1;
                    }
                }
            }

            &__content {
                display: flex;
                flex-grow: 1;
                flex-flow: column;
                position: relative;
                width: 100%;
                padding: 12px;
                :after {
                    content: "";
                    background: @grey-40;
                    width: 98%;
                    height: 1px;
                    bottom: 0;
                    transform: translateX(-50%);
                    left: 50%;
                    position: absolute;
                }
                &-header {
                    padding: 0;
                }
                &-body {
                    padding: 10px 0 0;
                    &-search {
                        padding: 8px 12px;
                        background: @grey-20;
                        font-size: 14px;
                        line-height: 24px;
                        &-title {
                            font-size: 14px;
                            font-weight: 700;
                        }
                        &-dates__checkin-icon,
                        &-dates__checkout-icon,
                        &-room-icon,
                        &-people-icon {
                            height: 26px;
                            width: 36px;
                            background-image: url(../../../../../../../../etc.clientlibs/barcelo/clientlibs/clientlib-base/resources/images/sprite-bhg.png);
                            background-repeat: no-repeat;
                            background-size: 36px auto;
                        }
                        &-dates, &-info, &-room, &-people {
                            display: flex;
                            align-items: center; 
                        }
                        &-dates {
                            margin: 4px 0;
                            &__checkin-icon {
                                background-position: -6px -620px;
                                width: 30px;
                            }
                            &__checkout-icon {
                                background-position: 0px -692px;
                                margin-left: 8px;
                            }
                            &__date {
                                white-space: nowrap;
                            }
                        }
                        &-room {
                            &-icon {
                                background-position: -6px -1270px;
                                width: 30px;
                            }
                        }
                        
                        &-people {
                            margin-left: 8px;
                            &-icon {
                                background-position: 0px -1198px;
                            }
                        }
                    }
                }

                &-footer {
                    flex: 0;
                    margin: auto 0;
                    border: 0;
                    padding: 10px 25px 10px 35px;
                    &-price {
                        .c-price {
                            align-items: flex-start;
                            &__value {
                                font-size: 28px;
                                font-weight: 700;
                            }
                        }
                    }
                    .c-cta {
                        margin-top: 0;
                    }
                    .c-card-hotel-btn-claim {
                        font-size: 12px;
                        line-height: 12px;
                        font-style: italic;
                    }
                }
            }
        }
    }
}
.c-card {
    .mod--card-hotel-launcher,
    &.mod--card-hotel-launcher {
        cursor: auto;
        width: 100%;
        margin: 0;
        box-shadow: none;
        .mod--label-price-integrated {
            &.c-card__image-overlay {
                .c-card {
                    &__titles {
                        bottom: 15px;
                        &-subtitle {
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 500;
                            text-transform: uppercase;
                            margin: 0 0 4px;
                        }
                        &-title {
                            margin: 0 0 8px;
                        }
                        &-head {
                            text-transform: none;
                        }
                    }
                    &__titles {
                        .c-card__btn {
                            .c-cta {
                                font-size: 15px;
                                padding: 0 25px;
                            }
                            &.mod--cta-secondary-on-image {
                                .c-cta {
                                    font-size: 16px;
                                    padding: 8px 32px;
                                }
                            }
                        }
                    }
                }
            }
        }
        figure {
            width: 100%;
            height: 100%;
        }
    }
}

/* CARD HOTEL TARGET -  DESKTOP  */
@media (min-width: @desktop) {
    .mod--card-hotel-target {
        &.c-card-hotel {
            flex-flow: row;
            .c-card-hotel {
                &__image {
                    width: 30%;
                    .c-container-image {
                        max-height: 221px;
                        .cmp-image {
                            max-height: 221px;
                        }
                    }
                }
                &__content {
                    width: 40%;
                    padding: 20px 22px;
                    align-self: center;
                    :after {
                        content: "";
                        width: 1px;
                        height: 80%;
                        transform: translateY(-50%);
                        top: 50%;
                        right: 0;
                        left: auto;
                        bottom: initial;
                    }
                    &-header {
                        &-title {
                            line-height: 1;
                        }
                    }
                    &-footer {
                        border-top: 0;
                        min-width: 316px;
                        button {
                            min-width: 200px;
                        }
                    }
                }
            }
        }
    }

    .c-card {
        .mod--card-hotel-launcher,
        &.mod--card-hotel-launcher {
            .mod--label-price-integrated {
                &.c-card__image-overlay {
                    .c-card {
                        &__titles {
                            .c-card__btn {
                                .c-cta {
                                    font-size: 18px;
                                    padding: 0 32px;
                                }
                                &.mod--cta-secondary-on-image {
                                    .c-cta {
                                        font-size: 16px;
                                        padding: 8px 32px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}