/* SCAFFOLDING */

*,
*:before,
*:after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html {
    font-size: @html-font-size;
    margin: 0;
    padding: 0;
    line-height: 0;
    overflow-x: hidden;
    overflow-y: visible;
}

body {
    font-family: @font-family-base;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @text-color;
    background: @body-bg;
    font-weight: 300;
    word-wrap: break-word;
    direction: ltr;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: visible;
}

img {
    max-width: none;
    width: 100%;
    height: auto;
}

svg {
    width: 100%;
    height: inherit;
}

a {
    cursor: pointer;
}

button {
    &:focus {
        outline: none;
    }
}

hr,
.h-rule {
    height: 1px;
    width: auto;
    background-color: @grey-40;
    margin: 15px 0px;
}

.generic-separator {
    .cmp-separator__horizontal-rule {
        visibility: hidden;
    }
    &.separator-line {
        .cmp-separator__horizontal-rule {
            visibility: visible;
        }
    }
    &.separator-line-minimun {
        .cmp-separator__horizontal-rule {
            visibility: visible;
            margin: 8px 0;
        }
    }
    &.no-default-hr-margin {
        .cmp-separator__horizontal-rule {
            margin: 0;
            height: 0;
        }
    }
    &.separator-line-only-desktop {
        .cmp-separator__horizontal-rule {
            visibility: hidden;
            margin: 0;
        }
    }
}

.c-pagination {
    font-size: 14px;
    font-weight: 500;
    color: @grey-70;
}

.spinner {
    position: relative;
    * {
        opacity: 0.7;
    }
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 4;
    }
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 4;
        left: 0;
        background-image: url("/content/dam/barcelo/commons/icons/ui/loading-circle.svg");
        background-repeat: no-repeat;
        background-position: center center;
        justify-content: center;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 1s;
        animation-name: spinner-loading;
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .generic-separator {
        &.separator-line-only-desktop {
            .cmp-separator__horizontal-rule {
                visibility: visible;
                margin: 0;
            }
        }
    }
}

// MEDIA - DESKTOP CONTENT - SIZE
@media (min-width: @desktop) and (max-width: @content-sizing) {
}
