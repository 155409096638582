p,
a,
span,
b,
strong,
i,
em,
mark,
small,
del,
ins,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

.c-rich-text {
    p {
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .cmp-text__readmore--wrapper {
        p:first-child {
            margin: 0px;
        }
    }
}

a {
    // color: var(--b-blue);
    color: inherit;
    text-decoration: underline 1px;
    word-break: break-word;
    .common-transition;
    &:hover {
        .common-transition;
        // color: @b-link-hover;
        color: inherit;
        text-decoration-color: transparent;
    }
}

b,
strong {
    font-weight: 700;
}

i,
em {
    font-style: italic;
}

mark {
    background: fade(@b-light-blue, 30%);
}

small {
    font-size: @small-text-base;
}

del {
    text-decoration: line-through;
}

ins {
    text-decoration: underline;
}

h1 {
    font-size: 24px;
    line-height: 28px;
}

h2 {
    font-size: 20px;
    line-height: 26px;
}

h3 {
    font-size: 18px;
    line-height: 24px;
}

h4 {
    font-size: 16px;
    line-height: 20px;
}

h5 {
    font-size: 15px;
    line-height: 20px;
}

h6 {
    font-size: 14px;
    line-height: 20px;
}

ul,
ol {
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.cmp-text,
.launcher,
.c-card,
.c-card-hotel-list,
.c-card-hotel__content-body-list {
    ul {
        li {
            word-break: break-word;
            padding-left: 1em;
            position: relative;
            &:before {
                content: "•";
                color: @grey-42;
                display: inline-block;
                font-size: 1em;
                width: 1em;
                position: absolute;
                left: 0px;
            }
        }
        &.mod--checks {
            li {
                padding-left: 1.5em;
                &:before {
                    content: "\e908";
                    font-family: "barcelo-ui-kit";
                    width: 1em;
                }
            }
        }
        &.mod--two-cols {
            column-count: 2;
        }
    }
    ol {
        li {
            word-break: break-word;
            counter-increment: custom-counter;
            padding-left: 2em;
            position: relative;
            &:before {
                content: counter(custom-counter);
                color: @grey-90;
                background-color: @grey-20;
                font-size: 1em;
                width: 1.5em;
                height: 1.5em;
                border-radius: 24px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0px;
            }
        }
    }
}

time,
.c-timedate {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: @grey-70;
}

.preheading {
    font-size: 14px;
    color: @grey-70;
    font-weight: 500;
    line-height: 1.43;
}

.heading {
    font-size: 20px;
    color: @grey-90;
    font-weight: 700;
    line-height: 1.4;
}

.subheading {
    font-size: 16px;
    color: @grey-90;
    font-weight: 500;
    line-height: 1.4;
}

.preheading,
.heading,
.subheading {
    display: block;
    &.text-white {
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    }
}

.section-header{
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.0015em;
    margin-bottom: 8px;
}

.section-subheader{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
}
.c-section {
	&__headings {
		margin-top: 48px;
		margin-bottom: 16px;
        .cmp-title {
            text-align: inherit;
        }
		&--title,
        .cmp-title__text {
            .format-text(28px, 36px, 300);
			letter-spacing: 0.15px;
            // text-align: left;
		}
        &--subtitle,
        .cmp-title__subtitle {
            margin-top: 8px;
            // text-align: left;
        }
	}
}
.c-services {
	&__headings {
		margin-top: 8px;
		margin-bottom: 24px;
        .cmp-title {
            text-align: inherit;
        }
		&--title,
        .cmp-title__text {
            .format-text(28px, 36px, 600);
			letter-spacing:  0.0015em;
		    margin-bottom: 32px;

		}
        &--subtitle,
        .cmp-title__subtitle {
            .format-text(20px, 30px, 300);
        }
	}
}

@media (min-width: @desktop) {
    h1 {
        font-size: 36px;
        line-height: 44px;
    }

    h2 {
        font-size: 24px;
        line-height: 36px;
    }

    h3 {
        font-size: 20px;
        line-height: 28px;
    }

    h4 {
        font-size: 18px;
        line-height: 24px;
    }

    h5 {
        font-size: 16px;
        line-height: 20px;
    }

    h6 {
        font-size: 15px;
        line-height: 20px;
    }
    .section-header{
        font-size: 36px;
        line-height: 42px;
        letter-spacing: 0.0025em;
    }
    

    .c-section {
		&__headings {
			margin-top: 56px;
			margin-bottom: 24px;
			&--title,
            .cmp-title__text {
                .format-text(36px, 42px, 300);
				letter-spacing: 0.25px;
			}
		}
	}
    .c-services {
        &__headings {
            margin-top: 8px;
            margin-bottom: 24px;
            .cmp-title {
                text-align: inherit;
            }
            &--title,
            .cmp-title__text {
                .format-text(36px, 42px, 600);
                letter-spacing:  0.0015em;
                margin-bottom: 40px;
    
            }
            &--subtitle,
            .cmp-title__subtitle {
                .format-text(24px, 34px, 300);
            }
        }
    }
}
