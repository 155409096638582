/* CARD HOTEL OFFER */
.c-offer {
    width: 100%;
    background-color: @b-light-green;
    color: @b-green;
    font-weight: 500;
    border-radius: @soft-corners;
    text-align: center;
    padding: 7px 15px;
    max-height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    &__highlight {
        font-size: 28px;
        line-height: 26px;
        font-weight: 700;
        vertical-align: middle;
        margin: 0.25rem;
    }
    &-price {
        display: inline-flex;
        align-items: center;
    }
    .cmp-icon {
        svg {
            path {
                stroke: @b-green;
                stroke-width: 4px;
            }
        }
    }
    .c-price {
        display: flex;
        align-items: flex-end;
        font-size: 24px;
        font-weight: 700;
        &__currency,
        &__type {
            font-size: 18px;
            line-height: 20px;
            font-weight: 500;
            white-space: nowrap;
        }
    }
}
