/* COMPONENT IA-LIST MOBILE FIRST (~ TABLET)*/
.ia-list {
	margin-top: 20px;
	&__content {
		max-width: @new-content-sizing;
		margin: 0 auto;
		display: flex;
		gap: 0 29px;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0 16px;
		&-item {
			width: 96%;
			.accordion-item {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;
				height: 48px;
				padding: 12px 0;
				.accordion-label {
					padding: 0px 12px;
					letter-spacing: 0.08px;
				}
				.accordion-toggle {
					gap: 12px;
					flex: 1 0 0;
					justify-content: end;
					padding: 0px 12px;
					.ia-list-count {
						padding: 2px 10px;
						border-radius: 4px;
						background: rgb(234, 238, 241);
						font-size: 12px;
						font-weight: 300;
						line-height: 16px;
						letter-spacing: 0.048px;
					}
					.svg-icon.accordion-arrow {
						margin-right: 0;
						padding: 2px;
					}
				}
				.accordion-content {
					padding: 0 12px;
					.cmp-title {
						font-weight: 400;
						letter-spacing: 0.08px;
						color: black;
					}
				}
				&.active {
					height: auto;
					&>.accordion-content {
						min-height: 48px;
						padding: 0 12px;
						margin: 24px 0 16px;
					}
				}
			}
		}
	}
}

/* COMPONENT IA-LIST - DESKTOP  */
@media (min-width: @desktop) {
	.ia-list {
		&__content {
			padding: 0;
			&-item {
				width: 48%;
			}
		}
	}
}