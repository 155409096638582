/* DESTINATIONS LIST */
.destinations-list {
    &__content {
        display: flex;
        flex-wrap: wrap;

        &-item {
            width: 100%;
        }
    }

    .cmp-title__text {
        text-align: center;
        border-bottom: 1px solid #d8d8d8;
        line-height: 2;
    }

    .accordion-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .accordion-label {
            font-size: 18px;
            line-height: 28px;
            font-weight: 700;
            padding: 8px 0px;
            .unstyle-anchor;
        }

        a.accordion-label:hover {
            color: @b-link-hover;
        }

        .accordion-toggle {
            align-self: flex-end;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;

            .destinations-list-count {
                margin-right: 5px;
                font-size: 12px;
                line-height: 20px;
                font-weight: 500;
                color: #767676;
                display: inline-block;
                padding: 2px 9px;
                text-align: center;
                width: auto;
                min-width: 32px;
                background: #f5f5f5;
                border-radius: 12px;
                margin: 2px 0.25rem 2px 0.25rem;
            }
        }
    }

    .accordion-content {
        -webkit-columns: 100px 2;
        -moz-columns: 100px 2;
        columns: 100px 2;
        margin-right: 32px;

        .cmp-list {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;

            .cmp-title {
                font-weight: 700;
                text-align: left;
                line-height: 1.15;
            }

            .cmp-text {
                font-weight: 500;
                display: inline-block;
                line-height: 1.4;
                margin: 6px 0 0 0;

                &:first-child {
                    margin: 0;
                }

                &:last-child {
                    padding-bottom: 0.9rem;
                }
            }
            &  > div {
                padding-top: 4px;
            }
        }
    }

    &.mod--col-2 {
        .destinations-list__content-item {
            width: 50%;
            padding-right: 15px;

            &:nth-child(even) {
                padding-right: 0px;
            }
        }
    }

    &.mod--col-3 {
        .destinations-list__content-item {
            width: 33.3%;
            padding-right: 15px;

            &:nth-child(3n) {
                padding-right: 0px;
            }
        }
    }
}