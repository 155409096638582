.homepage {
  .hidden-element {
    display: none;
    opacity: 0;
  }

  .visible-element {
    display: block;
    opacity: 1;
  }

  .animate-fade-in {
    animation: fadeInNew 0.2s;
  }

  .inject-hero {
    .hidden-element;
  }

  .inject-hero.inject-fastbooking {
    .visible-element;
    .animate-fade-in;
  }

  .heroslider {
    .fastbooking {
      .hidden-element;
    }

    .swiper-button-JS,
    .c-heroslider__central-block_titles {
      opacity: 0;
    }

    &.inject-fastbooking-true {
      .swiper-button-JS,
      .c-heroslider__central-block_titles {
        opacity: 1;
        .animate-fade-in;
      }

      .fastbooking {
        .visible-element;
        animation: slideIn 0.2s;
      }
    }
  }

  .xpf_reference_header {
    position: absolute;
    width: 100%;
    transition: 0.2s;
    animation: slideIn 0.2s;
  }

  .responsivegrid,
  #home_modules_header_jcr_content_root_generic_container {
    .animate-fade-in;
  }
}

// ANIMATION
@keyframes slideIn {
  0% {
    transform: scaleY(0.9) scaleX(0.9);
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    transform: scaleY(1) scaleX(1);
    opacity: 1;
  }
}

@keyframes fadeInNew {
  0%,
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
