
.c-royal-banner {
	display: grid;
	gap: 18px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
	padding: 18px 0 16px 0;
	.royal-banner__half {
		text-align: center;
		&-header {
			img {
				width: 128px;
				height: auto;
				padding: 32px 0 54px;
				margin: 0 auto;
				display: block;
			}
		}
		&-title {
			font-family: 'Didot', Times, serif;
			font-size: 25px;
			line-height: 31px;
			font-weight: 400;
			margin-bottom: 18px;
		}
		&-description {
			font-size: 15px;
			line-height: 18px;
		}
		&-features {
			display: flex;
			flex-wrap: wrap;
			padding: 40px 0 46px 0;
			margin: 16px 0 26px 0;
			border-top: 1px solid;
			border-bottom: 1px solid;
			gap: 16px 0;
		}
		&-feature {
			text-align: center;
			padding: 0 8px;
			width: 50%;
			.textimage .c-textimage {
				padding: 0;
				.cmp-text {
					h3 {
						font-size: 13px;
						line-height: 15px;
						margin-top: 0px;
						min-height: 30px;
					}
					p {
						font-size: 11px;
						line-height: 15px;
					}
				}
			}
			.cmp-icon,
			.icon {
				width: 32px;
				height: 32px;
			}
		}
		&:nth-of-type(2) {
			// max-height: 660px;
			max-width: 580px;
		}
		.gotopagecta.mod--cta-secondary {
			height: 48px;
			.c-cta {
				background: transparent;
				border-color: @white;
				color: @white;
				font-weight: 400;
				border-width: 1px;
			}
		}
	}
}
body.hotel-royal-level {
	.royal-banner {
		.cmp-text {
			color: inherit;
		}
	}
}

@media (min-width: @desktop) {
	.c-royal-banner {
		gap: 70px;
		grid-template-columns: repeat(2, 1fr);
		padding: 74px 0;
		// max-height: 810px;
		.royal-banner__half {
			&-header {
				padding: 0 44px;
			}
			&-feature {
				text-align: center;
				padding: 0 16px;
				width: 25%;
				.textimage .c-textimage {
					.cmp-text {
						h3 {
							min-height: 30px;
						}
					}
				}
			}
		}
	}
}