/* SWIPER CUSTOM POLICIES AND UTILS */
.mod--swiper-half-buttons {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 26px;
    height: 48px;
    margin-top: -24px;
    background-color: rgba(0, 0, 0, 0.6);
    background-image: none;
    color: white;
    .z-navs;
    cursor: pointer;
  }
  .swiper-button-prev {
    left: 0px;
    border-top-right-radius: @circle;
    border-bottom-right-radius: @circle;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    &:after {
      font-family: "barcelo-ui-kit";
      content: "\e901";
      font-size: 16px;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      position: absolute;
    }
  }
  .swiper-button-next {
    right: 0px;
    border-top-left-radius: @circle;
    border-bottom-left-radius: @circle;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    &:after {
      font-family: "barcelo-ui-kit";
      content: "\e902";
      font-size: 16px;
      top: 50%;
      transform: translateY(-50%);
      right: 2px;
      position: absolute;
    }
  }
  .swiper-rtl .swiper-button-next {
    left: 0px;
    border-top-left-radius: @circle;
    border-bottom-left-radius: @circle;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    &:after {
      font-family: "barcelo-ui-kit";
      content: "\e902";
      font-size: 16px;
      top: 50%;
      transform: translateY(-50%);
      right: 2px;
      position: absolute;
    }
  }
  .swiper-rtl .swiper-button-prev {
    right: 0px;
    border-top-right-radius: @circle;
    border-bottom-right-radius: @circle;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    &:after {
      font-family: "barcelo-ui-kit";
      content: "\e901";
      font-size: 16px;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      position: absolute;
    }
  }
}

.mod--swiper-round-buttons {
  .swiper-button-next,
  .swiper-button-prev {
    top: 50%;
    width: 48px;
    height: 48px;
    .z-navs;
    .common-transition;
    &:hover {
      opacity: 0.7;
    }
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    border-radius: @circle;
    &:after {
      left: 15px;
    }
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    border-radius: @circle;
    &:after {
      right: 15px;
    }
  }
}

.mod--swiper-outside-buttons {
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 0px;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 0px;
  }
}

.mod--swiper-inside-buttons {
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 15px;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 15px;
  }
}

.mod--swiper-inside-fullscreen {
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: ~"calc(calc(100% - " @content-sizing ~") / 2)";
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: ~"calc(calc(100% - " @content-sizing ~") / 2)";
  }
}

.mod--swiper-white-buttons {
  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.8);
    color: @grey-90;
  }
}

.mod--swiper-white-big-buttons,
.mod--swiper-white-big-buttons.modal-swiper-default {
  .swiper-button-next,
  .swiper-button-prev {
    color: white;
    background-color: rgba(255, 255, 255, 0.6);
    height: 80px;
    width: 39px;
    &::after {
      font-size: 30px;
    }
  }
}

.mod--swiper-rounded {
  .swiper {
    .swiper-wrapper-overflow {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: @rounded-corners;
      overflow: hidden;
    }
  }
}

/* SWIPER CUSTOM STYLES - MOBILE FIRST (~ TABLET) */
.swiper {
  .swiper-wrapper-overflow {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .mod--swiper-half-buttons;
  .swiper-pagination {
    .z-overlayer;
    &.swiper-pagination-fraction {
      font-size: 13px;
      color: white;
      .mod--text-shadow;
      text-align: left;
      margin-left: 30px;
      bottom: 15px;
      font-weight: 500;
      .swiper-pagination-current {
        margin-right: -3px;
      }
      .swiper-pagination-total {
        margin-left: -3px;
      }
      .swiper-pagination.text-pagination:after {
        content: "\2022  Title of the image";
        font-weight: 300;
        font-size: 15px;
        margin-left: 4px;
      }
    }
    &.swiper-pagination-bullets {
      bottom: -37px;
      .swiper-pagination-bullet {
        opacity: 0.4;
        background: @black;
        margin: 0 8px;
        &-active {
          opacity: 1;
          background: transparent;
          border: 2px solid @black;
          height: 10px;
          width: 10px;
          transform: translateY(1px);
        }
      }
      .mod--light-bullets {
        .swiper-pagination-bullet {
          background-color: @grey-20;
          &-active {
            border-color: @grey-20;
            background-color: transparent;
          }
        }
      }
    }
  }
  &.--new-max-width {
    max-width: @new-content-sizing;
  }
}

.swiper.swiper-type-carouselCustom {
  width: 90%;
  .swiper-slide {
    width: 90%;
    transform: scale(0.9);
  }
  .swiper-slide-active {
    width: 90%;
    transform: scale(1);
    transition: all 0.5s;
  }
}

/* SWIPER CUSTOM STYLES - DESKTOP  */
@media (min-width: @desktop) {
  .mod--swiper-outside-buttons {
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: -65px;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: -65px;
    }
  }
  .swiper {
    .mod--swiper-round-buttons;
    .mod--swiper-outside-buttons;
  }
  .mod--swiper-half-buttons {
    .swiper {
      .swiper-button-next,
      .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 26px;
        height: 48px;
        margin-top: -24px;
        background-color: rgba(0, 0, 0, 0.6);
        background-image: none;
        color: white;
        .z-layer;
        cursor: pointer;
      }
      .swiper-button-prev,
      .swiper-rtl .swiper-button-next {
        left: 0px;
        border-top-right-radius: @circle;
        border-bottom-right-radius: @circle;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        &:after {
          font-family: "barcelo-ui-kit";
          content: "\e901";
          font-size: 16px;
          top: 50%;
          transform: translateY(-50%);
          left: 2px;
          position: absolute;
        }
      }
      .swiper-button-next,
      .swiper-rtl .swiper-button-prev {
        right: 0px;
        border-top-left-radius: @circle;
        border-bottom-left-radius: @circle;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        &:after {
          font-family: "barcelo-ui-kit";
          content: "\e902";
          font-size: 16px;
          top: 50%;
          transform: translateY(-50%);
          right: 2px;
          position: absolute;
        }
      }
    }
  }
}

/*SWIPER CUSTOM STYLES - DESKTOP (CONTENT SIZE) */
@media (min-width: @desktop) and (max-width: @content-sizing) {
  .swiper {
    .mod--swiper-inside-buttons;
  }
  .mod--swiper-outside-buttons {
    .swiper {
      .swiper-button-prev,
      .swiper-rtl .swiper-button-next {
        left: 15px;
      }
      .swiper-button-next,
      .swiper-rtl .swiper-button-prev {
        right: 15px;
      }
    }
  }
  .mod--swiper-half-buttons {
    .swiper {
      .swiper-button-next,
      .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 26px;
        height: 48px;
        margin-top: -24px;
        background-color: rgba(0, 0, 0, 0.6);
        background-image: none;
        color: white;
        .z-layer;
        cursor: pointer;
      }
      .swiper-button-prev,
      .swiper-rtl .swiper-button-next {
        left: 0px;
        border-top-right-radius: @circle;
        border-bottom-right-radius: @circle;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        &:after {
          font-family: "barcelo-ui-kit";
          content: "\e901";
          font-size: 16px;
          top: 50%;
          transform: translateY(-50%);
          left: 2px;
          position: absolute;
        }
      }
      .swiper-button-next,
      .swiper-rtl .swiper-button-prev {
        right: 0px;
        border-top-left-radius: @circle;
        border-bottom-left-radius: @circle;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        &:after {
          font-family: "barcelo-ui-kit";
          content: "\e902";
          font-size: 16px;
          top: 50%;
          transform: translateY(-50%);
          right: 2px;
          position: absolute;
        }
      }
    }
  }
}

.mod--carousel-dropped-dots,
.mod--carousel-dropped-dots--black {
  .swiper {
    .swiper-pagination.swiper-pagination-bullets {
      display: flex;
      gap: 8px;
      justify-content: center;
      position: absolute;
      bottom: 27px;
      .swiper-pagination-bullet {
        border-radius: 6px;
        width: 6px;
        height: 6px;
        background: #fff;
        margin: unset;
      }
      .swiper-pagination-bullet-active {
        width: 20px;
        border-radius: 100px;
        border: unset;
        transform: none;
      }
    }
  }
  &.--black {
    .swiper {
      .swiper-pagination.swiper-pagination-bullets {
        .swiper-pagination-bullet {
          background: @grey-90;
        }
      }
    }
  }
}
.mod--carousel-dropped-dots--black {
  .swiper {
    .swiper-pagination.swiper-pagination-bullets {
      // justify-content: end;
      bottom: -20px;
      .swiper-pagination-bullet {
        background: @grey-90;
      }
    }
  }
  & ~ .c-hotel-related__content_legend {
    padding: 20px 0px 10px;
  }
}

.mod--carousel-small-white-arrows,
.c-hotel-related .mod--carousel-small-white-arrows {
  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      width: 32px;
      height: 32px;
      color: #000;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 50%;
    }
    .swiper-button-prev {
      left: 16px;
      &::after {
        left: 6px;
      }
    }
    .swiper-button-next {
      right: 16px;
      &::after {
        right: 6px;
      }
    }
  }
}
.c-hotel-related .mod--carousel-small-white-arrows {
  .swiper {
    .swiper-button-prev {
      display: none;
    }
    .swiper-button-next {
      display: none;
    }
  }
}

@media (min-width: @desktop) {
  .mod--carousel-dropped-dots--black {
    .swiper {
      .swiper-pagination.swiper-pagination-bullets {
        justify-content: end;
      }
    }
    & ~ .c-hotel-related__content_legend {
      padding: 10px 0px;
    }
  }
  .c-hotel-related .mod--carousel-small-white-arrows {
    .swiper {
      padding-left: 60px;
      .swiper-wrapper-overflow {
        width: 99%;
      }
      .swiper-button-prev {
        display: block;
        left: 0;
      }
      .swiper-button-next {
        display: block;
        right: -48px;
      }
    }
  }
}
