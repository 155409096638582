@import (once) "../libs/grid/grid_base.less";

/* maximum amount of grid cells to be provided */
@max_col: 12;

/* default breakpoint */
.aem-Grid {
  .generate-grid(default, @max_col);
}

/* phone breakpoint */
@media (max-width: 1024px) {
  .aem-Grid {
    .generate-grid(phone, @max_col);
  }
}
