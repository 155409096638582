// RESORT FONTS

// FONT FAMILIES
@museo-regular: "Museo Regular", sans-serif;
@museo-medium: "Museo Medium", sans-serif;
@museo-bold: "Museo Bold", sans-serif;
@museo-slab: "Museo Slab", serif;
@museo-slab-bold: "Museo Slab Bold", serif;

// FONT ROLES
@primary-font: @museo-regular;
@secundary-font: @museo-slab;

// FONT VARIANTS
.font-700 {
	font-weight: 700;
	.cmp-title__text,
	.cmp-text p,
	.c-cta {
		font-weight: 700;
	}
}
.font-400 {
	font-weight: 400;
	.cmp-title__text,
	.cmp-text p {
		font-weight: 400;
	}
	.c-cta {
		font-weight: 300;
	}
}
.font-uppercase {
	text-transform: uppercase;
	.cmp-title__text,
	.cmp-text p,
	.c-cta {
		text-transform: uppercase;
	}
}
.font-underline {
	text-decoration: underline;
	.cmp-title__text,
	.cmp-text p,
	.c-cta {
		text-decoration: underline;
	}
}
.no-boxshadow {
	box-shadow: none;
	.c-cta {
		box-shadow: none;
	}
}
.font-museo-slab {
	font-family: @museo-slab;
	.c-cta {
		font-family: @museo-slab;
	}
}
.font-museo {
	font-family: @museo-regular;
	.c-cta {
		font-family: @museo-regular;
	}
}
.font-museo-bold {
	font-family: @museo-bold;
	.c-cta {
		font-family: @museo-bold;
	}
}
.font-museo-medium {
	font-family: @museo-medium;
	.c-cta {
		font-family: @museo-medium;
	}
}
.font-secundary {
	font-family: @secundary-font;
	.c-cta {
		font-family: @secundary-font;
	}
}

// FONT SIZES
.text-13,
.text-13 .cmp-title__text,
.text-13 .cmp-text p,
.text-13 .c-cta {
	font-size: 13px;
	line-height: 18px;
}
.text-14,
.text-14 .cmp-title__text,
.text-14 .cmp-text p,
.text-14 .c-cta {
	font-size: 14px;
	line-height: 18px;
}
.font-m-15,
.font-body,
.font-m-15 .cmp-title__text,
.font-m-15 .cmp-text p,
.font-body .cmp-title__text,
.font-body .cmp-text p,
.font-m-15 .c-cta {
	font-size: 15px;
	line-height: 24px;
	font-weight: 300;
}
.font-17,
.font-17 .cmp-title__text,
.font-17 .cmp-text p,
.font-17 .c-cta {
	font-size: 17px;
	line-height: 24px;
}
.font-18 .c-cta {
	font-size: 18px;
	line-height: 24px;
}
.font-18,
.font-18 .cmp-title__text,
.font-18 .cmp-text p,
.font-18 .c-cta {
	font-size: 18px;
	line-height: 24px;
}
.font-xl-22,
.font-xl-22 .c-cta,
.font-xl-22 .cmp-title__text,
.font-xl-22 .cmp-text p,
.font-xl-22 .c-cta {
	font-size: 22px;
	line-height: 28px;
}
.font-2xl-36,
.font-2xl-36 .c-cta,
.font-2xl-36 .cmp-title__text,
.font-2xl-36 .cmp-text p {
	font-size: 36px;
	line-height: 38px;
	&.font-secundary.font-uppercase {
		letter-spacing: -8px;
	}
}
.font-3xl-54,
.font-3xl-55,
.font-3xl-55 .c-cta,
.font-3xl-55 .cmp-title__text,
.font-3xl-55 .cmp-text p {
	font-size: 54px;
	line-height: 60px;
	&.font-secundary.font-uppercase {
		letter-spacing: -8px;
	}
}
.font-4xl-64,
.font-4xl-64 .c-cta,
.font-4xl-64 .cmp-title__text,
.font-4xl-64 .cmp-text p {
	font-size: 64px;
	line-height: 60px;
}
.font-5xl-70,
.font-5xl-70 .c-cta,
.font-5xl-70 .cmp-title__text,
.font-5xl-70 .cmp-text p {
	font-size: 70px;
	line-height: 78px;
}
.font-d86_m55,
.font-d86_m55 .c-cta,
.font-d86_m55 .cmp-title__text,
.font-d86_m55 .cmp-text p {
	font-size: 55px;
	line-height: 60px;
}

.text-center,
.text-center .cmp-title__text,
.text-center .cmp-text p,
.text-center .c-cta,
.text-center .c-submenu-container .cmp-title, .text-center .c-submenu-container .cmp-title__text {
	text-align: center;
}
.text-left,
.text-left .cmp-title__text,
.text-left .cmp-text p,
.text-left .c-cta {
	text-align: left;
}
.text-right,
.text-right .cmp-title__text,
.text-right .cmp-text p,
.text-right .c-cta {
	text-align: right;
}

@media (min-width: @desktop) {
	.font-d86_m55,
	.font-d86_m55 .cmp-title__text,
	.font-d86_m55 .cmp-text p {
		font-size: 86px;
		line-height: 78px;
	}
	.font-2xl-36,
	.font-2xl-36 .cmp-title__text,
	.font-2xl-36 .cmp-text p  {
		font-size: 36px;
		line-height: 38px;
	}
}