/* COUNTRY LANG - MOBILE FIRST */
.xpf_reference_footer {
    .c-country-lang {
        hr {
            display: none;
        }
        .c-megamenu__menu-utils-item {
            margin-left: 0px;
            padding: 0px;
            height: 41px;
            border-top: 0px;
            &-anchor {
                display: flex;
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                margin: 7px 0px;
                color: white;
                opacity: 1;
                .svg-icon {
                    height: 20px;
                    width: 20px;
                    margin-right: 15px;
                    .cmp-icon {
                        height: 20px;
                        width: 20px;
                        svg path {
                            fill: white;
                        }
                    }
                }
                .c-country-lang__select {
                    margin-left: auto;
                    width: 24px;
                    height: 16px;
                    margin-right: 20px;
                    position: relative;
                    background-repeat: no-repeat;
                    &:after {
                        font-family: "barcelo-ui-kit";
                        content: "\e907";
                        color: white;
                        font-size: 7px;
                        position: absolute;
                        right: -13px;
                        top: 0;
                        height: 16px;
                        line-height: 16px;
                    }
                    .c-flag-select__options {
                        opacity: 0;
                        height: 0px;
                        width: 0px;
                    }
                }
                &:hover {
                    opacity: 1;
                    color: white;
                }
            }
        }
    }
}

/* COUNTRY-LANG - DESKTOP  */
@media (min-width: @desktop) {
    .xpf_reference_footer {
        .c-country-lang {
            hr {
                display: block;
            }
            .c-megamenu__menu-utils-item {
                &-anchor {
                    justify-content: space-between;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                    color: @grey-45;
                    .svg-icon {
                        display: none;
                    }
                    .c-country-lang__select {
                        width: 24px;
                        height: 16px;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
