//	 __          __              _             _ 
//	 \ \        / /             (_)           | |
//	  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _| |
//	   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` | |
//	    \  /\  / (_| | |  | | | | | | | | (_| |_|
//	     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
//	                                      __/ |  
//	                                     |___/   
//	
//	   ANY CHANGES MADE HERE WILL TAKE EFFECT ON THE "NEW ROYAL HIDEAWAY (BRH)" FASTBOOKING COMPONENT

@import (once) "./src/main/webpack/components/barcelo/generic/cta/less/c-cta";

.ribbon {
    display: none;
    position: relative;
    &:not(.ribbon-language) {
        min-height: 30px;
        overflow: hidden;
    }
    &.mod--bg-blue {
        .c-ribbon {
            background-color: @b-light-blue;
            .text-color(@b-blue);
        }
    }
    &.mod--bg-yellow {
        .c-ribbon {
            background-color: @b-yellow;
            .text-color(@b-yellow);
        }
    }
    .mix-mod-color-generator(ribbon, bg-barcelo-black, @grey-90);
    .mix-mod-color-generator(ribbon, bg-dark-gray, @grey-50);
    .mix-mod-color-generator(ribbon, bg-light-gray, @grey-20);
    .mix-mod-color-generator(ribbon, bg-white, @white);
    .mix-mod-color-generator(ribbon, bg-red, @b-red);
    .mix-mod-color-generator(ribbon, bg-blue, @b-blue);
    .mix-mod-color-generator(ribbon, bg-light-blue, @b-light-blue);
    .mix-mod-color-generator(ribbon, bg-green, @b-green);
    .mix-mod-color-generator(ribbon, bg-light-green, @b-light-green);
    .mix-mod-color-generator(ribbon, bg-light-yellow, @b-yellow);
    .mix-mod-color-generator(ribbon, bg-intense-blue, @b-intense-blue);
    .mix-mod-color-generator(ribbon, white-montecastillo, @white-montecastillo);
    .mix-mod-color-generator(ribbon, green-montecastillo, @green-montecastillo);
    .mix-mod-color-generator(ribbon, brown-royal, @brown-royal);
    .mix-mod-color-generator(ribbon, brown-light-royal, @brown-light-royal);
    .mix-mod-color-generator(ribbon, grey-royal, @grey-royal);
    .mix-mod-color-generator(ribbon, brown-classic, @brown-classic);

    &.mod--bg-barcelo-black {
        .c-ribbon__extended__content {
            color: @white;
            .cmp-title {
                color: @white;
            }
        }
    }
}

main.root > div .parsys .ribbon {
    position: absolute;
    top: 0px;
    width: 100%;
    height: auto;
    .z-navs;
}

.c-ribbon {
    width: 100%;
    height: auto;
    color: initial;
    background-color: @b-light-blue;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
    &__close-btn {
        height: 16px;
        width: 16px;
        font-size: 16px;
        position: absolute;
        right: 7px;
        top: 7px;
        cursor: pointer;
    }
    &__light {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 28px;
        .icon {
            width: 20px;
            height: 20px;
            margin: 5px;
        }
        .cmp-text {
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
            padding: 5px 35px 5px 0px;
        }
        .c-cta {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.4;
        }
    }

    &__extended {
        min-height: 84px;
        padding: 25px;
        .icon {
            width: auto;
            height: 48px;
            margin: 0 auto 15px auto;
        }
        &__content {
            .cmp-title__text {
                text-align: left;
                line-height: 32px;
            }
            .cmp-text {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.4;
                margin-right: 7px;
                b {
                    font-size: 18px;
                    line-height: 26px;
                    display: block;
                }
            }
        }
        .cta {
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
            .c-cta {
                margin-top: 15px;
                width: 100%;
                a {
                    width: 100%;
                }
            }
        }
    }

    &__dynamic {
        height: 50px;
        &__content {
            transform: translateX(-50%);
            left: 50%;
            display: flex;
            align-items: center;
            height: 100%;
            .icon {
                width: auto;
                .cmp-icon {
                    width: auto;
                    height: 20px;
                }
            }
            .cmp-text {
                padding: 5px;
                font-weight: 500;
                color: @grey-90;
                span {
                    font-weight: 900;
                }
            }
        }
    }
    &.mod--ribbon-language {
        .language-dialog {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px;
            flex-wrap: wrap;
            &__txt {
                font-size: 14px;
                line-height: 18px;
                width: 100%;
                display: flex;
                align-items: center;
                padding: 0px 7px;
                flex-shrink: 0;
                justify-content: center;
                .cmp-text p {
                    margin-left: 0px !important;
                }
            }
            &__options {
                display: flex;
                font-size: 14px;
                line-height: 18px;
                flex-wrap: wrap;
                justify-content: center;
                &-btn {
                    display: inline-flex;
                    padding-right: 0.35em;
                    padding-left: 0.35em;
                    .c-cta {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

// MEDIA - DESKTOP
@media (min-width: @desktop) {
    .c-ribbon {
        &__extended {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 114px;
            padding: 17px;
            .icon {
                margin: 17px;
                // width: auto;
                height: 48px;
            }

            &__content {
                padding-left: 17px;
                border-left: 2px solid @white;
                .cmp-title__text {
                    font-size: 22px;
                    line-height: 32px;
                }
                .cmp-text {
                    max-width: @content-sizing;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;

                    b {
                        font-size: 20px;
                        line-height: 28px;
                        display: block;
                    }
                }
            }
            .cta {
                margin-left: initial;
                margin-right: initial;
                .c-cta {
                    margin-top: 0px;
                    margin-left: 30px;
                    width: auto;
                    a {
                        width: auto;
                    }
                }
            }
        }
        &__dynamic {
            height: 30px;
            &__content {
                .icon {
                    margin-right: 8px;
                }
                .cmp-text {
                    padding: 5px 0px;
                }
            }
        }
        &.mod--ribbon-language {
            .language-dialog {
                padding: 7px 15px;
                &__txt {
                    font-size: 16px;
                    line-height: 20px;
                    width: auto;
                    padding: 15px;
                }
                &__options {
                    display: flex;
                    font-size: 16px;
                    line-height: 20px;
                    align-items: center;
                    &-comparator {
                        display: none;
                    }
                    &-btn {
                        padding: 0px 15px;
                        .c-cta {
                            font-size: 16px;
                            line-height: 20px;
                            text-align: center;
                            line-height: 38px;
                            font-size: 15px;
                            height: 40px;
                            padding: 0px 25px;
                            border-radius: 25px;
                            text-transform: uppercase;
                            text-decoration: none;
                            .ellipsis;
                            max-width: 100%;
                        }
                        &-current {
                            .c-cta {
                                .mixin-cta-border-color(@grey-90, @transparent, @white, @grey-90, @grey-90);
                            }
                        }
                        &-target {
                            .c-cta {
                                border: solid 2px #384044;
                                .mixin-cta-bg-color(@grey-90, @white, @white, #384044);
                            }
                        }
                    }
                }
            }
        }
    }
}
