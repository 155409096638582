/* CAMPIGN-CHRONO - MOBILE FIRST */
.c-campaign-chrono {
    display: flex;
    justify-content: center;
}
.c-chrono {
    &__section {
        margin: 0px 5px;
        text-align: center;
        &-counter {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            min-width: 36px;
            background-color: white;
            color: @grey-90;
            box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
            border-radius: @soft-corners;
            padding: 0px 0.5em;
            position: relative;
            &:after {
                content: ":";
                position: absolute;
                right: -7px;
            }
            &-value {
                transition: all 0.12s ease-out;
                &.count-animate {
                    transform: scale(0.3);
                    opacity: 0;
                }
            }
        }
        &:last-child {
            .c-chrono__section-counter {
                &:after {
                    content: none;
                }
            }
        }
        &-legend {
            .format-text(12px, 20px, 500);
            color: @grey-70;
        }
    }
}
