/* COMPONENT TEXT - MOBILE FIRST (~ TABLET) */
.cmp-text {
	&__readmore {
		display: flex;
		align-items: center;
		color: @grey-90;
		cursor: pointer;
		margin-bottom: 30px;
		position: relative;

		.cmp-icon {
			margin-left: 0.25rem;
			svg {
				path {
					// fill: @b-blue;
					fill: @grey-90;
				}
			}
		}
	}

	.generic-container.bg-grey-90 {
		a {
			color: inherit;
		}
	}

	table {
		background: @white;
		display: block;
		overflow: auto;
		white-space: nowrap;
		touch-action: pan-right pinch-zoom;
		cursor: pointer;
		max-height: 500px;
		tbody {
			display: table;
			width: 100%;
			tr {
				&:first-child {
					border-top-color: @grey-40;
					top: 0;
					z-index: 2;
					position: sticky;
					background-color: white;
				}
				td {
					&:first-child {
						max-width: 210px;
						white-space: normal;
					}
				}
				td,
				th {
					border-width: 0px 1px 1px 0px;
					border-right-color: @grey-40;
					border-bottom-color: @grey-90;
					text-align: left;
					padding: 15px;
					&:last-child {
						border-right: 0px;
					}
				}
				th[scope="col"] {
					border-width: 1px 0px 1px;
					border-color: @grey-40;
					background-color: @grey-20;
				}
			}
		}
		/* width */
		&::-webkit-scrollbar {
			width: 7px;
		}
		/* Track */
		&::-webkit-scrollbar-track {
			background: #FFFFFF;
			border-radius: 10px;
		}
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #D8D8D8;
			border-radius: 10px;
		}
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #D8D8D8;
		}
		/* Works on Firefox */
		& {
			scrollbar-width: thin;
			scrollbar-color: #D8D8D8 #FFFFFF;
		}
	}
	h1, h2, h3, h4, h5, h6 {
		font-family: "Museo Sans", sans-serif;
		// font-weight: 300;
		font-size: 20px;
		line-height: 30px;
		letter-spacing: 0.0015em;
		margin-top: 16px;
    	margin-bottom: 10px;
	}
}

/* GENERIC TEXT - POLICIES - MOBILE FIRST (~ TABLET) */
.generic-text,
.textimage .c-textimage .generic-text {
	&.mod--text-head {
		.cmp-text {
			.format-text(16px, 36px, 300);
		}
	}
	&.mod--text-head-services {
		.cmp-text {
			.format-text(16px, 30px, 300);
		}
	}
	&.mod--text-lead {
		.cmp-text {
			.format-text(20px, 32px, 300);
		}
	}
	&.mod--text-body {
		.cmp-text {
			.format-text(18px, 28px, 300);
		}
	}
	&.mod--text-body-small {
		.cmp-text {
			.format-text(16px, 24px, 300);
		}
	}
	&.mod--text-small {
		.cmp-text {
			.format-text(14px, 20px, 500);
		}
	}
	&.mod--text-micro {
		.cmp-text {
			.format-text(12px, 20px, 500);
		}
	}
	&.mod--text-nano {
		.cmp-text {
			.format-text(11px, 15px, 500);
		}
	}
	&.mod--cta-color-dark-yellow-link {
		a {
			color: @b-dark-yellow;
			&:hover {
				color: lighten(@b-dark-yellow, 20%);
			}
		}
	}
	&.mod--blue-short-underline {
		a {
			font-weight: 400;
			color: #384044;
			text-decoration: none;
			display: inline-flex;
			flex-direction: column;
			text-align: left;

			&:hover {
				&::after {
					width: 100%;
				}
			}

			&::after {
				content: "";
				display: block;
				width: 24px;
				height: 2px;
				background-color: #30A2BF;
				transition: width 0.3s ease-out;
			}
		}
	}
	&.mod--mybarcelo-help-item {
		li {
			&::before {
				font-size: 24px;
				color: #ACBDC8;
			}
		}
	}
	&.mod--read-more-align-right {
		.cmp-text__readmore {
			justify-content: end;
		}
		.cmp-text__readmore--wrapper {
			.cmp-text__readmore.read_less {
				justify-content: flex-start;
			}
		}
	}
}

/* COMPONENT TEXT - DESKTOP  */
@media (min-width: @desktop) {
	.generic-text,
	.textimage .c-textimage .generic-text {
		&.mod--text-head {
			.cmp-text {
				.format-text(28px, 40px, 400);
			}
		}
		&.mod--text-head-services {
			.cmp-text {
				.format-text(28px, 40px, 300);
			}
		}

		&.mod--text-body-only-desktop {
			.cmp-text {
				.format-text(18px, 28px, 300);
			}
		}
	}
}
