/* TARGET  FIXES */

/* START - CAMPAIGN-LIST & CARD COMPONENT - MOBILE FIRST (~ TABLET) */
.campaign-list {
	.c-campaign-list {
		&__col,
		&__row {
			.c-card {
				&.at-element-marker {
					.c-card {
						margin: 0;
						width: 100%;
						height: auto;
						&.mod--fullImg,
						&.mod--imgRight {
							.c-card__btn {
								padding: 0;
							}
						}
					}
					&.swiper-slide {
						height: auto;
						& div {
							height: 100%;
							// EVENT LAYOUT
							.c-card.mod--event {
								.c-card__content div {
									height: initial;
								}
								.c-card__image {
									width: auto;
									height: auto;
									.c-container-image {
										height: auto;
										width: 150px;
									}
								}
							}
						}
						.c-card__titles {
							height: auto;
						}
						&.swiper-slide-active {
							height: 100%;
						}
					}
				}
			}
		}
	}
}
@media (min-width: @desktop) {
	.campaign-list {
		.c-campaign-list {
			&__col,
			&__row {
				.c-card {
					&.at-element-marker {
						height: auto;
						& div {
							height: 100%;
							// IMG RIGHT & COMPACT LAYOUT
							.c-card.mod--imgRight,
							.c-card.mod--imgRightCompact {
								height: 100%;
							}
							.c-card.mod--imgRight .c-card__image,
							.c-card.mod--imgRightCompact .c-card__image {
								height: auto;
							}
							.c-card__titles {
								height: auto;
							}
							// IMG LAYOUT
							.c-card.mod--fullImg {
								.c-card__image {
									.c-container-image {
										height: 100%;
									}
								}
							}
							// CARD LAYOUT
							.c-card.mod--card {
								.c-card__image {
									height: 250px;
								}
							}
							// EVENT LAYOUT
							.c-card.mod--event {
								.c-card__content {
									height: auto;
									.c-cta {
										flex: none;
									}
								}
								.c-card__image {
									width: auto;
									.c-container-image {
										min-width: initial;
										width: 227px;
										height: 380px;
									}
									.c-card__image-overlay {
										height: auto;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
/* END - CAMPAIGN-LIST & CARD COMPONENT - MOBILE FIRST (~ TABLET) */